import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import PersonalInfoForm from './ProfileTabForms/PersonalInfo';
import SocietyInfo from './ProfileTabForms/SocietyInfo';
import PageLayout from './PageLayout';
import PaymentInfoForm from './ProfileTabForms/PaymentInfo';
import { WTabs, WTabItem } from 'shared_components/WTabs/WTabs';
import SquarePlaceholder100 from 'shared_components/public/assets/SquarePlaceholder100.svg';

import DocumentsUpdateForm from './ProfileTabForms/Documents';
import MembershipForms from './ProfileTabForms/MembershipForms';
import AdditionalContactsForm from './ProfileTabForms/AdditionalContacts';
import { WizardProvider } from 'shared_components/components/WForms/Wizard';
import WContainer from 'shared_components/components/WContainer';
import { useApi } from 'shared_components/context';
import { useUserId } from 'shared_components/context/user';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { handleInputChange } from 'admin_portal/src/view/PageLayout/Components/Settings/SocietyDetails/SocietyDetails';
import { WPassword } from 'shared_components/components/WForms/WPassword/WPassword';
import { ProfileOnboardingSteps } from './ProfileTabForms/ProfileOnboardingSteps';
import { useUser } from 'shared_components/context/user';
export type Steps =
  | 'personalInfo'
  | 'paymentInfo'
  | 'societyInfo'
  | 'additionalContacts';
function Profile() {
  {
    /* The Profile is a step by step wizard to fill in details */
  }

  const user = useUser();

  const [onboarding, setOnboarding] = useState(false);
  const { userId } = useUserId();
  const { clientApi: api } = useApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const [formValue, setFormValue] = useState<UserSettings>({
    fullName: '',
    emailAddress: '',
  });

  useEffect(() => {
    fetchData();
  }, [userId]);

  const fetchData = () => {
    if (userId === undefined) {
      return;
    }
    api
      .retrieveUserSettings({ userId })
      .then((userSettings) => {
        setFormValue({
          fullName: userSettings.fullName,
          emailAddress: userSettings.emailAddress,
        });
      })
      .catch(() => {});
  };

  //if the onboarding=1 get param is there, display the onboarding wizard
  useEffect(() => {
    if (searchParams.get('onboarding') === '1') {
      setOnboarding(true);
    }
  }, [searchParams]);

  // the 3 tabs with editByDefault=true are reused in the onboarding wizard
  const tabsData: WTabItem[] = [
    {
      label: 'Personal Info',
      content: <PersonalInfoForm editByDefault={false} />,
    },
    {
      label: 'My Documents',
      content: <DocumentsUpdateForm />,
    },
    {
      label: 'Bank Details',
      content: <PaymentInfoForm editByDefault={false} />,
    },
    {
      label: 'Membership Forms',
      content: <MembershipForms />,
    },
    {
      label: 'Additional Contact(s)',
      content: <AdditionalContactsForm editByDefault={false} />,
    },
  ];

  const steps = [
    <div className="tw-flex tw-flex-col tw-space-y-4 tw-max-w-[500px]">
      <PersonalInfoForm />
    </div>,
    <div className="tw-flex tw-flex-col tw-space-y-4 tw-max-w-[500px]">
      <SocietyInfo />
    </div>,
    <div className="tw-flex tw-flex-col tw-space-y-4 tw-max-w-[500px]">
      <PaymentInfoForm />
    </div>,
  ];

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const profileProgress = user.profileProgress;

  return (
    <>
      <PageLayout>
        <div className="tw-flex">
          <h2>Profile </h2>
        </div>
        {onboarding && (
          <>
            {profileProgress && profileProgress === 3 && (
              <WizardProvider steps={steps}>
                <ProfileOnboardingSteps />
              </WizardProvider>
            )}
            {profileProgress && profileProgress !== 3 && (
              <WContainer extraClasses="tw-flex tw-flex-col tw-space-y-4 tw-max-w-[500px]">
                <p className="tw-text-2xl">
                  Onboarding step not available, please return to dashboard.
                </p>
              </WContainer>
            )}
          </>
        )}

        {!onboarding && (
          <>
            {profileProgress && profileProgress < 4 && (
              <WContainer extraClasses="tw-flex tw-flex-col tw-space-y-4 tw-max-w-[500px]">
                <p className="tw-text-2xl">
                  To protect your personal data please verify your identity.
                </p>
              </WContainer>
            )}

            {profileProgress && profileProgress >= 4 && (
              <div className="tw-flex tw-space-x-6">
                <WContainer extraClasses="tw-self-start tw-w-[400px]">
                  {/* A card that shows the profile, email and show password modal */}
                  <img
                    src={SquarePlaceholder100}
                    alt=""
                    className="tw-rounded-full"
                  />
                  <p className="tw-text-2xl tw-my-6">Account Settings</p>
                  <WFormProvider
                    formData={formValue}
                    handleSubmit={() => {}}
                    schema={undefined}
                  >
                    <WInput
                      type="text"
                      value={`${user.firstName ?? ''} ${user.lastName ?? ''}`}
                      name="fullName"
                      label="Name"
                      onChange={inputChange}
                    />
                    <WInput
                      type="text"
                      value={formValue.emailAddress}
                      name="emailAddress"
                      label="Email"
                      onChange={inputChange}
                    />
                    <WPassword
                      name="password"
                      label="Password"
                      onChange={handleInputChange}
                    />
                  </WFormProvider>
                </WContainer>
                <WTabs variant={'transparent'} tabs={tabsData} />
              </div>
            )}
          </>
        )}
      </PageLayout>
    </>
  );
}
export default Profile;
