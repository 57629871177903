import React, { useState, useEffect } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { format } from 'date-fns';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import ViewStatement from './ViewStatement';
import { useApi } from 'shared_components/context';
import { useUserId } from 'shared_components/context/user';
import {
  DailyPayment,
  Payment,
  StatementsJsonMetadata,
  StatementsJsonPaymentsInner,
} from 'shared_components/generated/client';
import {
  basePath,
  getTokenFromSessionStorage,
} from 'shared_components/context/auth';
import PageLayout from './PageLayout';
import customFetch from 'shared_components/utils/customFetch';

function Statements() {
  const { clientApi: api } = useApi();
  const { userId } = useUserId();
  const [sidebarSmall, setSidebarSmall] = useState(false);
  const [nav1, setNav1] = useState<Slider>();
  const [nav2, setNav2] = useState<Slider>();
  const [nav3, setNav3] = useState<Slider>();
  const [nav4, setNav4] = useState<Slider>();
  const [statementView, setStatementView] = useState(false);
  const handleShow = () => setStatementView(!statementView);
  const [financialYears, setFinancialYears] = useState<string[]>([]);
  const [spreadsheetUrls, setSpreadsheetUrls] = useState<string[]>([]);
  const [clientPayments, setClientPayments] = useState<Payment[][]>([]);
  const [statementYear, setStatementYear] = useState(0);
  const [paymentHistoryYear, setPaymentHistoryYear] = useState(0);
  const [clientPaymentsByDay, setClientPaymentsByDay] = useState<
    DailyPayment[][]
  >([]);
  const [paymentDetail, setPaymentDetail] = useState<StatementsJsonMetadata>();
  const [statementData, setStatementData] = useState<
    StatementsJsonPaymentsInner[]
  >([]);
  const [statementPdf, setStatementPdf] = useState('');

  const fetchData = async () => {
    try {
      const token = getTokenFromSessionStorage();

      if (token === null) {
        return;
      }
      if (userId === undefined) {
        return;
      }

      const { fiscalYearsUnique } = await api.retrieveFinancialYears({
        userId,
      });

      if (!fiscalYearsUnique.length) {
        return;
      }

      setStatementYear(fiscalYearsUnique.length - 1);
      setPaymentHistoryYear(fiscalYearsUnique.length - 1);
      setFinancialYears(fiscalYearsUnique);

      const paymentsGroupedByYear = await Promise.all(
        fiscalYearsUnique.map((financialYear) => {
          return api.listPayments({ userId, financialYear });
        })
      );

      setClientPayments(paymentsGroupedByYear);

      const dailyPaymentsGroupedByYear = await Promise.all(
        fiscalYearsUnique.map((financialYear) => {
          return api.listDailyPayments({ userId, financialYear });
        })
      );

      setClientPaymentsByDay(dailyPaymentsGroupedByYear);

      const headers = new Headers({
        Authorization: 'Bearer ' + token.access,
      });

      const spreadsheetResponses = await Promise.all(
        fiscalYearsUnique.map((financialYear) => {
          /**
           * Schema generation currently does not support non-JSON responses.
           * As a workaround, requests that expect other media types are
           * performed manually using the Fetch API.
           */
          return customFetch(
            `${basePath}/portal/users/${userId}/payments/?` +
              new URLSearchParams({
                financial_year: financialYear,
                format: 'xls',
              }),
            { headers }
          );
        })
      );

      for await (const response of spreadsheetResponses) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        setSpreadsheetUrls((prevSpreadsheetUrls) => {
          return [...prevSpreadsheetUrls, url];
        });
      }
    } catch {
      setFinancialYears([]);
      setClientPayments([]);
      setClientPaymentsByDay([]);
    }
  };

  const slideLinkChange = (oldIndex: number, newIndex: number) => {
    setStatementYear(newIndex);
  };

  const slidePaymentYearChange = (oldIndex: number, newIndex: number) => {
    setPaymentHistoryYear(newIndex);
  };

  const viewStatement = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const token = getTokenFromSessionStorage();

    if (userId === undefined) {
      return;
    }
    if (token === null) {
      return;
    }

    const paymentDay = e.currentTarget.getAttribute('data-statementday')!;

    await api
      .retrieveStatementsJson({ userId, paymentDay })
      .then((response) => {
        setPaymentDetail(response.metadata);
        setStatementData(response.payments);
      })
      .catch(() => {
        setPaymentDetail(undefined);
        setStatementData([]);
      });

    const headers = new Headers({
      Authorization: 'Bearer ' + token.access,
    });
    /**
     * Schema generation currently does not support non-JSON responses.
     * As a workaround, requests that expect other media types are
     * performed manually using the Fetch API.
     */
    customFetch(
      `${basePath}/portal/users/${userId}/statements/?` +
        new URLSearchParams({
          payment_day: paymentDay,
          format: 'pdf',
        }),
      { headers }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        setStatementPdf(url);
        setStatementView(true);
      })
      .catch(() => {});
  };

  const numToString = (num: number) => {
    var parseNumber = num / 100;
    const toLocale = parseNumber.toLocaleString(navigator.language, {
      minimumFractionDigits: 2,
    });
    return toLocale;
  };

  useEffect(() => {
    fetchData();
  }, [userId]);

  return (
    <PageLayout>
      {statementView && paymentDetail ? (
        <ViewStatement
          show={statementView}
          modalClose={handleShow}
          statementData={statementData}
          paymentData={paymentDetail}
          statementPdf={statementPdf}
        />
      ) : (
        ''
      )}

      <Container fluid className="d-flex p-0">
        <Row className="w-100 m-0">
          <Col xs={12} md={9} lg={9} className="PageContent p-0">
            <div className="pageDetails">
              <div className="pageDetailsWrapper">
                <div className="DashboardDetails">
                  <div className="clientStatements">
                    <div className="piBack d-flex">
                      <Link to="/dashboard" className="back-button">
                        Back
                      </Link>
                    </div>
                    <div className="distributionsSecti mt-4 pt-3">
                      <h3>Statements</h3>
                      <div>
                        <span>Next expected distribution date is April 22</span>

                        <OverlayTrigger
                          delay={{ hide: 350, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              Your next expected payment depends on your minimum
                              payment threshold and quarterly payment dates
                            </Tooltip>
                          )}
                          placement="auto-start"
                        >
                          <Button className="helpTooltip">Help</Button>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="StatementsBloxes mt-4 pt-0 mw-96">
                      <div className="d-flex justify-content-end">
                        <div className="col-5 blockAction">
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="fySlider">
                              {financialYears.length > 0 ? (
                                <Slider
                                  asNavFor={nav2}
                                  ref={(slider1) => setNav1(slider1 as Slider)}
                                  slidesToShow={1}
                                  slidesToScroll={1}
                                  infinite={false}
                                  swipeToSlide={false}
                                  rtl={false}
                                  swipe={false}
                                  initialSlide={financialYears.length}
                                  beforeChange={(oldIndex, newIndex) => {
                                    slideLinkChange(oldIndex, newIndex);
                                  }}
                                >
                                  {financialYears
                                    .slice(0)
                                    .reverse()
                                    .map((fy) => (
                                      <div
                                        key={fy.toString()}
                                        data-year={fy}
                                        className="yearSlide"
                                      >
                                        <span className="currentYear">
                                          {fy}
                                        </span>
                                      </div>
                                    ))}
                                </Slider>
                              ) : (
                                ''
                              )}
                            </div>
                            {spreadsheetUrls.length > 0 ? (
                              <a
                                href={spreadsheetUrls[statementYear]}
                                className="btn download-btn"
                                download={`${format(
                                  new Date(),
                                  'd_mm_yyyy'
                                )}.xls`}
                              >
                                Download
                              </a>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="statementsListinf">
                        {financialYears.length > 0 &&
                        clientPaymentsByDay.length > 0 ? (
                          <Slider
                            asNavFor={nav1}
                            ref={(slider2) => setNav2(slider2 as Slider)}
                            slidesToShow={1}
                            slidesToScroll={1}
                            arrows={false}
                            infinite={false}
                            swipeToSlide={false}
                            focusOnSelect={true}
                            draggable={false}
                            rtl={false}
                            fade={true}
                            initialSlide={financialYears.length}
                          >
                            {clientPaymentsByDay
                              .slice(0)
                              .reverse()
                              .map((number: DailyPayment[], index) => (
                                <div className="statementSlides" key={index}>
                                  <Slider
                                    slidesToShow={4}
                                    infinite={false}
                                    arrows={false}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                  >
                                    {number.map((num: DailyPayment, index) => (
                                      <div className="sbBox" key={index}>
                                        <div className="sbBoxInner">
                                          <span className="ym d-block">
                                            {format(
                                              new Date(num.date),
                                              'd LLLL, yyyy'
                                            )}
                                          </span>
                                          <span className="price d-block">
                                            &#163;{' '}
                                            {numToString(num.aggregatePayment)}
                                          </span>
                                          <span className="mt-4 d-block">
                                            <Button
                                              className="x-button px-4 d-block"
                                              data-statementday={num.date}
                                              onClick={viewStatement}
                                            >
                                              View Statement
                                            </Button>
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                  </Slider>
                                </div>
                              ))}
                          </Slider>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="paymentHistory mt-4 pt-2 mw-96">
                      <div className="d-flex justify-content-between">
                        <div className="col-7 blockTitle">
                          <h3>Payment history</h3>
                        </div>
                        <div className="col-5 blockAction">
                          <div className="d-flex justify-content-end align-items-center">
                            <div className="fySlider">
                              {financialYears.length > 0 ? (
                                <Slider
                                  asNavFor={nav4}
                                  ref={(slider1) => setNav3(slider1 as Slider)}
                                  infinite={false}
                                  rtl={false}
                                  initialSlide={financialYears.length}
                                  beforeChange={(oldIndex, newIndex) => {
                                    slidePaymentYearChange(oldIndex, newIndex);
                                  }}
                                >
                                  {financialYears
                                    .slice(0)
                                    .reverse()
                                    .map((fy) => (
                                      <div
                                        key={fy.toString()}
                                        data-year={fy}
                                        className="yearSlide"
                                      >
                                        <span className="currentYear">
                                          {fy}
                                        </span>
                                      </div>
                                    ))}
                                </Slider>
                              ) : (
                                ''
                              )}
                            </div>
                            {spreadsheetUrls.length > 0 ? (
                              <a
                                href={spreadsheetUrls[paymentHistoryYear]}
                                className="btn download-btn"
                                download={`${format(
                                  new Date(),
                                  'd_mm_yyyy'
                                )}.xls`}
                              >
                                Download
                              </a>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="paymentListing mt-3 mb-3 pt-1">
                        <div className="paymentListbox">
                          {clientPayments.length > 0 ? (
                            <Slider
                              asNavFor={nav3}
                              ref={(slider2) => setNav4(slider2 as Slider)}
                              slidesToShow={1}
                              arrows={false}
                              infinite={false}
                              swipeToSlide={false}
                              focusOnSelect={true}
                              fade={true}
                              rtl={false}
                              initialSlide={clientPayments.length}
                            >
                              {clientPayments
                                .slice(0)
                                .reverse()
                                .map((payments, index) => (
                                  <div className="paymentSlides" key={index}>
                                    <div className="tableHeaders d-flex">
                                      <span className="date">
                                        Date Received
                                      </span>
                                      <span className="society">Society</span>
                                      <span className="ammount">
                                        Amount Received
                                      </span>
                                      <span className="ammount">% Payable</span>
                                      <span className="ammount">
                                        Amount Paid Out
                                      </span>
                                      <span className="date">
                                        Date Paid Out
                                      </span>
                                      <span className="status">Status</span>
                                    </div>
                                    {payments.length > 0 ? (
                                      <div className="paymentDetails">
                                        {payments.map((payment, index) => (
                                          <div
                                            className="tableRow d-flex"
                                            key={index.toString()}
                                          >
                                            <span className="date">
                                              {format(
                                                new Date(payment.dateCreated),
                                                'dd/MM/yyyy'
                                              )}
                                            </span>
                                            <span className="society">
                                              {payment.fromSociety}{' '}
                                              <small className="d-block">
                                                United Kingdom
                                              </small>
                                            </span>
                                            <span className="ammount">
                                              &#163;{' '}
                                              {numToString(
                                                payment.totalAmountReceived
                                              )}
                                            </span>
                                            <span className="ammount">
                                              {parseFloat(
                                                payment.percentagePayable
                                              ).toFixed(2)}
                                              %
                                            </span>
                                            <span className="ammount">
                                              &#163;{' '}
                                              {numToString(
                                                payment.amountPaidToClient
                                              )}
                                            </span>
                                            <span className="date">
                                              {format(
                                                new Date(payment.paymentDate),
                                                'dd/MM/yyyy'
                                              )}
                                            </span>
                                            <span className="status">
                                              {payment.status}
                                            </span>
                                          </div>
                                        ))}
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                ))}
                            </Slider>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PageLayout>
  );
}
export default Statements;
