import React, { useEffect, useState } from 'react';
import WContainer from 'shared_components/components/WContainer';
import hUser from 'shared_components/public/assets/helloUser.svg';
import { useUser, useUserId } from 'shared_components/context/user';
import { useApi } from 'shared_components/context';
import {
  ClientSocietyFormTask,
  ClientSocietyFormTaskStatusEnum,
  MembershipFormsStatusEnum,
} from 'shared_components/generated/client';

const AvatarWelcome = () => {
  const user = useUser();
  const { userId } = useUserId();
  const profileProgress = user.profileProgress;
  const name = user.firstName;
  const { clientApi: api } = useApi();
  const [greeting, setGreeting] = useState<string>('');
  const greetings: Record<number, string> = {
    1: 'Verify your identity to protect your personal data',
    2: 'WMC are creating your forms, check out your discography',
    3: 'Sign the WMC forms to manage your discography',
    4: 'While WMC reviews your forms, check your PPL review',
    5: 'Complete your profile to get your society forms',
    6: 'WMC are generating your society forms',
    7: 'Sign your society forms to maximise your global income',
    8: 'While WMC reviews your forms, check your PPL review',
  };

  useEffect(() => {
    const updateGreeting = async () => {
      let greetingVal = await getGreeting();
      let greetingText =
        greetingVal === undefined ? '' : greetings[greetingVal];
      setGreeting(greetingText);
    };

    updateGreeting();
  }, [profileProgress]);

  const getGreeting = async () => {
    switch (profileProgress) {
      case 1:
        return 1;
      case 2:
        let clientTasks = await api.listClientSocietyFormTasks({
          userId: userId!,
        });
        if (formsSubmitted(clientTasks)) {
          return 4;
        } else if (waitingForForms(clientTasks)) {
          return 2;
        } else {
          return 3;
        }
      case 3:
        return 5;
      case 4:
        const membershipFormTasks = await api.listMembershipForms({
          userId: userId!,
        });
        const withClient = membershipFormTasks.find(
          (task) => task.status === MembershipFormsStatusEnum.WithClient
        );
        if (withClient === undefined) {
          return 6;
        }
        return 7;
      case 5:
        return 8;
      default:
        return undefined;
    }
  };
  const waitingForForms = (clientTasks: ClientSocietyFormTask[]) => {
    if (clientTasks.length === 0) {
      return true;
    } else {
      // find uncompleted tasks and if the task is 'client_to_sign', then continue else stop
      const uncompletedTasks = clientTasks.filter(
        (task) => task.status === ClientSocietyFormTaskStatusEnum.ClientToSign
      );
      if (uncompletedTasks.length === 0) {
        return true;
      }
    }
    return false;
  };

  const formsSubmitted = (clientTasks: ClientSocietyFormTask[]) => {
    const pendingTasks = clientTasks.filter(
      (task) =>
        task.status === ClientSocietyFormTaskStatusEnum.WitnessToSign ||
        task.status === ClientSocietyFormTaskStatusEnum.AdminToApprove
    );
    if (pendingTasks.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <WContainer extraClasses="tw-w-1/2 tw-flex tw-flex-col">
      <p className="tw-text-2xl tw-mb-1 tw-font-medium">
        {name && <>Hello, {name}!</>}
        {!name && <>Hello!</>}
      </p>
      <p className="tw-font-light">{greeting}</p>
      <div className="tw-flex-grow" />
      <div className="tw-flex tw-justify-end">
        <img src={hUser} alt="avatar" />
      </div>
    </WContainer>
  );
};

export default AvatarWelcome;
