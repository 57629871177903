import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useApi } from 'shared_components/context';
import { useUserId } from 'shared_components/context/user';
import {
  clientLoginHandler,
  getPrevLocationFromSessionStorage,
} from 'shared_components/context/auth';
import LoginPageLayout from 'shared_components/PageLayout/LoginPageLayout';
import WContainer from 'shared_components/components/WContainer';
import Logo from 'shared_components/public/assets/Logo.png';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import { useClientId } from 'shared_components/context/client';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import WModal from 'shared_components/components/WModal';
import { WPassword } from 'shared_components/components/WForms/WPassword/WPassword';
import { sendToast } from 'shared_components/utils/Toast';
import { z } from 'zod';
import ErrorBlock from 'shared_components/components/ErrorBlock/ErrorBlock';
function SignInForm() {
  const { clientApi: api } = useApi();
  const { setUserId } = useUserId();
  const { setClientId, setClientEmail } = useClientId();
  let history = useNavigate();
  const [submitError, setSubmitError] = useState(false);
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
  });

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e: React.FormEvent) => {
    return api
      .createSigninAuthToken({
        loginWithEmailorUsernameAuthToken: formData,
      })
      .then((response) => {
        if (!response.groups?.includes('client_portal')) {
          setSubmitError(true);
          throw new Error('user not a client');
        }

        clientLoginHandler(response);
        setUserId(response.userId);
        setClientId(response.userId);
        setClientEmail(response.email);
        history('/dashboard');
      })
      .catch((error) => {
        setSubmitError(true);
        return Promise.reject(error);
      });
  };
  useEffect(() => {
    const prev = getPrevLocationFromSessionStorage();
    if (prev) {
      sendToast({
        text: 'Your session expired. Please login again and you will be redirected to your previous page.',
        variant: 'success',
        duration: 10000,
      });
    }
  }, []);

  const validationSchema = z.object({
    identifier: z.string().email(),
    password: z
      .string()
      .refine((value) => value !== '', 'Password cannot be empty'),
  });

  return (
    <>
      <LoginPageLayout>
        <div className="tw-flex tw-flex-col tw-w-[1800px] tw-items-center">
          <WContainer extraClasses="tw-w-[600px]">
            <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
              <img
                src={Logo}
                alt="Westbury Music Consultants"
                className="tw-w-[158px] tw-h-[56px] tw-mt-6"
              ></img>
              <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-6">
                <h1 className="tw-font-sans tw-mb-4 tw-font-semibold tw-text-2xl">
                  Welcome Back!
                </h1>
                <p className="tw-mt-4">Please enter your details to sign in</p>
              </div>
              <div className="tw-flex tw-flex-col tw-w-[550px]">
                {/* TODO: Handle the edit state with a superEdit in WFormProvider that always keeps the form in edit mode without a success modal */}
                <WFormProvider
                  successMessage={false}
                  editByDefault={true}
                  handleSubmit={handleSubmit}
                  schema={validationSchema}
                  onSuccess={() => {}}
                  onSuccessMessage={<></>}
                  formData={formData}
                >
                  <WInput
                    type="text"
                    name="identifier"
                    label="Email"
                    value={formData.identifier}
                    onChange={inputChange}
                  />
                  <WPassword
                    name="password"
                    label="Password"
                    value={formData.password}
                    onChange={inputChange}
                  />
                  <div className="tw-flex tw-justify-center">
                    <FormFooter />
                  </div>
                </WFormProvider>
              </div>
            </div>
          </WContainer>
          <div className="tw-text-white tw-flex tw-mt-4">
            <span>
              Don&apos;t have an account?
              <Link className="hover:tw-underline" to="/">
                {'  '}
                <span className="tw-underline">Create an account here</span>
              </Link>
            </span>
          </div>
        </div>
      </LoginPageLayout>
      <WModal
        isOpen={submitError}
        onClose={() => setSubmitError(false)}
        title=""
      >
        <ErrorBlock />
      </WModal>
    </>
  );
}

export default SignInForm;

function FormFooter() {
  const { handleFormSubmit, formData } = useFormContext();
  return (
    <>
      <WButton
        label="Sign In"
        variant="primary-lg"
        onClick={() => handleFormSubmit(formData)}
        extraClasses="tw-mt-4 tw-w-[200px]"
      />
    </>
  );
}
