import React, { useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useApi } from 'shared_components/context';
import { useUserId } from 'shared_components/context/user';
import { clientLoginHandler } from 'shared_components/context/auth';
import LoginPageLayout from 'shared_components/PageLayout/LoginPageLayout';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { WPassword } from 'shared_components/components/WForms/WPassword/WPassword';

import WContainer from 'shared_components/components/WContainer';
import Logo from 'shared_components/public/assets/Logo.png';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import z from 'zod';

import { WError } from 'shared_components/components/WError/WError';
import { useWError } from 'shared_components/components/WError/WErrorProvider';

function SignUpForm() {
  const params = useParams();
  const { throwError } = useWError();
  const emailHash = params.client_email_hashed;
  const { clientApi: api } = useApi();
  const { setUserId } = useUserId();
  let history = useNavigate();

  const schema = z
    .object({
      email: z.string().email(),
      password: z.string().min(8, 'Password must be at least 8 characters'),
      passwordConfirm: z
        .string()
        .min(8, 'Confirm Password must be at least 8 characters'),
    })
    .refine((data) => data.password === data.passwordConfirm, {
      message: 'Password and Confirm Password must match',
      path: ['passwordConfirm'],
    });

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    passwordConfirm: '',
  });

  const fetchData = () => {
    if (emailHash === undefined) {
      return;
    }
    api
      .retrieveByEmailHashClientInterestRegistration({
        emailHash,
      })
      .then((interestRegistration) => {
        setFormData({ ...formData, ['email']: interestRegistration.email });
      })
      .catch(() => {});
  };

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    // add the emailHash to the payload

    const payload = { ...formData, hash: emailHash };
    api
      .updateSignupAuthToken({ signup: payload })
      .then((response) => {
        const userId = response.userId;
        if (userId) {
          clientLoginHandler(response);
          setUserId(response.userId);
          history('/dashboard');
        }
      })
      .catch((error) => {
        error.response
          .json()
          .then((response: { detail: string }) => {
            throwError(new WError(response.detail));
          })
          .catch(() => {
            throwError(new WError('Something went wrong'));
          });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <LoginPageLayout>
      <div className="tw-flex tw-flex-col tw-items-center">
        <WContainer extraClasses="tw-w-[600px]">
          <div className="tw-flex tw-items-center tw-justify-center tw-mb-6 tw-mt-6 tw-flex-col">
            <img
              src={Logo}
              alt="Westbury Music Consultants"
              className="tw-w-[158px] tw-h-[56px] tw-mb-6"
            ></img>
            <p className="tw-uppercase tw-text-xl tw-font-weight-600">
              Create your account
            </p>
          </div>
          <WFormProvider
            editByDefault={true}
            handleSubmit={handleSubmit}
            schema={schema}
            formData={formData}
            onSuccessMessage={null}
            onSuccess={() => {}}
          >
            <WInput
              type="text"
              name="email"
              label="Email"
              disabled={emailHash !== undefined}
              value={formData.email}
              onChange={inputChange}
              hideOnView={true}
            />
            <WPassword
              name="password"
              label="Password"
              value={formData.password}
              onChange={inputChange}
            />
            <WPassword
              name="passwordConfirm"
              label="Confirm Password"
              value={formData.passwordConfirm}
              onChange={inputChange}
            />
            <div className="tw-flex tw-justify-center">
              <FormFooter />
            </div>
          </WFormProvider>

          <div className="tw-flex tw-mt-6 tw-justify-center tw-text-center">
            <p className="tw-text-sm tw-font-light">
              By creating an account with WMC I agree to <br /> the terms and
              conditions and privacy policy
            </p>
          </div>
        </WContainer>
      </div>
    </LoginPageLayout>
  );
}

export default SignUpForm;

function FormFooter() {
  const { handleFormSubmit, formData } = useFormContext();
  return (
    <>
      <WButton
        label="Sign Up"
        variant="primary-lg"
        onClick={() => handleFormSubmit(formData)}
        extraClasses="tw-mt-4 tw-w-[200px]"
      />
    </>
  );
}
