import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, FormControl, Modal } from 'react-bootstrap';
import WContainer from 'shared_components/components/WContainer';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import { useApi } from 'shared_components/context';
import { useUserId } from 'shared_components/context/user';
import {
  AdditionalContactDetails,
  RepresentativeRoleEnum,
} from 'shared_components/generated/client';

import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import z from 'zod';
import { WFormFooter } from 'shared_components/components/WForms/WFormFooter';
import { WPassword } from 'shared_components/components/WForms/WPassword/WPassword';
import WModal from 'shared_components/components/WModal';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { WError } from 'shared_components/components/WError/WError';

interface AdditionalContactsFormProps {
  editByDefault?: boolean;
}

function AdditionalContactsForm({ editByDefault = true }) {
  const { clientApi: api } = useApi();
  const { userId } = useUserId();
  const [registerSociety, setRegisterSociety] = useState('');
  const [aliases, setAliases] = useState([' ']);
  const [userPasswordAuth, setUserPasswordAuth] = useState(false);
  const [userPassword, setUserPassword] = useState('');
  const { throwError } = useWError();

  const [isOpenPasswordModal, setIsOpenPasswordModal] = useState(false);
  const [formValue, setFormValue] = useState({
    witness_first_name: '',
    witness_last_name: '',
    witness_email: '',
    rep_name: '',
    rep_email: '',
    rep_role: '',
    rep_phone_number: '',
    rep_bank_account_name: '',
    rep_bank_name: '',
    rep_bank_sort_code: '',
    rep_bank_iban: '',
    rep_bank_account_holding_branch: '',
    rep_bank_account_number: '',
    rep_bank_address: '',
    rep_bank_routing_code: '',
    rep_bank_bic_swift_code: '',
  });

  const EmailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const inputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    fetchData();
  }, [userId]);

  const fetchData = () => {
    if (userId === undefined) {
      return;
    }
    api
      .retrieveAdditionalContactDetails({ userId })
      .then((additionalContactDetails) => {
        setFormValue({
          witness_first_name: additionalContactDetails.witnessFirstName ?? '',
          witness_last_name: additionalContactDetails.witnessLastName ?? '',
          witness_email: additionalContactDetails.witnessEmail ?? '',
          rep_name: additionalContactDetails.representative?.name ?? '',
          rep_email: additionalContactDetails.representative?.email ?? '',
          rep_role: additionalContactDetails.representative?.role ?? '',
          rep_phone_number:
            additionalContactDetails.representative?.phoneNumber ?? '',
          rep_bank_account_name:
            additionalContactDetails.representativeBankDetails
              ?.bankAccountName ?? '',
          rep_bank_name:
            additionalContactDetails.representativeBankDetails?.bankName ?? '',
          rep_bank_sort_code:
            additionalContactDetails.representativeBankDetails?.bankSortCode ??
            '',
          rep_bank_iban:
            additionalContactDetails.representativeBankDetails?.bankIban ?? '',
          rep_bank_account_number:
            additionalContactDetails.representativeBankDetails
              ?.bankAccountNumber ?? '',
          rep_bank_address:
            additionalContactDetails.representativeBankDetails?.bankAddress ??
            '',
          rep_bank_account_holding_branch:
            additionalContactDetails.representativeBankDetails
              ?.bankAccountHoldingBranch ?? '',
          rep_bank_routing_code:
            additionalContactDetails.representativeBankDetails
              ?.bankRoutingCode ?? '',
          rep_bank_bic_swift_code:
            additionalContactDetails.representativeBankDetails
              ?.bankBicSwiftCode ?? '',
        });
      });
  };

  const saveDetails = () => {
    const formDataValue: AdditionalContactDetails = {
      password: userPassword,
      witnessName: formValue.witness_name,
      witnessEmail: formValue.witness_email,
      representative: {
        name: formValue.rep_name,
        email: formValue.rep_email,
        role: formValue.rep_role as RepresentativeRoleEnum,
        phoneNumber: formValue.rep_phone_number,
      },
      representativeBankDetails: {
        bankAccountName: formValue.rep_bank_account_name,
        bankName: formValue.rep_bank_name,
        bankSortCode: formValue.rep_bank_sort_code,
        bankIban: formValue.rep_bank_iban,
        bankAccountHoldingBranch: formValue.rep_bank_account_holding_branch,
        bankAccountNumber: formValue.rep_bank_account_number,
        bankAddress: formValue.rep_bank_address,
        bankRoutingCode: formValue.rep_bank_routing_code,
        bankBicSwiftCode: formValue.rep_bank_bic_swift_code,
      },
    };

    api
      .updateAdditionalContactDetails({
        userId,
        additionalContactDetails: formDataValue,
      })
      .then(() => {
        setUserPassword('');
        setIsOpenPasswordModal(false);
      })
      .catch(async (e): Promise<any> => {
        const error = await handleErrorResponse(e);
        throwError(error);
        setUserPassword('');
        setIsOpenPasswordModal(false);
      });
  };
  const handleErrorResponse = async (error) => {
    let defaultError = 'An unknown error occurred';

    if (error.response && error.response.json) {
      try {
        const errorData = await error.response.json();
        const errorMessage = findErrorMessage(errorData);
        return new WError(errorMessage || defaultError);
      } catch (jsonError) {
        console.error('Error parsing JSON:', jsonError);
        return new WError(defaultError);
      }
    } else if (error.response) {
      return new WError(`${defaultError}: ${error.response.status}`);
    }
    return new WError(defaultError);
  };

  const findErrorMessage = (data: any, path: string[] = []): string | null => {
    if (typeof data === 'string') {
      return `${path.join(' ')}: ${data}`;
    } else if (Array.isArray(data)) {
      for (let item of data) {
        const message = findErrorMessage(item, path);
        if (message) return message;
      }
    } else if (typeof data === 'object' && data !== null) {
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          const message = findErrorMessage(data[key], [...path, key]);
          if (message) return message;
        }
      }
    }
    return null;
  };

  return (
    <>
      <WFormProvider
        handleSubmit={() => {
          saveDetails();
        }}
        submitOnEnter={false}
        schema={undefined}
        formData={formValue}
      >
        <WContainer>
          <p className="tw-text-2xl tw-mb-4">Additional Contact(s)</p>

          {/* Request Password Modal */}
          <WModal
            title="Password required"
            isOpen={isOpenPasswordModal}
            onClose={() => {
              setIsOpenPasswordModal(false);
            }}
          >
            <p className="tw-text-xl tw-mb-4 tw-text-center">
              Please enter your password to allow this
            </p>
            <WPassword
              name="password"
              label="Password"
              value={userPassword}
              onChange={(e) => {
                setUserPassword(e.target.value);
              }}
            />
            <ModalFooterSubmit />
          </WModal>
          <p className="tw-text-xl tw-mb-4 tw-font-semibold">Witness</p>
          <WInput
            type="text"
            name="witness_first_name"
            label="First Name"
            value={formValue.witness_first_name}
            onChange={inputChange}
          />
          <WInput
            type="text"
            name="witness_last_name"
            label="Last Name"
            value={formValue.witness_last_name}
            onChange={inputChange}
          />
          <WInput
            type="email"
            name="witness_email"
            label="Email"
            value={formValue.witness_email}
            onChange={inputChange}
          />
          <hr className="tw-h-px tw-my-8 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />
          <p className="tw-text-xl tw-mb-4 tw-font-semibold">Representative</p>
          <WInput
            type="text"
            name="rep_name"
            label="Full Name"
            value={formValue.rep_name}
            onChange={inputChange}
          />
          <WSelect
            name="rep_role"
            label="Representative Role"
            defaultValue={formValue.rep_role}
            onChange={inputChange}
            options={[
              { label: 'Manager', value: 'Manager' },
              { label: 'Accountant', value: 'Accountant' },
              { label: 'Lawyer', value: 'Lawyer' },
              { label: 'Representative', value: 'Representative' },
              { label: 'Company', value: 'Company' },
            ]}
          />

          <WInput
            type="email"
            name="rep_email"
            label="Email"
            value={formValue.rep_email}
            onChange={inputChange}
          />

          <WInput
            type="text"
            name="rep_phone_number"
            label="Phone Number"
            value={formValue.rep_phone_number}
            onChange={inputChange}
          />
          <WInput
            type="text"
            name="rep_bank_account_name"
            label="Account Name"
            value={formValue.rep_bank_account_name}
            onChange={inputChange}
          />
          <WInput
            type="text"
            name="rep_bank_sort_code"
            label="Sort Code"
            value={formValue.rep_bank_sort_code}
            onChange={inputChange}
          />
          <WInput
            type="text"
            name="rep_bank_account_number"
            label="Account Number"
            value={formValue.rep_bank_account_number}
            onChange={inputChange}
          />

          <WInput
            type="text"
            name="rep_bank_name"
            label="Bank Name"
            value={formValue.rep_bank_name}
            onChange={inputChange}
          />

          <WInput
            type="text"
            name="rep_bank_address"
            label="Bank Address"
            value={formValue.rep_bank_address}
            onChange={inputChange}
          />

          <WInput
            type="text"
            name="rep_bank_iban"
            label="IBAN Number"
            value={formValue.rep_bank_iban}
            onChange={inputChange}
          />
          <WInput
            type="text"
            name="rep_bank_account_holding_branch"
            label="Representative bank account holding branch"
            value={formValue.rep_bank_account_holding_branch}
            onChange={inputChange}
          />
          <WInput
            type="text"
            name="rep_bank_routing_code"
            label="Routing Code"
            value={formValue.rep_bank_routing_code}
            onChange={inputChange}
          />
          <WInput
            type="text"
            name="rep_bank_bic_swift_code"
            label="Representative bank BIC/SWIFT code"
            value={formValue.rep_bank_bic_swift_code}
            onChange={inputChange}
          />
        </WContainer>

        <div className="tw-flex tw-justify-start tw-mt-4">
          <FormEditSection />
          <FormFooter setIsOpenPasswordModal={setIsOpenPasswordModal} />
        </div>
      </WFormProvider>
    </>
  );
}

export default AdditionalContactsForm;

function FormEditSection() {
  const { isEditing, setIsEditing, errors } = useFormContext(); // Fetch errors from context
  return (
    <>
      {' '}
      {!isEditing && (
        <WButton
          icon="edit"
          variant="primary"
          label="Edit"
          onClick={() => setIsEditing(true)}
        />
      )}
    </>
  );
}

type FormFooterProps = {
  setIsOpenPasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
};

function FormFooter({ setIsOpenPasswordModal }: FormFooterProps) {
  const { handleFormSubmit, formData, isEditing, setIsEditing } =
    useFormContext();
  return (
    <>
      {isEditing && (
        <div className="tw-flex tw-justify-start tw-items-center tw-space-x-2">
          <WButton
            onClick={() => {
              setIsOpenPasswordModal(true);
            }}
            variant="primary-lg"
            label="Save"
          />
          <WButton
            onClick={() => {
              setIsEditing(false);
            }}
            variant="link-secondary"
            label="Cancel"
          />
        </div>
      )}
    </>
  );
}

function ModalFooterSubmit() {
  const { handleFormSubmit, formData, isEditing } = useFormContext();
  return (
    <>
      <div className="tw-flex tw-justify-end">
        <WButton
          onClick={() => {
            handleFormSubmit(formData);
          }}
          variant="primary"
          label="Submit"
        />
      </div>
    </>
  );
}
