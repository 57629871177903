import React from 'react';

import logo from 'shared_components/public/assets/Logo.png';

import { useUser } from 'shared_components/context/user';
import { useSelectedDashboardPath } from 'shared_components/context/navigation';
import { WLink } from 'shared_components/components/WLink/WLink';

interface SidebarProps {
  sidebarToggle: boolean;
  setToggle: (isToggled: boolean) => void;
}

function Sidebar(props: SidebarProps) {
  const user = useUser();
  let myProfileVariant = '';
  if (user.profileProgress < 4) {
    myProfileVariant = 'disabled';
  }

  return (
    <div className="tw-text-white tw-border-r-outlineGraphite">
      <div className="">
        <div className="">
          <div className="tw-pb-8">
            <img src={logo} alt="Westbury Music Logo" width="151" />
          </div>
          <div className="tw-flex tw-flex-col tw-space-y-6">
            <div className="tw-space-x-2">
              <WLink to="/dashboard" label="Home" icon="home"></WLink>
              <WLink
                to="/profile"
                label="My Profile"
                variant={myProfileVariant}
                icon="users"
              ></WLink>
              <WLink to="/discography" label="Discography" icon="speakers" />
              <WLink
                to="/statements"
                label="Statements"
                icon="gbp"
                variant="disabled"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
