import React, { useEffect } from 'react';
import WButton from 'shared_components/components/WForms/WButton/WButton';

export interface ClientTaskProps {
  subtitle: string;
  description?: string;
  cta?: {
    label: string;
    onClick: () => void;
  };
}

function ClientTask({ subtitle, description, cta }: ClientTaskProps) {
  return (
    <div className="tw-flex tw-items-center tw-flex-col">
      <div className="tw-text-base tw-font-light tw-text-center tw-p-2">
        {subtitle}
      </div>
      <div className="tw-text-sm tw-font-light tw-text-center">
        {description || '\u00A0'}
      </div>
      <WButton
        label={cta?.label as string}
        onClick={cta?.onClick}
        variant="primary"
        extraClasses="tw-mt-4 tw-text-sm"
      />
    </div>
  );
}

export default ClientTask;
