import React from 'react';
import { useClientId } from 'shared_components/context/client';
import { ClientMemberships } from 'shared_components/components/ClientMemberships';

function MembershipForms() {
  const { clientId } = useClientId();

  return <ClientMemberships clientId={clientId} />;
}

export default MembershipForms;
