/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrimaryDiscographyRecordingsInnerFeatured
 */
export interface PrimaryDiscographyRecordingsInnerFeatured {
    /**
     * 
     * @type {string}
     * @memberof PrimaryDiscographyRecordingsInnerFeatured
     */
    featuredStatus?: string;
}

/**
 * Check if a given object implements the PrimaryDiscographyRecordingsInnerFeatured interface.
 */
export function instanceOfPrimaryDiscographyRecordingsInnerFeatured(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PrimaryDiscographyRecordingsInnerFeaturedFromJSON(json: any): PrimaryDiscographyRecordingsInnerFeatured {
    return PrimaryDiscographyRecordingsInnerFeaturedFromJSONTyped(json, false);
}

export function PrimaryDiscographyRecordingsInnerFeaturedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrimaryDiscographyRecordingsInnerFeatured {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'featuredStatus': !exists(json, 'featuredStatus') ? undefined : json['featuredStatus'],
    };
}

export function PrimaryDiscographyRecordingsInnerFeaturedToJSON(value?: PrimaryDiscographyRecordingsInnerFeatured | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'featuredStatus': value.featuredStatus,
    };
}

