import React, { useState, useEffect } from 'react';
import { useApi } from 'shared_components/context';
import { useUserId } from 'shared_components/context/user';
import { ClientBankDetails } from 'shared_components/generated/client';
import z from 'zod';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import WContainer from 'shared_components/components/WContainer';
import { WRadioButton } from 'shared_components/components/WForms/WRadioButton/WRadioButton';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import Countries from 'shared_components/utils/Countries';
import { WError } from 'shared_components/components/WError/WError';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { useNavigate } from 'react-router-dom';
import { useWizardContext } from 'shared_components/components/WForms/Wizard';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';
import WModal from 'shared_components/components/WModal';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import { WPassword } from 'shared_components/components/WForms/WPassword/WPassword';
import { sendToast } from 'shared_components/utils/Toast';
import { useUser } from 'shared_components/context/user';

function PaymentInfoForm({ editByDefault = true }) {
  const user = useUser();
  const { clientApi: api } = useApi();
  const { userId: userId } = useUserId();
  const { throwError } = useWError();
  const [isOpenPasswordModal, setIsOpenPasswordModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formValue, setFormValue] = useState({
    bank_account_name: '',
    bank_name: '',
    bank_sort_code: '',
    bank_iban: '',
    bank_account_holding_branch: '',
    bank_account_number: '',
    bank_address: '',
    bank_routing_code: '',
    bank_bic_swift_code: '',
    intermediary_bank_name: '',
    intermediary_bank_address: '',
    intermediary_bank_country: '',
    intermediary_bank_bic_swift_code: '',
    password: '',
  });

  const [paymentMethod, setPaymentMethod] = useState('UK');
  const [intermediaryBank, setIntermediaryBank] = useState('No');

  const inputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    fetchData();
  }, [userId]);

  useEffect(() => {
    if (formValue.bank_iban != '') {
      setPaymentMethod('International');
    }
    if (formValue.intermediary_bank_bic_swift_code != '') {
      setIntermediaryBank('Yes');
    }
  }, [formValue.bank_iban, formValue.intermediary_bank_bic_swift_code]);

  const fetchData = async () => {
    if (userId === undefined) {
      throwError(
        new WError('Something went wrong while fetching your details')
      );
      return;
    }
    try {
      const bankDetails = await api.retrieveClientBankDetails({ userId });
      setFormValue({
        bank_account_name: bankDetails.bankAccountName ?? '',
        bank_name: bankDetails.bankName ?? '',
        bank_sort_code: bankDetails.bankSortCode ?? '',
        bank_iban: bankDetails.bankIban ?? '',
        bank_account_holding_branch: bankDetails.bankAccountHoldingBranch ?? '',
        bank_account_number: bankDetails.bankAccountNumber ?? '',
        bank_address: bankDetails.bankAddress ?? '',
        bank_routing_code: bankDetails.bankRoutingCode ?? '',
        bank_bic_swift_code: bankDetails.bankBicSwiftCode ?? '',
        intermediary_bank_name: bankDetails.intermediaryBankName ?? '',
        intermediary_bank_address: bankDetails.intermediaryBankAddress ?? '',
        intermediary_bank_country: bankDetails.intermediaryBankCountry ?? '',
        intermediary_bank_bic_swift_code:
          bankDetails.intermediaryBankBicSwiftCode ?? '',
        password: '',
      });
    } catch (error) {}
  };

  const saveDetails = () => {
    if (userId === undefined) {
      throwError(new WError('Something went wrong'));
      return;
    }

    const formDataValue: ClientBankDetails = {
      bankAccountName: formValue.bank_account_name,
      bankName: formValue.bank_name,
      bankSortCode: formValue.bank_sort_code,
      bankIban: formValue.bank_iban,
      bankAccountHoldingBranch: formValue.bank_account_holding_branch,
      bankAccountNumber: formValue.bank_account_number,
      bankAddress: formValue.bank_address,
      bankRoutingCode: formValue.bank_routing_code,
      bankBicSwiftCode: formValue.bank_bic_swift_code,
      intermediaryBankName: formValue.intermediary_bank_name,
      intermediaryBankAddress: formValue.intermediary_bank_address,
      intermediaryBankCountry: formValue.intermediary_bank_country,
      intermediaryBankBicSwiftCode: formValue.intermediary_bank_bic_swift_code,
    };
    if (!editByDefault) {
      formDataValue.password = formValue.password;
    }
    const res = !editByDefault
      ? api
          .updateClientBankDetails({
            userId,
            clientBankDetails: formDataValue,
          })
          .then(() => {
            setIsOpenPasswordModal(false);
            sendToast({
              text: 'Bank details updated',
              variant: 'success',
              duration: 10000,
            });
            return Promise.resolve();
          })
          .catch((e) => {
            e.response.json().then((data) => {
              setErrorMessage(data.password[0]);
            });
            return Promise.reject();
          })
      : api
          .createClientBankDetails({
            userId,
            clientBankDetails: formDataValue,
          })
          .then(() => {
            setShowSuccessModal(true);
            return Promise.resolve();
          })
          .catch(async (data) => {
            try {
              const resp = await data.response.json();
              return Promise.reject(resp);
            } catch (error) {
              const wError = new WError(
                'Something went wrong while saving your bank details.'
              );
              throwError(wError);
              return Promise.reject();
            }
          });

    return res;
  };

  const schema = undefined;

  return (
    <>
      <WModal
        title=""
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        hasCloseButton={false}
        disableBackdropClick={true}
      >
        <ThankyouBlock
          title="Thank you"
          description="You have now completed your profile. WMC will be in contact once your society forms have been generated and are ready for you to sign"
          cta={{
            ctaText: 'Go to Dashboard',
            onClick: () => {
              user.fetchData();
              navigate('/dashboard');
            },
          }}
        />
      </WModal>
      <WFormProvider
        submitOnEnter={false}
        schema={schema}
        onSuccessMessage={null}
        onSuccess={() => {}}
        handleSubmit={saveDetails}
        editByDefault={editByDefault}
      >
        <WModal
          title="Password required"
          isOpen={isOpenPasswordModal}
          onClose={() => {
            setIsOpenPasswordModal(false);
          }}
        >
          <p className="tw-text-xl tw-mb-4 tw-text-center">
            Please enter your password to allow this
          </p>
          {/* Show error message in case the server returns one */}

          <WPassword
            name="password"
            label="Password"
            value={formValue.password}
            onChange={(e) => {
              setFormValue({
                ...formValue,
                password: e.target.value,
              });
            }}
          />
          {errorMessage && (
            <p className="tw-text-red-500 tw-mb-4 tw-text-sm">{errorMessage}</p>
          )}
          <ModalFooterSubmit />
        </WModal>
        <WContainer>
          <WRadioButton
            label="Type of Account"
            name="payment_method"
            selectedValue={paymentMethod}
            items={[
              {
                label: 'UK',
                value: 'UK',
              },
              {
                label: 'International',
                value: 'International',
              },
            ]}
            onChange={(e) => {
              setPaymentMethod(e.target.value);
            }}
          />
          {paymentMethod == 'UK' && (
            <>
              <WInput
                type="text"
                label="Account name"
                name="bank_account_name"
                onChange={inputChange}
                data-required="true"
                value={
                  formValue.bank_account_name ? formValue.bank_account_name : ''
                }
              />
              <WInput
                type="text"
                label="Account number"
                name="bank_account_number"
                onChange={inputChange}
                data-required="true"
                value={
                  formValue.bank_account_number
                    ? formValue.bank_account_number
                    : ''
                }
              />
              <WInput
                type="text"
                label="Sort code"
                name="bank_sort_code"
                onChange={inputChange}
                data-required="true"
                value={formValue.bank_sort_code ? formValue.bank_sort_code : ''}
              />
            </>
          )}
          {paymentMethod == 'International' && (
            <>
              <WInput
                type="text"
                label="Account name"
                name="bank_account_name"
                onChange={inputChange}
                data-required="true"
                value={
                  formValue.bank_account_name ? formValue.bank_account_name : ''
                }
              />
              <WInput
                type="text"
                label="Account number"
                name="bank_account_number"
                onChange={inputChange}
                data-required="true"
                value={
                  formValue.bank_account_number
                    ? formValue.bank_account_number
                    : ''
                }
              />
              <WInput
                type="text"
                label="Bank name"
                name="bank_name"
                onChange={inputChange}
                data-required="true"
                value={formValue.bank_name ? formValue.bank_name : ''}
              />
              <WInput
                type="text"
                label="Bank address"
                name="bank_address"
                onChange={inputChange}
                data-required="true"
                value={formValue.bank_address ? formValue.bank_address : ''}
              />
              <WInput
                type="text"
                label="IBAN"
                name="bank_iban"
                onChange={inputChange}
                data-required="true"
                value={formValue.bank_iban ? formValue.bank_iban : ''}
              />
              <WInput
                type="text"
                label="Routing code"
                type="text"
                labelSecondary="(If Applicable)"
                name="bank_routing_code"
                onChange={inputChange}
                value={
                  formValue.bank_routing_code ? formValue.bank_routing_code : ''
                }
              />
              <WInput
                type="text"
                label="BIC/SWIFT code"
                labelSecondary="(If Applicable)"
                name="bank_bic_swift_code"
                onChange={inputChange}
                value={
                  formValue.bank_bic_swift_code
                    ? formValue.bank_bic_swift_code
                    : ''
                }
              />
              <WInput
                type="text"
                label="Account holding branch"
                labelSecondary="(If Applicable)"
                name="bank_account_holding_branch"
                onChange={inputChange}
                value={
                  formValue.bank_account_holding_branch
                    ? formValue.bank_account_holding_branch
                    : ''
                }
              />

              <WRadioButton
                label="Have You An Intermediary Bank?"
                name="intermediary_bank"
                selectedValue={intermediaryBank}
                items={[
                  {
                    label: 'Yes',
                    value: 'Yes',
                  },
                  {
                    label: 'No',
                    value: 'No',
                  },
                ]}
                onChange={(e) => {
                  setIntermediaryBank(e.target.value);
                }}
              />
              {intermediaryBank == 'Yes' && (
                <>
                  <WInput
                    label="Intermediary bank name"
                    name="intermediary_bank_name"
                    onChange={inputChange}
                    value={
                      formValue.intermediary_bank_name
                        ? formValue.intermediary_bank_name
                        : ''
                    }
                    type={''}
                  />
                  <WInput
                    label="Intermediary Bank Address"
                    name="intermediary_bank_address"
                    onChange={inputChange}
                    value={
                      formValue.intermediary_bank_address
                        ? formValue.intermediary_bank_address
                        : ''
                    }
                    type={''}
                  />
                  <WSelect
                    label="Intermediary Bank Country"
                    name="intermediary_bank_country"
                    defaultValue={formValue.intermediary_bank_country}
                    options={Countries.map((country) => ({
                      label: country,
                      value: country,
                    }))}
                    onChange={inputChange}
                  />
                  <WInput
                    type="text"
                    label="Intermediary Bank BIC/SWIFT Code"
                    name="intermediary_bank_bic_swift_code"
                    onChange={inputChange}
                    value={
                      formValue.intermediary_bank_bic_swift_code
                        ? formValue.intermediary_bank_bic_swift_code
                        : ''
                    }
                  />
                </>
              )}
            </>
          )}
        </WContainer>
        {!editByDefault && (
          <OnboardedFormFooter
            setIsOpenPasswordModal={setIsOpenPasswordModal}
          />
        )}
        {editByDefault && (
          <FormFooter setShowSuccessModal={setShowSuccessModal} />
        )}
      </WFormProvider>
    </>
  );
}

export default PaymentInfoForm;
interface FormFooterProps {
  setShowSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const FormFooter: React.FC<FormFooterProps> = ({ setShowSuccessModal }) => {
  const { handleFormSubmit, isFormSubmitted, formData } = useFormContext();
  const { nextStep, prevStep } = useWizardContext();
  const navigate = useNavigate();
  return (
    <div className="tw-flex tw-justify-between tw-mt-4">
      <WButton
        onClick={() => {
          prevStep();
        }}
        variant="secondary"
        label="Back"
      />
      <WButton
        onClick={() => {
          handleFormSubmit(formData);
          if (isFormSubmitted) {
            setShowSuccessModal(true);
          }
        }}
        variant="primary"
        label="Save"
      />
    </div>
  );
};

function OnboardedFormFooter({ setIsOpenPasswordModal }) {
  const {
    handleFormSubmit,
    formData,
    isFormSubmitted,
    isEditing,
    setIsEditing,
  } = useFormContext();
  return (
    // show primary button with edit icon when not editing, show Save button when editing
    <div className="tw-flex tw-justify-start tw-mt-4 tw-space-x-2 tw-items-center">
      {!isEditing && (
        <WButton
          onClick={() => {
            setIsEditing(true);
          }}
          variant="primary-lg"
          label="Edit"
          icon="edit"
        />
      )}
      {isEditing && (
        <>
          <WButton
            onClick={() => {
              setIsOpenPasswordModal(true);
            }}
            variant="primary-lg"
            label="Save"
          />
          <WButton
            onClick={() => {
              setIsEditing(false);
            }}
            variant="link-secondary"
            label="Cancel"
          />
        </>
      )}
    </div>
  );
}

function ModalFooterSubmit() {
  const { handleFormSubmit, formData, isEditing } = useFormContext();
  return (
    <>
      <div className="tw-flex tw-justify-end ">
        <WButton
          onClick={() => {
            handleFormSubmit(formData);
          }}
          variant="primary"
          label="Submit"
        />
      </div>
    </>
  );
}
