import React, { useEffect, useState } from 'react';
import { WError } from 'shared_components/components/WError/WError';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import WModal from 'shared_components/components/WModal';
import { useApi } from 'shared_components/context';
import { useUserId } from 'shared_components/context/user';
import {
  ClientSocietyFormTask,
  ClientSocietyFormTaskStatusEnum,
  MembershipFormsStatusEnum,
} from 'shared_components/generated/client';
import { AgreementModalPage, AgreementModalIFrame } from './AgreementModal';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';
import WDot from 'shared_components/components/WDot';
import PandadocPage from 'shared_components/components/PandadocPage/PandadocPage';

interface SignSocietyFormsModalProps {
  onClose: () => void;
}
interface pandadocsDocumentIds {
  documentId: string | null | undefined;
  sessionId: string | null | undefined;
  documentName: string | null | undefined;
  taskId: string | null | undefined;
  complete: boolean;
}

function SignSocietyFormsModal({ onClose }: SignSocietyFormsModalProps) {
  const { clientApi } = useApi();
  const { userId } = useUserId();
  const { throwError } = useWError();
  const [societyFormTasks, setSocietyFormTasks] = useState<
    ClientSocietyFormTask[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedPageIndex, setSelectedPageIndex] = React.useState<number>(1);
  const [pandadocsDocumentIds, setPandadocsDocumentIds] = React.useState<
    pandadocsDocumentIds[]
  >([]);
  const [signing, setSigning] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const fetchClientSocietyFormTasks = async () => {
    if (userId === undefined) {
      return;
    }
    try {
      let tasks = await clientApi.listClientSocietyFormTasks({
        userId: userId,
      });
      tasks = tasks.filter(
        (task) => task.status === ClientSocietyFormTaskStatusEnum.ClientToSign
      );
      if (tasks.length === 0) {
        setIsOpen(true);
        setCompleted(true);
        return;
      }
      setSocietyFormTasks(tasks);
    } catch {
      throwError(new WError('Error fetching membership forms'));
    }
  };

  useEffect(() => {
    fetchClientSocietyFormTasks();
  }, []);

  useEffect(() => {
    // Fetch the required data (sessionId or documentId) for each form
    const fetchDataForForms = async () => {
      const updatedPandadocsDocumentIds = await Promise.all(
        societyFormTasks.map(async (task) => {
          if (!task.pandadocsDocumentId || task.status !== 'client_to_sign') {
            return {
              documentId: null,
              sessionId: null,
              documentName: task.societyFormName,
              taskId: task.id,
              complete: false,
            };
          }
          let sessionId = task.pandadocsSessionId;
          if (!sessionId) {
            let response =
              await clientApi.createClientSessionCreatePandadocsSessionId({
                taskId: task.id ?? '',
              });
            sessionId = response?.sessionId ?? '';
          }

          return {
            documentId: task.pandadocsDocumentId ?? '',
            documentName: task.societyFormName,
            taskId: task.id,
            sessionId: sessionId,
            complete: false,
          };
        })
      );
      setPandadocsDocumentIds(
        updatedPandadocsDocumentIds.filter((doc) => doc.sessionId !== null)
      );
    };
    if (societyFormTasks.length === 0) {
      return;
    }
    fetchDataForForms();
    setIsOpen(true);
  }, [societyFormTasks]);

  const signMembershipForm = async (documentId: string) => {
    if (userId === undefined || signing) {
      return;
    }
    if (signing) {
      return;
    }
    try {
      let task = societyFormTasks.find(
        (doc) => doc.pandadocsDocumentId === documentId
      );
      if (task?.status !== ClientSocietyFormTaskStatusEnum.ClientToSign) {
        return;
      }
      let taskId = task?.id;
      let taskData = await clientApi.clientSignClientSocietyFormTask({
        userId: userId,
        taskId: taskId ?? '',
      });

      setPandadocsDocumentIds((prev) =>
        prev.map((doc) => {
          if (doc.taskId === taskData.id) {
            return { ...doc, complete: true };
          }
          return doc;
        })
      );
      setSigning(false);
    } catch {
      throwError(new WError('Error signing membership form'));
    }
  };
  const checkIfAllFormsSigned = () => {
    if (pandadocsDocumentIds.length === 0) {
      // no forms to sign
      return false;
    }
    let allSigned = true;
    pandadocsDocumentIds.forEach((doc) => {
      if (!doc.complete) {
        allSigned = false;
      }
    });
    return allSigned;
  };
  useEffect(() => {
    if (checkIfAllFormsSigned()) {
      setCompleted(true);
    }
  }, [pandadocsDocumentIds]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent<any>) => {
      const type = event.data && event.data.type;
      const payload = event.data && event.data.payload;
      switch (type) {
        case 'session_view.document.loaded':
          if (isLoading) setIsLoading(false);
          break;
        case 'session_view.document.completed':
          if (payload && payload.uuid) {
            if (!signing) {
              setSigning(true);
              signMembershipForm(payload.uuid);
              if (selectedPageIndex < pandadocsDocumentIds.length) {
                setSelectedPageIndex(selectedPageIndex + 1);
              }
            }
          }
          break;
      }
    };
    // pandadocs iframe event message listeners
    // https://developers.pandadoc.com/reference/embed-a-document#embedded-document-javascript-events
    window.addEventListener('message', handleMessage);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [isLoading, signing]);

  if (completed) {
    return (
      <WModal
        title=""
        disableBackdropClick={true}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          onClose();
        }}
      >
        <ThankyouBlock
          title="Onboarding Completed"
          description="Any remaining physical forms should be returned to WMC once signed. WMC will process your forms and will be in touch once your membership is confirmed with each society."
          cta={{
            ctaText: 'View Dashboard',
            onClick: () => {
              setIsOpen(false);
              onClose();
            },
          }}
        />
      </WModal>
    );
  }

  return (
    <WModal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        onClose();
      }}
      title="Society Forms"
      modalClasses="tw-min-w-[70vw]"
    >
      <div className="">
        {isLoading && (
          <div className="tw-flex tw-items-center tw-justify-center tw-min-h-[75vh]">
            <p className="tw-my-auto tw-animate-ping">⚪</p>
          </div>
        )}
        <div
          className={
            isLoading
              ? 'invisible'
              : 'tw-flex tw-flex-row tw-justify-center tw-mb-2'
          }
        >
          {pandadocsDocumentIds.map(({}, index: number) => (
            <WDot
              key={index}
              selected={selectedPageIndex === index + 1}
              onClick={() => setSelectedPageIndex(index + 1)}
            />
          ))}
        </div>

        {pandadocsDocumentIds.map(({ sessionId }, index: number) => (
          <PandadocPage
            key={index}
            index={index}
            sessionId={sessionId}
            selectedPageIndex={selectedPageIndex}
            isLoading={isLoading}
          />
        ))}
      </div>
    </WModal>
  );
}

export default SignSocietyFormsModal;
