import React from "react";
import { Form } from "react-bootstrap";
function FormSelect(props) {
  return (
    <Form.Select
      {...props}
      style={{
        color: props.value ? "var(--color-stone)" : "var(--color-silver)",
      }}
    >
      {props.children}
    </Form.Select>
  );
}

export default FormSelect;
