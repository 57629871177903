import React, { ChangeEvent, useState } from 'react';
import { Steps } from '../Profile';
import { WRadioButton } from 'shared_components/components/WForms/WRadioButton/WRadioButton';
import z from 'zod';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import WImageUpload from 'shared_components/components/WForms/WFileUpload/WImageUpload';
import WContainer from 'shared_components/components/WContainer';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import WFileUpload from 'shared_components/components/WForms/WFileUpload/WFileUpload';
import { useWizardContext } from 'shared_components/components/WForms/Wizard';
function SocietyInfo() {
  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
  };

  const fileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
  };

  const radioButtonChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      societyInfo: e.target.value,
    });
  };

  const [formValues, setFormValues] = useState({
    societyInfo: '',
    file: undefined,
  });

  const handleSubmit = () => {
    return Promise.resolve(true);
  };
  const schema = undefined;
  return (
    <>
      <WFormProvider
        schema={schema}
        handleSubmit={handleSubmit}
        editByDefault={true}
      >
        <WContainer>
          <WRadioButton
            label="Are You Currently Registered With Any Neighbouring Rights Agent?"
            name="societyInfo"
            selectedValue={formValues['societyInfo']}
            items={[
              {
                label: 'Yes',
                value: 'yes',
              },
              {
                label: 'No',
                value: 'no',
              },
              {
                label: "Don't Know",
                value: 'dontKnow',
              },
            ]}
            onChange={radioButtonChange}
          />
          {formValues.societyInfo === 'yes' && (
            <>
              <div className="tw-mb-4">Current Agent Agreement (Optional)</div>
              <WFileUpload
                label="Drag & drop or click to upload"
                name="file"
                value={formValues['file']}
                onFileChange={fileChange}
              />
            </>
          )}
        </WContainer>
        <PersonalInfoFormFooter />
      </WFormProvider>
    </>
  );
}

export default SocietyInfo;

function PersonalInfoFormFooter() {
  const { handleFormSubmit, formData } = useFormContext();
  const { nextStep, prevStep } = useWizardContext();

  if (nextStep) {
    return (
      <div className="tw-flex tw-justify-between tw-mt-4">
        <WButton
          onClick={() => {
            prevStep();
          }}
          variant="secondary"
          label="Back"
        />
        <WButton
          onClick={() => {
            handleFormSubmit(formData);
            nextStep();
          }}
          variant="primary"
          label="Next"
        />
      </div>
    );
  } else {
    return (
      <div className="tw-flex tw-justify-end tw-mt-4">
        <WButton
          onClick={() => {
            handleFormSubmit(formData);
          }}
          variant="primary"
          label="Next"
        />
        ;
      </div>
    );
  }
}
