import React, { useState, Component, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FormGroup,
  FormControl,
  FormLabel,
} from 'react-bootstrap';
import { Document, pdfjs, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
import PageLayout from './PageLayout';

function UploadPDF(props) {
  const [sidebarSmall, setSidebarSmall] = useState(false);
  const [pdfForm, setPdfForm] = useState('');
  const [formError, setFormError] = useState('');
  const [fileDataURL, setFileDataURL] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [pageArray, setPageArray] = useState([]);
  const pdfPages = [];
  var docId = localStorage.getItem('document_id');
  const [documentID, setDocumentID] = useState(docId);
  const apiKey = '462ef621474a2ea833ac60418b8d36176af04996';
  var Documentdata = JSON.stringify({
    name: 'Test document by Dev',
    template_uuid: 'eSqYVxhTgvvEtWpGxZ2PKg',
    recipients: [
      {
        email: 'me@shaystudio.co.uk',
      },
    ],
    fields: {
      client_sign: {
        value: '',
        title: 'Signature',
        assigned_to: {
          email: 'me@shaystudio.co.uk',
          type: 'recipient',
        },
      },
      sign_date: {
        value: '',
        title: 'Date of Signature',
        assigned_to: {
          email: 'me@shaystudio.co.uk',
          type: 'recipient',
        },
      },
      date_of_birth: {
        value: '15th April 1999',
        title: 'Date of Birth',
      },
      nationality: {
        value: 'Uk',
        title: 'nationality',
      },
      instrument: {
        value: 'xxx xxx xxx',
        title: '',
      },
      full_name: {
        value: 'xxx xxx xxx',
        title: '',
      },
      stage_name: {
        value: 'xxx xxx xxx',
        title: '',
      },
      band_member_of: {
        value: 'xxx xxx xxx',
        title: '',
      },
      address: {
        value: 'xxx xxx xxx',
        title: '',
      },
      zip_code: {
        value: 'xxx xxx xxx',
        title: '',
      },
      country_of_residence: {
        value: 'xxx xxx xxx',
        title: '',
      },
      tel_no: {
        value: 'xxx xxx xxx',
        title: '',
      },
      mobile_no: {
        value: 'xxx xxx xxx',
        title: '',
      },
      email: {
        value: 'xxx xxx xxx',
        title: '',
      },
      website: {
        value: 'xxx xxx xxx',
        title: '',
      },
      account_holder: {
        value: 'xxx xxx xxx',
        title: '',
      },
      bic_code: {
        value: 'xxx xxx xxx',
        title: '',
      },
      account_no: {
        value: 'xxx xxx xxx',
        title: '',
      },
      bank_name: {
        value: 'xxx xxx xxx',
        title: '',
      },
      bank_address: {
        value: 'xxx xxx xxx',
        title: '',
      },
      bank_zipcode: {
        value: 'xxx xxx xxx',
        title: '',
      },
    },
  });
  const datFetch = async () => {
    if (!documentID) {
      var config = {
        method: 'post',
        url: 'https://api.pandadoc.com/public/v1/documents',
        headers: {
          Authorization: 'API-Key 462ef621474a2ea833ac60418b8d36176af04996',
          'Content-Type': 'application/json',
        },
        data: Documentdata,
      };
      axios(config)
        .then(function (response) {
          if (response.data.id) {
            var Senddata = JSON.stringify({
              message: 'Hello! This document was sent from the PandaDoc API.',
              email: 'me@shaystudio.co.uk',
              silent: true,
            });
            var config = {
              method: 'post',
              url: `https://api.pandadoc.com/public/v1/documents/${response.data.id}/send`,
              headers: {
                Authorization:
                  'API-Key 462ef621474a2ea833ac60418b8d36176af04996',
                'Content-Type': 'application/json',
              },
              data: Senddata,
            };
            setTimeout(() => {
              axios(config)
                .then(function (response) {
                  if (
                    response.data.id &&
                    response.data.status === 'document.sent'
                  ) {
                    var data = JSON.stringify({
                      recipient: 'me@shaystudio.co.uk',
                      lifetime: 315359990,
                    });
                    var config = {
                      async: true,
                      method: 'post',
                      url: `https://api.pandadoc.com/public/v1/documents/${response.data.id}/session`,
                      headers: {
                        Authorization:
                          'API-Key 462ef621474a2ea833ac60418b8d36176af04996',
                        'Content-Type': 'application/json',
                      },
                      data: data,
                    };

                    axios(config)
                      .then(function (response) {
                        localStorage.setItem('document_id', response.data.id);
                        setDocumentID(response.data.id);
                      })
                      .catch(function (error) {});
                    setTimeout(() => {}, 5000);
                  }
                })
                .catch(function (error) {});
            }, 3000);
          }
        })
        .catch(function (error) {});
    }
  };
  useEffect(() => {
    datFetch();
  }, []);
  const pdfUpload = async (event) => {
    var targetValue = event.target.files[0];
    if (event.target.files[0].type === 'application/pdf') {
      setPdfForm(targetValue);
      setFormError('');
    } else {
      setFormError('Please select any pdf file');
      return;
    }
  };
  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (pdfForm) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };

      fileReader.readAsDataURL(pdfForm);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [pdfForm]);

  for (var i = 1; i <= pageNum; i++) {
    pdfPages.push(<Page key={i} pageNumber={i} width="750" />);
  }
  return (
    <PageLayout>
      <Container fluid className="d-flex p-0">
        <Col xs={12} md={9} lg={9} className="PageContent p-0">
          <div className="pageDetails">
            <div className="pageDetailsWrapper">
              <div className="DashboardDetails d-none">
                <Row className="DsRow">
                  <Col xs={12} lg={3} className="">
                    <h3>Upload PDf here!</h3>
                    <Form.Group className="ModalFormGroup">
                      <div className="fileUpload">
                        <Form.Control
                          type="file"
                          name="pdfform"
                          className="formFileUpload"
                          id="pdfform"
                          onChange={pdfUpload}
                        />
                        <label
                          htmlFor="passport"
                          className="fileUploadLabel d-flex"
                        >
                          Drag and drop or click to upload
                          <span className="uploadicon"></span>
                        </label>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={9} className="uploadedFile">
                    {formError ? (
                      <div clasName="pdfError">{formError}</div>
                    ) : (
                      ''
                    )}
                    {fileDataURL ? (
                      <div className="PdfViewerCanvas ContentPdf">
                        <Document
                          file={fileDataURL}
                          onLoadError={(error) => {}}
                          onLoadSuccess={(pdf) => {
                            setPageNum(pdf.numPages);
                          }}
                        >
                          {pdfPages}
                        </Document>
                      </div>
                    ) : (
                      <div className="ContentPdf">
                        <h4>Your PDF file will be viewed here.</h4>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="pandadocPDFView">
                <div className="pdfView">
                  {documentID ? (
                    <iframe
                      src={`https://app.pandadoc.com/s/${documentID}`}
                      width="100%"
                      height="770px"
                    ></iframe>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Container>
    </PageLayout>
  );
}

export default UploadPDF;
