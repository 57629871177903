import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import WContainer from 'shared_components/components/WContainer';
import Logo from 'shared_components/public/assets/Logo.png';
import LoginPageLayout from 'shared_components/PageLayout/LoginPageLayout';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import z from 'zod';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import { useApi } from 'shared_components/context';

import WModal from 'shared_components/components/WModal';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';

interface EOIFormProps {
  handleSubmit: (formData: any) => Promise<any> | undefined;
  onSuccess: () => void;
  formData: any;
  setFormData: any;
  onSuccessMessage?: React.ReactNode;
}

export const EOIForm = ({
  handleSubmit,
  onSuccess,
  onSuccessMessage,
  formData,
  setFormData,
}: EOIFormProps) => {
  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const nonEmptyString = z.string().min(1, 'At least one alias is required');
  const arrayWithNonEmptyString = z
    .array(nonEmptyString)
    .refine((data) => data.length > 0, {
      message: 'Please provide at least one alias in "aliases" field',
    });

  const schema = z.object({
    firstName: z.string().nonempty({ message: 'First name is required' }),
    middleName: z.string(),
    lastName: z.string().nonempty({ message: 'Last name is required' }),
    email: z
      .string()
      .email({ message: 'Please provide a valid email address' }),
    aliases: arrayWithNonEmptyString,
  });
  return (
    <div className="tw-flex tw-flex-col tw-w-[600px] tw-justify-center tw-items-center tw-content-center">
      <div className="tw-mt-4 tw-flex tw-flex-col tw-min-w-[536px]">
        <WFormProvider
          editByDefault={true}
          handleSubmit={handleSubmit}
          schema={schema}
          formData={formData}
          onSuccessMessage={onSuccessMessage}
          onSuccess={onSuccess}
        >
          <div className="tw-mb-4">
            <WInput
              type="text"
              name="firstName"
              label="First Name"
              value={formData.firstName}
              onChange={inputChange}
            />
          </div>
          <div className="tw-mb-4">
            <WInput
              type="text"
              name="middleName"
              label="Middle Name"
              value={formData.middleName}
              onChange={inputChange}
            />
          </div>
          <div className="tw-mb-4">
            <WInput
              type="text"
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              onChange={inputChange}
            />
          </div>
          {/* Aliases is an array of inputs that updates formData.aliases[] */}
          <div className="tw-mb-4">
            <Aliases formData={formData} setFormData={setFormData} />
          </div>
          <div className="tw-mb-4">
            <WInput
              type="text"
              name="pplPerformerName"
              label="PPL Contributor Name"
              optionalLabel={true}
              value={formData.pplPerformerName}
              onChange={inputChange}
            />
          </div>
          <div className="tw-mb-4">
            <WInput
              type="text"
              name="pplPerformerId"
              label="PPL Performer ID"
              optionalLabel={true}
              value={formData.pplPerformerId}
              onChange={inputChange}
            />
          </div>
          <div className="tw-mb-4">
            <WInput
              type="email"
              name="email"
              label="Email"
              value={formData.email}
              onChange={inputChange}
            />
          </div>
          <div className="tw-flex tw-items-center tw-justify-center">
            <EOIFormFooter />
          </div>
        </WFormProvider>
      </div>
    </div>
  );
};

function EOIFormFooter() {
  // large submit button
  const { handleFormSubmit, formData } = useFormContext();
  return (
    <>
      <div className="tw-flex tw-items-center">
        <WButton
          label="Send"
          extraClasses="tw-w-[200px]"
          variant="primary-lg"
          onClick={() => {
            handleFormSubmit(formData);
          }}
        />
      </div>
    </>
  );
}

interface AliasesProps {
  formData: any;
  setFormData: any;
}
const Aliases: React.FC<AliasesProps> = ({ formData, setFormData }) => {
  const aliasInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // split won't work for first input
    // first name of aliases is aliases, after it's `aliases_index`

    const index = parseInt(e.target.name.split('_')[1]) || 0;

    const newAliases = [...formData.aliases];
    newAliases[index] = e.target.value;
    setFormData({ ...formData, aliases: newAliases });
  };

  const aliasesInput = formData.aliases.map((alias: string, index: number) => {
    // first name of aliases is aliases, after it's `aliases_index`
    const name = index === 0 ? 'aliases' : `aliases_${index}`;
    return (
      <WInput
        key={index}
        optionalLabel={index > 0}
        type="text"
        name={name}
        label="Alias"
        value={formData.aliases[index]}
        onChange={aliasInputChange}
      />
    );
  });

  return (
    <>
      {aliasesInput}
      <div className="tw-flex tw-justify-end" style={{ marginTop: '-12px' }}>
        <WButton
          label="Add Alias"
          variant="link-secondary"
          icon="plus"
          onClick={() => {
            setFormData({
              ...formData,
              aliases: [...formData.aliases, ''],
            });
          }}
        />
      </div>
    </>
  );
};
