import React, {
  createContext,
  useContext,
  useState,
  ReactElement,
} from 'react';

interface WizardContextProps {
  currentStep: number;
  nextStep: () => void;
  prevStep: () => void;
  stepsCount: number;
}

const WizardContext = createContext<WizardContextProps | null>(null);

interface WizardProviderProps {
  steps: ReactElement[];
  children?: any; // i.e. could be Wizard Steps display or Wizard Buttons
}

export const WizardProvider: React.FC<WizardProviderProps> = ({
  steps,
  children,
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () =>
    setCurrentStep(Math.min(currentStep + 1, steps.length - 1));
  const prevStep = () => setCurrentStep(Math.max(currentStep - 1, 0));
  const stepsCount = steps.length;
  return (
    <WizardContext.Provider
      value={{ currentStep, nextStep, prevStep, stepsCount }}
    >
      {children}
      {steps[currentStep]}
    </WizardContext.Provider>
  );
};

export const useWizardContext = (): WizardContextProps => {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error('useWizard must be used within a WizardProvider');
  }
  return context;
};
