import React, { useEffect } from 'react';
import WContainer from 'shared_components/components/WContainer';
import ClientTask, { ClientTaskProps } from './ClientTask';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import WDot from 'shared_components/components/WDot';

interface ClientTasksBoxProps {
  tasks?: ClientTaskProps[];
}

function ClientTasksBox({ tasks }: ClientTasksBoxProps) {
  const [selectedTaskIndex, setSelectedTaskIndex] = React.useState<number>(0);
  const [clientTasks, setClientTasks] = React.useState<ClientTaskProps[]>([]);

  useEffect(() => {
    if (tasks) {
      setClientTasks(tasks);
    }
  }, []);

  const handleDismiss = () => {
    const newTasks = [...clientTasks];
    newTasks.splice(selectedTaskIndex, 1);
    setClientTasks(newTasks);
    setSelectedTaskIndex(0);
  };

  const showClientTasks = () => {
    return (
      <>
        <ClientTask {...clientTasks[selectedTaskIndex]} />
        <WButton
          label="Dismiss"
          onClick={() => handleDismiss()}
          variant="link-secondary"
          extraClasses="tw-text-sm tw-font-light tw-mt-2"
        />
        <div
          className={'tw-flex tw-flex-row tw-justify-center tw-mt-4 tw-mb-2'}
        >
          {clientTasks?.length > 1 &&
            clientTasks?.map(({}, index: number) => (
              <WDot
                key={index}
                selected={selectedTaskIndex === index}
                onClick={() => setSelectedTaskIndex(index)}
              />
            ))}
        </div>
      </>
    );
  };

  const completedTasks = () => {
    return (
      <div className="tw-text-base tw-font-light tw-text-center">
        You're all caught up
      </div>
    );
  };

  return (
    <WContainer extraClasses="tw-w-1/2 tw-flex-col tw-items-center tw-flex">
      <p className="tw-text-2xl">Tasks</p>
      <div className="tw-justify-center tw-h-full tw-items-center tw-flex-col tw-flex">
        {clientTasks && clientTasks?.length > 0
          ? showClientTasks()
          : completedTasks()}
      </div>
    </WContainer>
  );
}

export default ClientTasksBox;
