import React, { useState, useEffect } from 'react';
import { useApi } from 'shared_components/context';
import { useUserId } from 'shared_components/context/user';
import WFileUpload from 'shared_components/components/WForms/WFileUpload/WFileUpload';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { z } from 'zod';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import WContainer from 'shared_components/components/WContainer';
import { WFormFooter } from 'shared_components/components/WForms/WFormFooter';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { WError } from 'shared_components/components/WError/WError';
import { Constants } from 'shared_components/utils/Countries';
import {
  ClientIdentityDocumentSubmissionDocumentTypeEnum,
  ClientIdentityDocumentSubmission,
} from 'shared_components/generated/client';

function DocumentsUpdateForm() {
  const { clientApi: api } = useApi();
  const { throwError } = useWError();
  const { userId } = useUserId();
  const [agreementsList, setAgreementsList] = useState([' ']);
  const [passportExpiryDate, setPassportExpiryDate] = useState('');
  const [formData, setFormData] = useState<{ agreements: any[] }>({
    agreements: [],
  });
  const [formValue, setFormValue] = useState({
    full_name: '',
    societies: agreementsList,
  });

  const constants = Constants;
  const [passportStatus, setPassportStatus] = useState('');
  const [docUpdatesByType, setDocUpdatesByType] = useState<{
    [key: string]: {
      edited: boolean;
      documentName: ClientIdentityDocumentSubmissionDocumentTypeEnum;
      docId?: string;
      filename: string;
      file: File;
    };
  }>({});

  const [docTypes, setDocTypes] = useState<
    ClientIdentityDocumentSubmissionDocumentTypeEnum[]
  >([]);
  const [docsByType, setDocsByType] = useState<{
    [key: string]: ClientIdentityDocumentSubmission;
  }>({});

  // Zod validation schema for documents, passport can be an image, others need to be pdf
  const docSchema = z
    .object({
      Passport: z.any(),
      proofOfAddress: z.any(),
      Terms_Of_Agreement: z.any(),
      Power_Of_Attorney: z.any(),
    })
    .refine(
      (data) => {
        const formKeys = Object.keys(docUpdatesByType);
        // We only need to validate updated docs - if the user hasn't updated the doc, we don't need to validate it
        if (!formKeys.includes('Passport')) {
          return true;
        }

        if (data.Passport !== undefined) {
          return (
            data.Passport.file.size <= constants.MAX_FILE_SIZE &&
            constants.IMAGE_FILE_TYPES.includes(data.Passport.file.type)
          );
        }
      },
      {
        path: ['Passport'],
        message:
          'Passport should be a valid jpg, jpeg or png that is less than 5MB.',
      }
    )
    .refine(
      (data) => {
        // We only need to validate updated docs - if the user hasn't updated the doc, we don't need to validate it
        const formKeys = Object.keys(docUpdatesByType);

        if (!formKeys.includes('Power_Of_Attorney')) {
          return true;
        }
        if (data.Power_Of_Attorney !== undefined) {
          return (
            data.Power_Of_Attorney.file.size <= constants.MAX_DOCUMENT_SIZE &&
            constants.DOCUMENT_FILE_TYPES.includes(
              data.Power_Of_Attorney.file.type
            )
          );
        }
      },
      {
        path: ['Power_Of_Attorney'],
        message:
          'Power of Attorney should be a valid pdf that is less than 50MB.',
      }
    )
    .refine(
      (data) => {
        // We only need to validate updated docs - if the user hasn't updated the doc, we don't need to validate it
        const formKeys = Object.keys(docUpdatesByType);

        if (!formKeys.includes('proofOfAddress')) {
          return true;
        }

        if (data.proofOfAddress !== undefined) {
          return (
            data.proofOfAddress.file.size <= constants.MAX_DOCUMENT_SIZE &&
            constants.DOCUMENT_FILE_TYPES.includes(
              data.proofOfAddress.file.type
            )
          );
        }
      },
      {
        path: ['proofOfAddress'],
        message:
          'Proof of Address should be a valid pdf that is less than 50MB.',
      }
    )
    .refine(
      (data) => {
        // We only need to validate updated docs - if the user hasn't updated the doc, we don't need to validate it
        const formKeys = Object.keys(docUpdatesByType);

        if (!formKeys.includes('Terms_of_Agreement')) {
          return true;
        }

        if (data.Terms_Of_Agreement !== undefined) {
          return (
            data.Terms_Of_Agreement.size <= constants.MAX_DOCUMENT_SIZE &&
            constants.DOCUMENT_FILE_TYPES.includes(data.Terms_Of_Agreement.type)
          );
        }
      },
      {
        path: ['Terms_Of_Agreement'],
        message:
          'Terms of Agreement should be a valid pdf that is less than 50MB.',
      }
    );

  const onFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | null,
    docId: string | undefined
  ) => {
    if (event === null) {
      return;
    }
    if (!event.target.value) {
      return;
    }
    if (event.target.files === null) {
      return;
    }

    const file = event.target.files[0];
    const data = {
      file: file,
    };

    if (event.target.value) {
      setDocUpdatesByType({
        ...docUpdatesByType,
        [event.target.name]: {
          edited: true,
          documentName: event.target
            .name as ClientIdentityDocumentSubmissionDocumentTypeEnum,
          docId: docId,
          filename: event.target.files[0].name,
          file: event.target.files[0],
        },
      });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event);
    console.log(docUpdatesByType);
    // setDocUpdatesByType({
    //   ...docUpdatesByType,
    //   Passport: {
    //     ...docUpdatesByType['Passport'],
    //     documentExpiryDate: event.target.value,
    //   },
    // });
  };

  const fetchIdentityDocTypes = () => {
    api.retrieveIdentityDocumentTypes().then((response) => {
      setDocTypes(
        response.documentTypes as ClientIdentityDocumentSubmissionDocumentTypeEnum[]
      );
    });
  };

  useEffect(() => {
    if (userId !== undefined) {
      fetchData();
    }
  }, [docTypes]);

  useEffect(() => {
    fetchIdentityDocTypes();
  }, []);

  const fetchData = async () => {
    if (userId === undefined) {
      return;
    }

    try {
      const responses: ClientIdentityDocumentSubmission[] = await Promise.all(
        docTypes.map((documentType) => {
          return api.retrieveLatestClientIdentityDocumentSubmission(
            { userId, documentType },
            ({ init }) =>
              Promise.resolve({
                ...init,
                headers: {
                  ...init.headers,
                  Accept: 'application/json',
                },
              })
          );
        })
      );

      responses.forEach((response) => {
        // Ensure documentType is a string
        const docType = response.documentType as string;

        if (docType === 'Passport' && response.documentExpiryDate) {
          setPassportExpiryDate(response.documentExpiryDate);
        }

        if (response.file !== undefined) {
          setDocsByType((prevDocs) => ({
            ...prevDocs,
            [docType]: response,
          }));
        }
      });
    } catch (error) {
      throwError(new WError('Failed to fetch documents'));
    }
  };

  const handleSubmit = async () => {
    Object.entries(docUpdatesByType).forEach(([keyName, doc]) => {
      if (!doc.edited) {
        return;
      }
      if (!doc.file) {
        return;
      }

      if (doc.docId) {
        api
          .updateClientIdentityDocumentSubmission(
            {
              userId,
              documentId: doc.docId,
              file: doc.file,
            },
            ({ init }) => {
              return Promise.resolve({
                ...init,
                headers: {
                  ...init.headers,
                  Accept: 'application/json',
                },
              });
            }
          )
          .then((res) => {})
          .catch(() => {});
        return;
      }

      api
        .createClientIdentityDocumentSubmission(
          {
            userId,
            documentType: doc.documentName,
            file: doc.file,
          },
          ({ init }) => {
            return Promise.resolve({
              ...init,
              headers: {
                ...init.headers,
                Accept: 'application/json',
              },
            });
          }
        )
        .then((response) => {
          setDocsByType({
            ...docsByType,
            [doc.documentName]: response,
          });
        })
        .catch(() => {});
    });
  };

  const addMoreAgreements = () => {
    let agreements = [...formData.agreements];
    agreements.push({
      currentAgreement: undefined,
      terminationLetter: undefined,
      terminationConfirmation: undefined,
    });
    setFormData({ ...formData, agreements });
  };

  const fileInputValue = (
    type: ClientIdentityDocumentSubmissionDocumentTypeEnum
  ) => {
    if (docsByType[type]) {
      return {
        fileName: docsByType[type].filename,
        fileUrl: docsByType[type].fileUrl,
        file: undefined,
      };
    }
    return undefined;
  };

  return (
    <>
      <WFormProvider
        submitOnEnter={false}
        formData={docUpdatesByType}
        handleSubmit={handleSubmit}
        schema={docSchema}
      >
        <WContainer>
          <p className="tw-text-2xl tw-mb-6">My Documents</p>

          <div className="tw-flex tw-flex-col">
            <WFileUpload
              name="Terms_Of_Agreement"
              label="Mandate"
              onFileChange={(event) => {
                console.log(docsByType);
                onFileChange(event, docsByType['Terms_Of_Agreement']?.id);
              }}
              value={fileInputValue('Terms_Of_Agreement')}
            />
            <WFileUpload
              name="Power_Of_Attorney"
              label="Power of Attorney"
              onFileChange={(event) => {
                onFileChange(event, docsByType['Power_Of_Attorney']?.id);
              }}
              value={fileInputValue('Power_Of_Attorney')}
            />
            <WFileUpload
              name="Passport"
              label="Passport"
              onFileChange={(event) => {
                onFileChange(event, docsByType['Passport']?.id);
              }}
              value={fileInputValue('Passport')}
            />
            <WInput
              type="date"
              name="passportExpiryDate"
              label="Passport Expiry Date"
              disabled={true} // TODO: Enable this when we have a way to update this
              onChange={handleInputChange}
              value={passportExpiryDate}
            />
            <WFileUpload
              name="proofOfAddress"
              label="Proof of Address"
              onFileChange={(event) => {
                onFileChange(event, docsByType['Proof_Of_Address']?.id);
              }}
              value={fileInputValue('Proof_Of_Address')}
            />
            {/* Phase II */}
            {/* <hr className="tw-h-px tw-my-8 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />
            <p className="tw-text-2xl tw-mb-2">
              Terminating Agency Agreement(s)
            </p>
            <p className="tw-mb-6">
              Please upload any existing neighboring rights agent agreements
            </p> */}
            {formData.agreements.map((agreement, idx) => {
              return (
                <div key={idx}>
                  {idx > 0 && (
                    <hr className="tw-h-px tw-my-8 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />
                  )}
                  <WFileUpload
                    name={`agreements[${idx}].currentAgreement`}
                    label="Agreement"
                    onFileChange={(event) => {
                      onAgreementFileChange(event, idx, 'currentAgreement');
                    }}
                    value={agreement.currentAgreement}
                  />
                  <WFileUpload
                    name={`agreements[${idx}].terminationLetter`}
                    label="Termination Letter"
                    onFileChange={(event) => {
                      onAgreementFileChange(event, idx, 'terminationLetter');
                    }}
                    value={agreement.terminationLetter}
                  />
                  <WFileUpload
                    name={`agreements[${idx}].terminationConfirmation`}
                    label="Termination Confirmation"
                    onFileChange={(event) => {
                      onAgreementFileChange(
                        event,
                        idx,
                        'terminationConfirmation'
                      );
                    }}
                    value={agreement.terminationConfirmation}
                  />
                </div>
              );
            })}

            {/* <AddMoreButton addMoreAgreements={addMoreAgreements} /> */}
          </div>
        </WContainer>
        <WFormFooter />
        <div className="tw-flex tw-justify-start">
          <FormEditSection />
        </div>
      </WFormProvider>
    </>
  );
}
interface AddMoreButtonProps {
  addMoreAgreements: () => void;
}

function FormEditSection() {
  const { isEditing, setIsEditing, errors } = useFormContext(); // Fetch errors from context
  return (
    <>
      {' '}
      {!isEditing && (
        <WButton
          icon="edit"
          variant="primary"
          label="Edit"
          onClick={() => setIsEditing(true)}
        />
      )}
    </>
  );
}

const AddMoreButton: React.FC<AddMoreButtonProps> = ({ addMoreAgreements }) => {
  const { isEditing } = useFormContext();
  return (
    <>
      {isEditing && (
        <div className="tw-flex tw-justify-end">
          <WButton
            variant="outline-light"
            label="+ Add more agreements"
            onClick={() => {
              addMoreAgreements();
            }}
          />
        </div>
      )}
    </>
  );
};
export default DocumentsUpdateForm;
