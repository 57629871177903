import React, { useState, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import DataTable from 'react-data-table-component';

import PageLayout from './PageLayout';
import WContainer from 'shared_components/components/WContainer';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import WModal from 'shared_components/components/WModal';
import { WError } from 'shared_components/components/WError/WError';
import AnnotatedEvidenceModal from 'shared_components/components/AnnotatedEvidenceModal';
import roleIconMap from 'shared_components/maps/role-icon-map.json';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { useApi } from 'shared_components/context';
import {
  PrimaryDiscographyRecordingsInnerPplContributionsInner,
  PrimaryDiscographyRecordingsInnerPplContributionsInnerWestburyStatusEnum,
} from 'shared_components/generated/client';
import { basePath } from 'shared_components/context/auth';
import { useUser, useUserId } from 'shared_components/context/user';
import customFetch from 'shared_components/utils/customFetch';
import { asyncEvidenceUrlIterator } from 'shared_components/utils/asyncEvidenceUrlIterator';

interface DiscographyRow {
  id: string;
  artist: string;
  title: string;
  release: string;
  year?: string;
  isrcs: string;
  contributions: string[];
  pplContributions: PrimaryDiscographyRecordingsInnerPplContributionsInner[];
  annotatedEvidenceObjectKeys: string[];
}

interface GetSignedAnnotatedEvidenceUrlsParams {
  parentRecordingId?: string;
  parentReleaseId?: string;
  maxUrlsPerRelease?: number;
}

type rowIdImageElementMap = {
  [id: string]: HTMLImageElement;
};

type StatusColorMap = {
  [STATUS in PrimaryDiscographyRecordingsInnerPplContributionsInnerWestburyStatusEnum]: string;
};

const statusColorMap: StatusColorMap = {
  SUBMITTED_TO_SOCIETY: 'tw-text-[#00B3FF]',
  EVIDENCE_NEEDED: 'tw-text-[#FF2600]',
  REGISTERED_WITH_SOCIETY: 'tw-text-[#6EE038]',
  TO_BE_REGISTERED: 'tw-text-textDove',
  OTHER: 'tw-text-textDove',
};

function Discography() {
  const { clientApi: api } = useApi();
  const user = useUser();
  const { userId } = useUserId();
  const { throwError } = useWError();

  const [images, setImages] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [rows, setRows] = useState<DiscographyRow[]>([]);

  const [annotatedEvidenceModelUrls, setAnnotatedEvidenceModelUrls] = useState<
    string[]
  >([]);
  const [isOpenAnnotatedEvidenceModal, setIsOpenAnnotatedEvidenceModal] =
    useState<boolean>(false);
  const [isOpenContributionRoleModal, setIsOpenContributionRoleModal] =
    useState<boolean>(false);

  const registrationsColumnHeading = useMemo(() => {
    if (!user.verifiedStatus) {
      return (
        <p>
          Verify your identity to view your associated recordings and available
          PPL contribution roles
        </p>
      );
    }

    if (!user.pplAccountName) {
      return (
        <p>
          View your associated recordings and available PPL contribution roles.
          Register at PPL with Westbury to view all of your contribution roles
        </p>
      );
    }

    return <p>View your associated recordings and contribution roles at PPL</p>;
  }, [user.verifiedStatus, user.signedStatus]);

  useEffect(() => {
    fetchData();
    // refetch user data in case verified or signed status have changed since previous render
    user.fetchData();
  }, [userId]);

  const fetchData = async () => {
    if (userId === undefined) {
      return;
    }

    setLoading(true);

    try {
      const discography = await api.retrievePrimaryDiscography({
        userId: userId,
      });

      const rows = discography.recordings.flatMap((recording) => {
        return recording.parentReleases.map((release) => {
          const rowId = `${recording.parentRecording.id}/${release.id}`;
          return {
            id: rowId,
            artist: recording.parentRecording.mainArtists?.toString() ?? '',
            title: recording.parentRecording.recordingTitle[0],
            release: release.releaseTitle?.length
              ? release.releaseTitle[0]
              : '',
            year: release.dateReleased?.at(0),
            isrcs: recording.parentRecording.isrcs?.toString() ?? '',
            contributions: recording.westburyContributions.map(
              (westburyContribution) => westburyContribution.contribution
            ),
            pplContributions: recording.pplContributions,
            annotatedEvidenceObjectKeys: (
              recording.parentRecording.evidenceAnnotatedUrls ?? []
            ).filter(
              (url) =>
                url.includes(release.id) ||
                release.sourceReleaseId.some((id) => url.includes(id))
            ),
          };
        });
      });

      setRows(rows);
    } catch (error) {
      console.error(error);
      throwError(new WError('Error loading data'));
      setLoadingError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    let stream: ReadableStream<Uint8Array>;
    let reader: ReadableStreamDefaultReader<Uint8Array>;
    let intervalId: any;
    const fetchEvidenceUrls = async () => {
      const response = await getSignedAnnotatedEvidenceUrls({
        maxUrlsPerRelease: 1,
      });
      if (response === undefined || !response.body) {
        return;
      }

      stream = response.body;
      reader = stream.getReader();

      // Buffer to hold the images temporarily
      let buffer: { [key: string]: string } = {};

      try {
        const processBatch = () => {
          setImages((prevImages) => ({
            ...prevImages,
            ...buffer,
          }));

          buffer = {};
        };

        // Start the interval to process the batch every 1 second
        intervalId = setInterval(processBatch, 1000);

        for await (const signedUrl of asyncEvidenceUrlIterator(reader)) {
          const [parentRecordingId, parentReleaseId] = signedUrl.url
            .split('/')
            .slice(-3, -1);
          const rowId = `${parentRecordingId}/${parentReleaseId}`;

          buffer[rowId] = signedUrl.url;
        }

        // Process any remaining images
        processBatch();

        clearInterval(intervalId);
      } catch (error) {
        console.error(error);
        throwError(new WError('Failed to load evidence images'));

        clearInterval(intervalId);
      }
    };

    fetchEvidenceUrls();

    return () => {
      if (reader && stream.locked) {
        reader.cancel();
      }
    };
  }, [rows]);

  const getSignedAnnotatedEvidenceUrls = async ({
    parentRecordingId,
    parentReleaseId,
    maxUrlsPerRelease,
  }: GetSignedAnnotatedEvidenceUrlsParams) => {
    if (userId === undefined) {
      return;
    }

    try {
      /**
       * Schema generation does not support streaming responses.
       * As a workaround, this request is performed manually
       * using the Fetch API.
       */
      const response = await customFetch(
        `${basePath}/portal/users/${userId}/primary-discography/annotated-evidence-signed-urls/?` +
          new URLSearchParams({
            ...(parentRecordingId && {
              parent_recording_id: parentRecordingId,
            }),
            ...(parentReleaseId && {
              parent_release_id: parentReleaseId,
            }),
            ...(maxUrlsPerRelease && {
              max_urls_per_release: maxUrlsPerRelease.toString(),
            }),
          })
      );

      return response;
    } catch (error) {
      console.error(error);
      throwError(new WError('Failed to load evidence images'));
    }
  };

  const handleOpenAnnotatedEvidenceModal = async (rowId: string) => {
    const [parentRecordingId, parentReleaseId] = rowId.split('/');
    const response = await getSignedAnnotatedEvidenceUrls({
      parentRecordingId,
      parentReleaseId,
    });
    setIsOpenAnnotatedEvidenceModal(true);
    if (response === undefined || !response.body) {
      return;
    }
    const reader = response.body.getReader();
    try {
      for await (const signedUrl of asyncEvidenceUrlIterator(reader)) {
        setAnnotatedEvidenceModelUrls((prevAnnotatedEvidenceModalUrls) => [
          ...prevAnnotatedEvidenceModalUrls,
          signedUrl.url,
        ]);
      }
    } catch (error) {
      console.error(error);
      throwError(new WError('Failed to load evidence images'));
    }
  };

  const handleCloseAnnotatedEvidenceModal = () => {
    setAnnotatedEvidenceModelUrls([]);
    setIsOpenAnnotatedEvidenceModal(false);
  };

  const columns = [
    {
      name: (
        <div className="tw-h-full tw-flex tw-flex-col tw-justify-between">
          <div className="tw-border-l tw-border-l-[#FF00A6] tw-pl-2">
            <div className="tw-uppercase tw-mb-4 tw-text-xs">
              WMC Discography
            </div>
            <p className="tw-h-20 tw-mb-4 tw-w-max tw-flex-1">
              View your AI generated Westbury Music discography
            </p>
          </div>
        </div>
      ),
      cell: (row: DiscographyRow) => (
        <div className="tw-w-[45px] tw-h-[45px]">
          {row.annotatedEvidenceObjectKeys.length ? (
            <button
              onClick={() => handleOpenAnnotatedEvidenceModal(row.id)}
              className="tw-w-full tw-h-full"
            >
              {images[row.id] ? (
                <img
                  src={images[row.id]}
                  alt="Annotated Evidence"
                  className="tw-w-full tw-h-full"
                />
              ) : (
                <div className="tw-flex tw-justify-center">
                  <p className="tw-my-auto tw-animate-ping">⚪</p>
                </div>
              )}
            </button>
          ) : (
            <div className="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
              <i className="wi wi-shut-eye wi-20px" />
            </div>
          )}
        </div>
      ),
      minWidth: '80px',
      maxWidth: '80px',
    },
    {
      name: (
        <div className="tw-flex tw-flex-col tw-justify-end tw-h-full">
          Artist
        </div>
      ),
      sortable: true,
      selector: (row: DiscographyRow) => row.artist,
      minWidth: '200px',
      maxWidth: '200px',
    },
    {
      name: (
        <div className="tw-flex tw-flex-col tw-justify-end tw-h-full">
          Title
        </div>
      ),
      selector: (row: DiscographyRow) => row.title,
      minWidth: '200px',
      maxWidth: '200px',
      sortable: true,
    },
    {
      name: (
        <div className="tw-flex tw-flex-col tw-justify-end tw-h-full">
          Release
        </div>
      ),
      selector: (row: DiscographyRow) => row.release,
      minWidth: '210px',
      maxWidth: '210px',
      sortable: true,
    },
    {
      name: (
        <div className="tw-flex tw-flex-col tw-justify-end tw-h-full">Year</div>
      ),
      selector: (row: DiscographyRow) =>
        row.year ? new Date(row.year).getFullYear() : '',
      minWidth: '110px',
      maxWidth: '110px',
      sortable: true,
    },
    {
      name: (
        <div className="tw-flex tw-flex-col tw-justify-end tw-h-full">
          ISRC(s)
        </div>
      ),
      selector: (row: DiscographyRow) => row.isrcs,
      minWidth: '170px',
      maxWidth: '170px',
      sortable: true,
    },
    {
      name: (
        <div className="tw-flex tw-flex-col tw-justify-end tw-h-full">
          Contributions
        </div>
      ),
      cell: (row: DiscographyRow) => (
        <div className="tw-flex gap-2">
          {row.contributions.map((westburyContribution, i) => (
            <OverlayTrigger
              key={i}
              delay={{ hide: 350, show: 300 }}
              overlay={(props) => (
                <Tooltip
                  className="black-bg datatable-tip tw-capitalize"
                  {...props}
                >
                  {westburyContribution}
                </Tooltip>
              )}
              placement="top"
            >
              <div className="tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-rounded-sm tw-bg-surfaceBlack80">
                <i
                  className={clsx(
                    'wi wi-xs',
                    `wi-role-${roleIconMap[westburyContribution] || 'general'}`
                  )}
                />
              </div>
            </OverlayTrigger>
          ))}
        </div>
      ),
      minWidth: '295px',
      maxWidth: '295px',
    },
    {
      name: (
        <div className="tw-flex tw-flex-col tw-justify-between tw-h-full">
          <div className="tw-uppercase tw-mb-4 tw-text-xs">PPL Discography</div>
          <p className="tw-mb-4">{registrationsColumnHeading}</p>
          <div>Contributions</div>
        </div>
      ),
      cell: (row: DiscographyRow) => (
        <div className="tw-flex gap-2">
          {row.pplContributions.map(
            ({ westburyContribution, westburyStatus }, i) => (
              <OverlayTrigger
                key={i}
                delay={{ hide: 150, show: 100 }}
                overlay={(props) => (
                  <Tooltip
                    className="black-bg datatable-tip tw-capitalize"
                    {...props}
                  >
                    {westburyContribution}
                  </Tooltip>
                )}
                placement="top"
              >
                <button
                  onClick={() =>
                    westburyContribution === 'contributor' &&
                    setIsOpenContributionRoleModal(true)
                  }
                  className="tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-rounded-sm tw-bg-surfaceBlack80"
                >
                  <i
                    className={clsx(
                      'wi wi-xs',
                      `wi-role-${
                        roleIconMap[westburyContribution] || 'general'
                      }`,
                      statusColorMap[westburyStatus ?? 'OTHER']
                    )}
                  />
                </button>
              </OverlayTrigger>
            )
          )}
        </div>
      ),
      minWidth: '295px',
      maxWidth: '295px',
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        borderBottom: 'none !important',
        borderBottomColor: 'none !important',
        fontSize: '14px',
        background: '#151719',
      },
    },
    headCells: {
      style: {
        background: 'transparent',
        color: '#fff',
        paddingBottom: '12px',
        '&:last-of-type': {
          borderLeftStyle: 'solid',
          borderLeftWidth: '1px',
          borderLeftColor: '#535D66',
        },
        '.rdt_TableCol_Sortable span': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        },
        '.rdt_TableCol_Sortable': {
          overflow: 'visible',
        },
      },
    },
    cells: {
      style: {
        background: '#151719',
        color: '#fff',
        fontWeight: '300',
        overflowX: 'auto',
        '&:last-of-type': {
          borderLeftStyle: 'solid',
          borderLeftWidth: '1px',
          borderLeftColor: '#535D66',
        },
      },
    },
    rows: {
      style: {
        fontSize: '14px',
        minHeight: '60px',
        borderBottom: '1px solid #535D66 !important',
      },
    },
    pagination: {
      style: {
        color: '#fff',
        fontSize: '13px',

        backgroundColor: '#151719',
      },
      pageButtonsStyle: {
        color: '#fff',
        fill: '#fff',
        backgroundColor: 'transparent',
        '&:disabled': {
          cursor: 'unset',
          color: '#535D66',
          fill: '#535D66',
        },
      },
    },
  };

  return (
    <PageLayout>
      {isOpenAnnotatedEvidenceModal && (
        <AnnotatedEvidenceModal
          isOpen={isOpenAnnotatedEvidenceModal}
          onClose={handleCloseAnnotatedEvidenceModal}
          urls={annotatedEvidenceModelUrls}
        />
      )}
      <div className="tw-flex">
        <h2>Your Discography</h2>
      </div>
      <div className="tw-flex tw-mt-6 tw-mb-6">
        <WButton
          variant="link-secondary"
          icon="chevron-left"
          onClick={() => {
            window.history.back();
          }}
          label="Back"
        />
      </div>
      <div className="pageDetailsWrapper">
        <Keys />
        <WContainer>
          <WModal
            title="Contributor"
            isOpen={isOpenContributionRoleModal}
            onClose={() => {
              setIsOpenContributionRoleModal(false);
            }}
            modalClasses="tw-max-w-md"
          >
            <div className="tw-text-md tw-space-y-8 tw-py-4">
              <p>
                The{' '}
                <i className="wi wi-sm wi-role-contributor -tw-translate-y-0.5"></i>{' '}
                symbol means that you are recognised as a contributor to this
                recording.
              </p>
              <p>
                All of your contribution roles are visible once associated to
                WMC in PPL's database.
              </p>
            </div>
          </WModal>
          <div>
            {rows.length > 0 ? (
              <DataTable
                columns={columns}
                data={rows}
                customStyles={customStyles}
                fixedHeader
                fixedHeaderScrollHeight="7000px" // around 100 rows
                sortIcon={<CustomSortIcon isSorted={false} direction={'asc'} />}
                pagination
                paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
                paginationPerPage={100}
              />
            ) : (
              <div>
                {loading ? (
                  <div className="tw-flex tw-justify-center">
                    <p className="tw-my-auto tw-animate-ping">⚪</p>
                  </div>
                ) : (
                  <div className="tw-text-center">
                    {loadingError
                      ? 'Error loading data'
                      : 'No data available currently'}
                  </div>
                )}
              </div>
            )}
          </div>
        </WContainer>
      </div>
    </PageLayout>
  );
}

export default Discography;

const CustomSortIcon: React.FC<{
  isSorted: boolean;
  direction: 'asc' | 'desc';
}> = ({ isSorted, direction }) => {
  if (!isSorted) {
    return <span className="tw-translate-y-0.5"> &#8693;</span>; // Not sorted
  }

  return direction === 'asc' ? <span>⇡</span> : <span>⇣</span>;
};

const Keys: React.FC = () => {
  return (
    <div className="tw-flex tw-justify-end tw-space-x-4 tw-items-center">
      <Key
        color="tw-bg-[#6EE038]"
        label="Registered with society"
        tooltip="This contribution has been registered with the society"
      />
      <Key
        color="tw-bg-[#FF2600]"
        label="Evidence needed"
        tooltip="The society has requested evidence for this contribution"
      />

      <Key
        color="tw-bg-[#00B3FF]"
        label="Submitted to society"
        tooltip="We have submitted this contribution to the society."
      />
      <Key
        color="tw-bg-textDove"
        label="To be registered"
        tooltip="This contribution is pending submission to the society."
      />
    </div>
  );
};

const Key: React.FC<{ color: string; label: string; tooltip: string }> = ({
  color,
  label,
  tooltip,
}) => {
  return (
    <div>
      <OverlayTrigger
        delay={{ hide: 350, show: 300 }}
        overlay={(props) => (
          <Tooltip className="black-bg" {...props}>
            {tooltip}
          </Tooltip>
        )}
        placement="left"
      >
        <span className="pointer-cursor tw-flex tw-text-white tw-leading-1 tw-items-center">
          <span
            className={`tw-rounded-full ${color} tw-mr-2`}
            style={{
              width: '10px',
              height: '10px',
              display: 'block',
            }}
          ></span>
          {label}
        </span>
      </OverlayTrigger>
    </div>
  );
};
