/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminTaskClientClientAdminTasksInner
 */
export interface AdminTaskClientClientAdminTasksInner {
    /**
     * 
     * @type {string}
     * @memberof AdminTaskClientClientAdminTasksInner
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTaskClientClientAdminTasksInner
     */
    status?: AdminTaskClientClientAdminTasksInnerStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof AdminTaskClientClientAdminTasksInner
     */
    readonly dateCreated?: Date;
}


/**
 * @export
 */
export const AdminTaskClientClientAdminTasksInnerStatusEnum = {
    VerifyPassportAndSendForms: 'verify_passport_and_send_forms',
    ExpressionOfInterestReview: 'expression_of_interest_review',
    SubmitDiscographyId: 'submit_discography_id',
    ApproveWestburyForms: 'approve_westbury_forms',
    SignSocietyForm: 'sign_society_form',
    ApproveMembershipForms: 'approve_membership_forms',
    DownloadPrimaryDiscographyExport: 'download_primary_discography_export'
} as const;
export type AdminTaskClientClientAdminTasksInnerStatusEnum = typeof AdminTaskClientClientAdminTasksInnerStatusEnum[keyof typeof AdminTaskClientClientAdminTasksInnerStatusEnum];


/**
 * Check if a given object implements the AdminTaskClientClientAdminTasksInner interface.
 */
export function instanceOfAdminTaskClientClientAdminTasksInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminTaskClientClientAdminTasksInnerFromJSON(json: any): AdminTaskClientClientAdminTasksInner {
    return AdminTaskClientClientAdminTasksInnerFromJSONTyped(json, false);
}

export function AdminTaskClientClientAdminTasksInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminTaskClientClientAdminTasksInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'dateCreated': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
    };
}

export function AdminTaskClientClientAdminTasksInnerToJSON(value?: AdminTaskClientClientAdminTasksInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}

