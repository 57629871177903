import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import WContainer from 'shared_components/components/WContainer';
import React from 'react';
import { Payment } from 'shared_components/generated/client/models';
import WTable from 'shared_components/WTable/WTable';

interface StatementWidgetProps {
  clientPayments: Payment[];
  viewStatement: (e: React.MouseEvent<HTMLElement>) => void;
}

export const StatementWidget = ({
  clientPayments,
  viewStatement,
}: StatementWidgetProps) => {
  const numToString = (num: number) => {
    const parseNumber = num / 100;
    const toLocale = parseNumber.toLocaleString(navigator.language, {
      minimumFractionDigits: 2,
    });
    return toLocale;
  };

  const tableData = clientPayments.slice(0, 4).map((payment, index) => {
    return {
      date: <div>{format(new Date(payment.paymentDate), 'dd/MM/yyyy')}</div>,
      total: <div>&#163; {numToString(payment.amountPaidToClient!)}</div>,
      viewStatement: (
        <div>
          <button
            className="tw-flex tw-border-white tw-border-2 tw-px-4 tw-py-2 tw-rounded-full"
            onClick={viewStatement}
          >
            View Statement
          </button>
        </div>
      ),
    };
  });

  return (
    <WContainer
      extraClasses="tw-h-[245px] tw-overflow-y-auto tw-scrollbar-w-10 tw-scrollbar-h-57 tw-scrollbar-thumb-black tw-scrollbar-thumb-rounded-full"
      disabled
      title="My Statements"
    >
      <div className="tw-flex tw-justify-between">
        <div>
          <h2>My Statements</h2>
          <p>Generate income by registering with societies</p>
        </div>
        <div>
          <Link to="/statements" className="text-white">
            View All
          </Link>
        </div>
      </div>
      <div>
        <WTable
          columns={[
            { title: 'Date', dataKey: 'date' },
            { title: 'Total', dataKey: 'total' },
            { title: ' ', dataKey: 'viewStatement' },
          ]}
          data={tableData}
        />
      </div>
    </WContainer>
  );
};
