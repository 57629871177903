import React from 'react';
import { DashboardDetails } from './DashBoardDetails';
import PageLayout from './PageLayout';

function Dashboard() {
  return (
    <PageLayout>
      <DashboardDetails />
    </PageLayout>
  );
}

export default Dashboard;
