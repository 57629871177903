import React from 'react';

type ButtonStyles =
  | 'btn-primary'
  | 'btn-info'
  | 'btn-info-outline'
  | 'btn-link';

interface ButtonProps {
  onClick: () => void;
  styleType: ButtonStyles;
  children: string;
  width?: string;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  styleType,
  width = '154',
  disabled = false,
}) => {
  let styleClass: string;

  switch (styleType) {
    case 'btn-primary':
      styleClass = `tw-bg-surfaceLime tw-text-black tw-px-5 tw-py-3 tw-rounded-full tw-w-[${width}]`;
      break;
    case 'btn-info':
      styleClass = 'tw-bg-teal-500 tw-text-white';
      break;
    case 'btn-info-outline':
      styleClass = 'tw-border tw-border-teal-500 tw-text-teal-500';
      break;
    case 'btn-link':
      styleClass =
        'tw-bg-transparent tw-text-white hover:tw-textLime tw-font-medium active:tw-textLime';
      break;
    default:
      styleClass = 'tw-bg-blue-500 tw-text-white';
  }

  return (
    <button
      className={`${styleClass} tw-rounded ${disabled ? 'tw-grayscale' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
