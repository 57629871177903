import React from 'react';
// import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';

import { WToaster } from 'shared_components/utils/Toast';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import EOIBox from './view/Client/PageLayout/EOIBox';
import SignUpForm from './view/Client/PageLayout/SignUpForm';
import CreateYourClientAccount from './view/Client/CreateYourClientAccount/CreateYourClientAccount';
import SignInForm from './view/Client/PageLayout/ClientLogin';
import Dashboard from './view/Client/PageLayout/Dashboard';
import Statements from './view/Client/PageLayout/Statements';
import Profile from './view/Client/PageLayout/Profile';
import Discography from './view/Client/PageLayout/Discography';
import UploadPDF from './view/Client/PageLayout/UploadPDF';
import { ApiProvider } from 'shared_components/context';
import { UserProvider } from 'shared_components/context/user';
import { useApi } from 'shared_components/context';
import { ClientProvider } from 'shared_components/context/client';
import { WErrorProvider } from 'shared_components/components/WError/WErrorProvider';
import './App.css';
import './global.css';
import 'shared_components/dashboard_icons.css';
import 'shared_components/icons.css';

// Sentry.init({
//   dsn: 'https://30580e35a058b9d0cd7f38f46e45b628@o4506376806465536.ingest.sentry.io/4506376859090944',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         'https://portal.prod.westburymusic.com/',
//         'https://portal.dev.westburymusic.com/',
//       ],
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

posthog.init('phc_PUGeiiTXxm2q7fPp5n8d77UgtXu59IZgUdbRVEVrixF', {
  api_host: 'https://eu.posthog.com',
});

function App() {
  const { clientApi: api } = useApi();

  return (
    <WErrorProvider>
      <ApiProvider>
        <UserProvider api={api}>
          <ClientProvider>
            <BrowserRouter>
              <WToaster />
              <Routes>
                <Route
                  path="/create-your-client-account/:client_email_hashed"
                  element={<CreateYourClientAccount />}
                />
                <Route path="/signup" element={<SignUpForm />} />
                <Route path="/signin" element={<SignInForm />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/statements" element={<Statements />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/discography" element={<Discography />} />
                <Route path="/uploadpdf" element={<UploadPDF />} />
                <Route path="/" element={<EOIBox />} />
              </Routes>
            </BrowserRouter>
          </ClientProvider>
        </UserProvider>
      </ApiProvider>
    </WErrorProvider>
  );
}

export default App;
