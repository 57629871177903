import React, { useState, useEffect } from 'react';
import { useWizardContext } from 'shared_components/components/WForms/Wizard';
import clsx from 'clsx';
import './ProfileOnboardingSteps.css';
export const ProfileOnboardingSteps = () => {
  const { currentStep } = useWizardContext();
  const steps = [
    <>
      Personal <br /> Information
    </>,
    <>
      Society <br /> Registrations
    </>,
    <>
      Payment <br /> Details
    </>,
  ];
  return (
    <>
      <div
        className=" tw-mt-6 tw-max-w-[550px]"
        style={{ marginLeft: '-25px' }}
      >
        <ol className="profile-onboarding-steps">
          {steps.map((step, index) => {
            return (
              <li
                className={clsx({
                  'profile-current-step': currentStep === index,
                })}
                key={index}
              >
                {step}
              </li>
            );
          })}
        </ol>
      </div>
    </>
  );
};
