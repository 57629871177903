import React, { useState, useEffect } from 'react';
import ClientPersonalInfo from '../ClientPersonalInfo';
import clientMap from 'shared_components/public/assets/NewWorldMap.svg';
import { useApi } from 'shared_components/context';
import { useUserId } from 'shared_components/context/user';
import {
  StatementsJson,
  StatementsJsonMetadata,
  StatementsJsonPaymentsInner,
} from 'shared_components/generated/client/models';
import { Payment } from 'shared_components/generated/client/models';
import {
  basePath,
  getTokenFromSessionStorage,
} from 'shared_components/context/auth';
import WContainer from 'shared_components/components/WContainer';
import { OnboardingHeader } from './OnBoardingHeader';
import { CircleChart } from 'shared_components/components/CircleChart';
import { StatementWidget } from './StatementWidget';
import AvatarWelcome from './AvatarWelcome';
import { useUser } from 'shared_components/context/user';
import ClientTasksBox from './ClientTasksBox';
import { useNavigate } from 'react-router-dom';
import customFetch from 'shared_components/utils/customFetch';

export const DashboardDetails = () => {
  const user = useUser();
  const { clientApi: api } = useApi();
  const navigate = useNavigate();
  const { userId } = useUserId();
  const token = getTokenFromSessionStorage();
  const [firstName, setFirstName] = useState('Me');
  const [clientPayments, setClientPayments] = useState<Payment[]>([]);
  const [statementView, setStatementView] = useState(false);
  const [clientInfo, setClientInfo] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState<StatementsJsonMetadata>();
  const [statementData, setStatementData] = useState<
    StatementsJsonPaymentsInner[]
  >([]);
  const [statementPdf, setStatementPdf] = useState('');

  const fetchData = () => {
    if (userId === undefined) {
      return;
    }
    api
      .retrieveClientPersonalInformation({ userId })
      .then((personalInfo) => {
        setFirstName(personalInfo.firstName ?? 'Me');
      })
      .catch(() => {});
    api
      .listPayments({ userId })
      .then((payments) => {
        setClientPayments(payments);
      })
      .catch(() => {});
  };

  const viewStatement = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (userId === undefined) {
      return;
    }
    if (token === null) {
      return;
    }

    const statementId = e.currentTarget.dataset.statementday;
    if (!statementId) {
      return;
    }

    const headers = new Headers({
      Authorization: 'Bearer ' + token.access,
    });

    await api
      .retrieveStatementsJson({ userId, paymentDay: statementId })
      .then((statement: StatementsJson) => {
        setPaymentDetail(statement.metadata);
        setStatementData(statement.payments);
        /** OpenAPI typescript-fetch Generator does not support `oneOf` OAS3 feature:
         * https://openapi-generator.tech/docs/generators/typescript-fetch/#feature-set.
         * As a workaround, requests to `users/:userId/statements` route that expect a
         * non-JSON response are performed manually using the Fetch API.
         */
        return customFetch(
          `${basePath}/portal/users/${userId}/statements/?` +
            new URLSearchParams({
              payment_day: statementId,
              format: 'pdf',
            }),
          { headers }
        );
      })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        setStatementPdf(url.toString());
        setStatementView(true);
      })
      .catch(() => {
        setStatementData([]);
      });
  };

  useEffect(() => {
    fetchData();
  }, [userId]);

  const handleClientInfo = () => {
    setClientInfo(!clientInfo);
  };

  const progress = user.profileProgress
    ? Math.round((user.profileProgress / 6) * 100)
    : 0;
  const completedProfile: boolean = (user.profileProgress ?? 0) >= 5;

  const accountProgressBox = () => {
    return (
      <WContainer extraClasses="tw-w-1/2 tw-flex-col tw-justify-center">
        <h2 className="tw-font-sans tw-text-2xl ">Account Progress</h2>
        <CircleChart value={progress} text={'of account completed'} />
      </WContainer>
    );
  };
  const clientTasksBox = () => {
    // TODO: fetch client tasks from BE. Below placeholder hardcoded for release 1.
    const tasks = [
      {
        subtitle: 'Check out your discography and initial PPL review',
        description: undefined,
        cta: {
          label: 'Let’s Go',
          onClick: () => {
            navigate('/discography');
          },
        },
      },
    ];

    return <ClientTasksBox tasks={tasks} />;
  };

  return (
    <div>
      {clientInfo ? (
        <ClientPersonalInfo showClientInfo={handleClientInfo} />
      ) : (
        <div>
          <h1 className="tw-text-page-heading tw-text-white">Your Dashboard</h1>
          {!completedProfile && <OnboardingHeader />}
          <div
            className="tw-flex tw-w-full tw-space-x-6"
            style={{ maxWidth: '1584px', width: '100%' }}
          >
            <div className="tw-w-[558px] tw-flex-col tw-space-y-6 tw-flex-shrink-0">
              <div className="tw-flex tw-space-x-6">
                <AvatarWelcome />
                {completedProfile ? clientTasksBox() : accountProgressBox()}
              </div>
              <StatementWidget
                clientPayments={clientPayments}
                viewStatement={viewStatement}
              />
            </div>
            <WContainer extraClasses="tw-w-5/10 tw-h-[537px] tw-grow tw-space-6 tw-flex tw-flex-col tw-items-center">
              <h2 className="tw-font-sans tw-text-2xl tw-mb-6">
                Society Coverage
              </h2>
              <img
                src={clientMap}
                alt="map"
                className="tw-h-[418px] tw-w-[716px] tw-grow"
              />
            </WContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardDetails;
