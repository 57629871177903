import React, { useEffect, useState } from 'react';
import WContainer from 'shared_components/components/WContainer';
import { useNavigate } from 'react-router-dom';
import AgreementModal from '../../AgreementModal';
import VerificationModal from '../../VerificationModal';
import SignSocietyFormsModal from '../../SignSocietyFormsModal';
import clsx from 'clsx';
import { useUser, useUserId } from 'shared_components/context/user';
import { useApi } from 'shared_components/context';
import {
  ClientSocietyFormTask,
  ClientSocietyFormTaskStatusEnum,
  MembershipFormsStatusEnum,
} from 'shared_components/generated/client';
import WModal from 'shared_components/components/WModal';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';
import ErrorBlock from 'shared_components/components/ErrorBlock/ErrorBlock';

export const OnboardingHeader = () => {
  const [isOpenVerificationModal, setIsOpenVerificationModal] = useState(false);
  const [isOpenAgreementModal, setIsOpenAgreementModal] = useState(false);
  const [isAgreementModalLoading, setIsAgreementModalLoading] = useState(false);
  const [showWaitMessage, setShowWaitMessage] = useState(false);
  const [signSocietyFormsModalOpen, setSignSocietyFormsModalOpen] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const user = useUser();
  const { userId } = useUserId();
  const { clientApi: api } = useApi();
  const [modalSubtitle, setModalSubtitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [showSubmittedModal, setShowSubmittedModal] = useState(false);
  const profileProgress = user.profileProgress;
  const steps = [
    {
      title: 'Create Account',
      action: () => {},
      progress: 1,
    },
    {
      title: 'Verify Identity',
      action: () => {
        setIsOpenVerificationModal(true);
      },
      progress: 2,
    },
    {
      title: <>Sign WMC Forms</>,
      action: async () => {
        const clientTasks = await api.listClientSocietyFormTasks({
          userId: userId!,
        });

        if (formsSubmitted(clientTasks)) {
          setModalDescription(
            `Your WMC forms have been submitted successfully. WMC will be in contact once your witness has signed the Power of Attorney.`
          );
          setShowSubmittedModal(true);
          return;
        }

        if (waitingForForms(clientTasks)) {
          setModalSubtitle('Forms Pending');
          setModalDescription(
            `We are verifying your identity and generating your forms. We will contact you once they are ready.`
          );
          setShowWaitMessage(true);
          return;
        }

        setIsOpenAgreementModal(true);
      },
      progress: 3,
    },
    {
      title: 'Complete Profile',
      action: () => {
        navigate('/profile?onboarding=1');
      },
      progress: 4,
    },
    {
      title: <div className="tw-text-center">Sign Society Forms</div>,
      action: async () => {
        const membershipFormTasks = await api.listMembershipForms({
          userId: userId!,
        });
        const withClient = membershipFormTasks.find(
          (task) => task.status === MembershipFormsStatusEnum.WithClient
        );
        if (withClient === undefined) {
          setModalSubtitle('Forms Pending');
          setModalDescription(
            `WMC are generating your society forms. They will be in touch once ready.`
          );
          setShowWaitMessage(true);
          return;
        }
        setSignSocietyFormsModalOpen(true);
      },
      progress: 5,
    },
    { title: 'Start Earning', action: () => {}, progress: 10 },
  ];

  const waitingForForms = (clientTasks: ClientSocietyFormTask[]) => {
    if (clientTasks.length === 0) {
      return true;
    } else {
      // find uncompleted tasks and if the task is 'client_to_sign', then continue else stop
      const uncompletedTasks = clientTasks.filter(
        (task) => task.status === ClientSocietyFormTaskStatusEnum.ClientToSign
      );
      if (uncompletedTasks.length === 0) {
        return true;
      }
    }
    return false;
  };

  const formsSubmitted = (clientTasks: ClientSocietyFormTask[]) => {
    const pendingTasks = clientTasks.filter(
      (task) =>
        task.status === ClientSocietyFormTaskStatusEnum.WitnessToSign ||
        task.status === ClientSocietyFormTaskStatusEnum.AdminToApprove
    );
    if (pendingTasks.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <>
      <VerificationModal
        verifyShowModal={isOpenVerificationModal}
        setIsOpenVerificationModal={setIsOpenVerificationModal}
        verifyModalClose={() => {
          user.fetchData();
          setIsOpenVerificationModal(false);
        }}
      />

      <AgreementModal
        showModal={isOpenAgreementModal}
        modalClose={() => {
          user.fetchData();
          setIsOpenAgreementModal(false);
        }}
        isLoading={isAgreementModalLoading}
        setIsLoading={setIsAgreementModalLoading}
      />

      {signSocietyFormsModalOpen && (
        <SignSocietyFormsModal
          onClose={() => {
            user.fetchData();
            setSignSocietyFormsModalOpen(false);
          }}
        />
      )}
      <WaitModal
        isOpen={showWaitMessage}
        onClose={() => {
          setShowWaitMessage(false);
        }}
        subtitle={modalSubtitle}
        description={modalDescription}
      />

      <SubmittedModal
        isOpen={showSubmittedModal}
        onClose={() => {
          setShowSubmittedModal(false);
        }}
        subtitle=""
        description={modalDescription}
      />

      <WContainer extraClasses="tw-items-center">
        <h2 className="tw-font-sans tw-text-2xl tw-text-bold tw-pb-6">
          Let&apos;s Get You Earning Income
        </h2>
        <ol className="onboarding-steps">
          {steps.map((step, i) => (
            <li
              key={i}
              className={clsx({
                'onboarding-current-step': step.progress === profileProgress,
                'next-step': step.progress === profileProgress + 1,
              })}
              onClick={(e) => {
                if (step.progress === profileProgress + 1) {
                  step.action();
                }
              }}
            >
              {
                <button
                  onClick={() => {
                    step.action();
                  }}
                  disabled={step.progress !== profileProgress + 1}
                >
                  <p className="tw-text-base">{step.title}</p>
                </button>
              }
            </li>
          ))}
        </ol>
      </WContainer>
    </>
  );
};

export default OnboardingHeader;

type WaitModalProps = {
  isOpen: boolean;
  onClose: () => void;
  subtitle: string;
  description: string;
};

const WaitModal = ({
  isOpen,
  onClose,
  subtitle,
  description,
}: WaitModalProps) => {
  return (
    <WModal
      title=""
      disableBackdropClick={true}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ErrorBlock title={subtitle} description={description} />
    </WModal>
  );
};

const SubmittedModal = ({ isOpen, onClose, description }: WaitModalProps) => {
  return (
    <WModal
      title=""
      disableBackdropClick={true}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ThankyouBlock title="Thank you" description={description} />
    </WModal>
  );
};
