import { Alert, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useApi } from 'shared_components/context';
import LoginPageLayout from 'shared_components/PageLayout/LoginPageLayout';
import WContainer from 'shared_components/components/WContainer';
import Logo from 'shared_components/public/assets/Logo.png';
import Button from 'shared_components/components/Button';
import eyelid from 'shared_components/public/assets/eyelid.svg';
import shuteyelid from 'shared_components/public/assets/shuteyelid.svg';
import check from 'shared_components/public/assets/check.svg';
import { clientLoginHandler } from 'shared_components/context/auth';
import { useState } from 'react';
import React from 'react';

function SignUpForm() {
  const { clientApi: api } = useApi();
  let history = useNavigate();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [emailValidation, setEmailValidation] = useState('');
  const [cfpassword, setCfpassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [passValidation, setPassValidation] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [formvalidations, setFormvalidations] = useState({
    email: '',
    emailValid: '',
    password: '',
    cfpassword: '',
  });

  const updateCfPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cfPass = e.target.value;
    const cmpPass = password;
    setCfpassword(cfPass);

    if (cfPass !== cmpPass) {
      setPassValidation('The passwords do not match');
    } else {
      setPassValidation('');
    }

    if (cfPass) {
      setFormvalidations({
        ...formvalidations,
        cfpassword: '',
      });
    }
  };

  const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    var sPass = e.target.value;
    if (sPass) {
      setPassword(sPass);
      setFormvalidations({
        ...formvalidations,
        password: '',
      });
    } else {
      setFormvalidations({
        ...formvalidations,
        password: 'This field is required',
      });
    }
  };
  const validateEmail = (e: React.FocusEvent<HTMLInputElement>) => {
    var email = e.target.value;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false) {
      setEmailValidation('Email is not valid');
    } else {
      setFormvalidations({
        ...formvalidations,
        email: '',
      });
    }
  };

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setFormvalidations({
        ...formvalidations,
        [e.target.name]: '',
      });
    }
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      !passValidation &&
      !emailValidation &&
      email &&
      password &&
      cfpassword
    ) {
      var formdataValue = {
        email: email,
        passwordConfirm: cfpassword,
        password: password,
      };
      setSubmitError('');
      api
        .createSignupAuthToken({ signup: formdataValue })
        .then((response) => {
          clientLoginHandler(response);
          history('/dashboard');
        })
        .catch((error) => {
          error.response
            .json()
            .then((response: { detail: string }) => {
              setSubmitError(response.detail);
            })
            .catch(() => {
              setSubmitError('An unknown error occurred');
            });
        });
    }
  };

  const inputClass =
    'tw-h-[48px] tw-bg-surfaceBlack80 tw-p-3 tw-textSeal tw-mt-2 tw-placeholder-textSeal sm:tw-w-50';

  return (
    <LoginPageLayout>
      <div className="tw-flex tw-flex-col tw-items-center">
        <div>
          {submitError ? (
            <Alert
              variant="danger"
              onClose={() => setSubmitError('')}
              dismissible
            >
              <Alert.Heading>
                The email or password entered are incorrect{' '}
              </Alert.Heading>
            </Alert>
          ) : (
            ''
          )}
        </div>
        <WContainer extraClasses="sm:tw-w-50 ">
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
            <img
              src={Logo}
              alt="Westbury Music Consultants"
              className="tw-w-[158px] tw-h-[56px] tw-mt-6"
            ></img>
            <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-6">
              <h3 className="tw-text-xl tw-mt-3">CREATE AN ACCOUNT</h3>
            </div>
            <Form onSubmit={handleSubmit}>
              <div className="tw-w-[536px]">
                <Form.Group>
                  <div className="tw-flex tw-flex-col tw-justify-start tw-mt-6">
                    <label htmlFor="email" className="tw-text-white">
                      Email
                    </label>
                    <input
                      className={`${inputClass} tw-rounded-lg`}
                      placeholder="Enter email"
                      type="email"
                      name="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        inputChange(e as React.ChangeEvent<HTMLInputElement>);
                      }}
                      onBlur={validateEmail}
                    />
                    {emailValidation ? (
                      <div>Please enter a valid email </div>
                    ) : (
                      ''
                    )}
                    {formvalidations.email ? (
                      <div>{formvalidations.email}</div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="tw-flex tw-flex-col tw-justify-start">
                    <label
                      htmlFor="password"
                      className="tw-text-white tw-mt-10"
                    >
                      Password
                    </label>
                    <div className="tw-flex">
                      <input
                        className={`${inputClass} tw-rounded-l-lg tw-w-[500px] tw-block`}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Enter password"
                        name="password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          changePassword(
                            e as React.ChangeEvent<HTMLInputElement>
                          );
                          inputChange(e as React.ChangeEvent<HTMLInputElement>);
                        }}
                      />
                      <div
                        className={`${inputClass} tw-rounded-r-lg tw-flex tw-block`}
                      >
                        <button
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          type="button"
                        >
                          <img
                            src={showPassword ? eyelid : shuteyelid}
                            alt="eye"
                          ></img>
                        </button>
                      </div>
                    </div>
                    <label
                      htmlFor="password"
                      className="tw-text-white tw-mt-10"
                    >
                      Confirm Your Password
                    </label>
                    <div className="tw-flex">
                      <input
                        className={`${inputClass} tw-rounded-l-lg tw-w-[500px] tw-block`}
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={cfpassword}
                        onChange={updateCfPassword}
                        onBlur={updateCfPassword}
                      />

                      <div
                        className={`${inputClass} tw-rounded-r-lg tw-flex tw-block`}
                      >
                        <button
                          onClick={() => {
                            setShowConfirmPassword(!showConfirmPassword);
                          }}
                          type="button"
                        >
                          <img
                            src={showConfirmPassword ? eyelid : shuteyelid}
                            alt="eye"
                          ></img>
                        </button>
                      </div>
                    </div>
                    {passValidation ? <div>Passwords do not match</div> : ''}
                    <div className="tw-flex tw-items-center tw-mt-8">
                      <button
                        onClick={() => setRememberMe(!rememberMe)}
                        className="tw-flex tw-items-center tw-h-[16px] tw-border-[2px] tw-border-white tw-mr-2 tw-justify-center tw-w-[16px]  tw-rounded-sm tw-bg-surfaceBlack80 "
                      >
                        {rememberMe ? (
                          <img src={check} alt="check"></img>
                        ) : null}
                      </button>

                      <p>Remember Me?</p>
                    </div>
                  </div>
                </Form.Group>
              </div>
              <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-8">
                <Button
                  styleType="btn-primary"
                  onClick={() => {
                    handleSubmit;
                  }}
                  width={'200px'}
                >
                  Sign in
                </Button>
                <div className="tw-text-white tw-text-xs tw-w-[250px] tw-text-center tw-mt-4">
                  <p>
                    By expressing my interest with WMC I agree to the terms and
                    conditions and privacy policy
                  </p>
                </div>
              </div>
            </Form>
          </div>
        </WContainer>
      </div>
    </LoginPageLayout>
  );
}

export default SignUpForm;
