import moment from 'moment';
import React, { useState, useEffect } from 'react';
import Nationalities from '../Forms/Nationalities.json';
import Countries from '../Forms/Countries.json';
import { useApi } from 'shared_components/context';
import { useUserId } from 'shared_components/context/user';
import {
  ClientPersonalInformation,
  ClientPersonalInformationTaxIdTypeEnum,
} from 'shared_components/generated/client';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import z, { setErrorMap } from 'zod';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import WContainer from 'shared_components/components/WContainer';
import { useWizardContext } from 'shared_components/components/WForms/Wizard';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { WPassword } from 'shared_components/components/WForms/WPassword/WPassword';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import { WModal } from 'shared_components/components/WModal/WModal';
import FullName from 'shared_components/components/FullName';
import { sendToast } from 'shared_components/utils/Toast';
interface PersonalInfoFormProps {
  editByDefault?: boolean;
}
const PersonalInfoForm: React.FC<PersonalInfoFormProps> = ({
  editByDefault = true,
}) => {
  const { clientApi: api } = useApi();
  const { userId } = useUserId();
  const [isOpenPasswordModal, setIsOpenPasswordModal] = useState(false);
  // error message displayed when submitting password
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [formValue, setFormValue] = useState({
    title: '',
    gender: '',
    city_of_birth: '',
    country_of_birth: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    date_of_birth: '',
    aliases: '',
    nationality: '',
    taxation_country: '',
    vat_number: '',
    tax_id_type: '',
    tax_id_number: '',
    email_address: '',
    phone_number: '',
    street_address: '',
    county: '',
    country: '',
    postcode: '',
    password: '',
  });

  const inputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    fetchData();
  }, [userId]);

  const fetchData = () => {
    if (userId === undefined) {
      return;
    }

    api.retrieveClientPersonalInformation({ userId }).then((personalInfo) => {
      setFormValue({
        ...formValue,
        title: personalInfo.title,
        gender: personalInfo.gender,
        city_of_birth: personalInfo.cityOfBirth,
        country_of_birth: personalInfo.countryOfBirth,
        first_name: personalInfo.firstName,
        middle_name: personalInfo.middleName,
        last_name: personalInfo.lastName,
        date_of_birth: personalInfo.dateOfBirth
          ? moment(personalInfo.dateOfBirth).format('YYYY-MM-DD')
          : '',
        aliases: (personalInfo.aliases ?? []).join(','),
        nationality: personalInfo.nationality,
        taxation_country: personalInfo.taxationCountry,
        vat_number: personalInfo.vatNumber,
        tax_id_type: personalInfo.taxIdType,
        tax_id_number: personalInfo.taxIdNumber,
        email_address: personalInfo.emailAddress,
        phone_number: personalInfo.phoneNumber,
        street_address: personalInfo.streetAddress,
        county: personalInfo.county,
        country: personalInfo.country,
        postcode: personalInfo.postcode,
      });
    });
  };

  const handleSubmit = () => {
    // Edit By Default is only during onboarding
    return editByDefault ? createDetails() : updateDetails();
  };

  const createDetails = async () => {
    if (userId === undefined) {
      return;
    }
    var formdataValue: ClientPersonalInformation = {
      title: formValue.title ? formValue.title : '',
      gender: formValue.gender ? formValue.gender : '',
      cityOfBirth: formValue.city_of_birth ? formValue.city_of_birth : '',
      countryOfBirth: formValue.country_of_birth
        ? formValue.country_of_birth
        : '',
      firstName: formValue.first_name ? formValue.first_name : '',
      middleName: formValue.middle_name ? formValue.middle_name : '',
      lastName: formValue.last_name ? formValue.last_name : '',

      ...(formValue.date_of_birth && {
        dateOfBirth: new Date(formValue.date_of_birth),
      }),
      aliases: formValue.aliases
        ? formValue.aliases.split(',').filter(Boolean)
        : [],
      nationality: formValue.nationality ? formValue.nationality : '',
      taxationCountry: formValue.taxation_country
        ? formValue.taxation_country
        : '',
      vatNumber: formValue.vat_number ? formValue.vat_number : '',
      taxIdType: formValue.tax_id_type ? formValue.tax_id_type : '',
      taxIdNumber: formValue.tax_id_number ? formValue.tax_id_number : '',
      emailAddress: formValue.email_address ? formValue.email_address : '',
      phoneNumber: formValue.phone_number ? formValue.phone_number : '',
      streetAddress: formValue.street_address ? formValue.street_address : '',
      county: formValue.county ? formValue.county : '',
      country: formValue.country ? formValue.country : '',
      postcode: formValue.postcode ? formValue.postcode : '',
    };
    const dataObject = new Map();

    Object.entries(formdataValue).map(([keyName, value]) => {
      if (value) {
        dataObject.set(keyName, value);
      }
    });

    try {
      await api.createClientPersonalInformation({
        userId,
        clientPersonalInformation: Object.fromEntries(dataObject),
      });
      return Promise.resolve();
    } catch (e) {
      const data = await e.response.json();
      alert('There was an error saving your personal information');
      return Promise.reject(data.old_password);
    }
  };
  const updateDetails = async () => {
    if (userId === undefined) {
      return;
    }
    var formdataValue: ClientPersonalInformation = {
      title: formValue.title ? formValue.title : '',
      gender: formValue.gender ? formValue.gender : '',
      cityOfBirth: formValue.city_of_birth ? formValue.city_of_birth : '',
      countryOfBirth: formValue.country_of_birth
        ? formValue.country_of_birth
        : '',
      firstName: formValue.first_name ? formValue.first_name : '',
      middleName: formValue.middle_name ? formValue.middle_name : '',
      lastName: formValue.last_name ? formValue.last_name : '',

      ...(formValue.date_of_birth && {
        dateOfBirth: new Date(formValue.date_of_birth),
      }),
      aliases: formValue.aliases
        ? formValue.aliases.split(',').filter(Boolean)
        : [],
      nationality: formValue.nationality ? formValue.nationality : '',
      taxationCountry: formValue.taxation_country
        ? formValue.taxation_country
        : '',
      vatNumber: formValue.vat_number ? formValue.vat_number : '',
      taxIdType: formValue.tax_id_type ? formValue.tax_id_type : undefined,
      taxIdNumber: formValue.tax_id_number ? formValue.tax_id_number : '',
      emailAddress: formValue.email_address ? formValue.email_address : '',
      phoneNumber: formValue.phone_number ? formValue.phone_number : '',
      streetAddress: formValue.street_address ? formValue.street_address : '',
      county: formValue.county ? formValue.county : '',
      country: formValue.country ? formValue.country : '',
      postcode: formValue.postcode ? formValue.postcode : '',
    };
    const dataObject = new Map();

    Object.entries(formdataValue).map(([keyName, value]) => {
      if (value) {
        dataObject.set(keyName, value);
      }
    });

    dataObject.set('oldPassword', formValue.password);

    setErrorMessage(null);
    try {
      await api.updateClientPersonalInformation({
        userId,
        clientPersonalInformation: Object.fromEntries(dataObject),
      });
      setIsOpenPasswordModal(false);
      sendToast({
        text: 'Personal information updated',
        variant: 'success',
        duration: 10000,
      });

      setFormValue({
        ...formValue,
        password: '',
      });
      return Promise.resolve();
    } catch (e) {
      const data = await e.response.json();
      if (data.hasOwnProperty('old_password')) {
        setErrorMessage(data.old_password);
        return Promise.reject(data.old_password);
      }
    }
  };
  const taxIdTypeOptions = Object.values(
    ClientPersonalInformationTaxIdTypeEnum
  ).map((taxIdType) => ({
    label: taxIdType,
    value: taxIdType,
  }));

  return (
    <>
      <WFormProvider
        editByDefault={editByDefault}
        schema={undefined}
        onSuccessMessage={null}
        submitOnEnter={false} // we don't want to submit the form on enter because we want to show the password modal
        onSuccess={() => {}}
        formData={formValue}
        handleSubmit={handleSubmit}
      >
        <WModal
          title="Password required"
          isOpen={isOpenPasswordModal}
          onClose={() => {
            setIsOpenPasswordModal(false);
          }}
        >
          <p className="tw-text-xl tw-mb-4 tw-text-center">
            Please enter your password to allow this
          </p>
          {/* Show error message in case the server returns one */}

          <WPassword
            name="password"
            label="Password"
            value={formValue.password}
            onChange={(e) => {
              setFormValue({
                ...formValue,
                password: e.target.value,
              });
            }}
          />
          {errorMessage && (
            <p className="tw-text-red-500 tw-mb-4 tw-text-sm">{errorMessage}</p>
          )}
          <ModalFooterSubmit />
        </WModal>
        <WContainer extraClasses="tw-w-[500px]">
          <p className="tw-text-2xl tw-mb-6">Personal Information</p>
          <WSelect
            label="Title"
            name="title"
            options={[
              { label: 'Mrs', value: 'Mrs' },
              { label: 'Mr', value: 'Mr' },
              { label: 'Miss', value: 'Miss' },
              { label: 'Ms', value: 'Ms' },
            ]}
            onChange={inputChange}
            defaultValue={formValue.title}
          />
          <FullName
            editedSettings={{
              firstName: formValue.first_name,
              lastName: formValue.last_name,
              middleName: formValue.middle_name,
            }}
            disabled={true} // we don't want to allow editing of the name because we would need to regenerate WMC forms with the new name
            handleInputChange={inputChange}
          />

          <WInput
            type="text"
            label="Aliases"
            name="aliases"
            onChange={inputChange}
            value={formValue.aliases ? formValue.aliases : ''}
          />

          <WInput
            type="text"
            label="Date of birth"
            name="date_of_birth"
            onChange={inputChange}
            value={formValue.date_of_birth ? formValue.date_of_birth : ''}
          />

          <WSelect
            label="Gender"
            name="gender"
            onChange={inputChange}
            defaultValue={formValue.gender}
            options={[
              { label: 'Female', value: 'Female' },
              { label: 'Male', value: 'Male' },
              { label: 'Other', value: 'Other' },
              { label: 'Prefer not to say', value: 'Prefer not to say' },
            ]}
          />

          <WSelect
            label="Nationality"
            name="nationality"
            onChange={inputChange}
            defaultValue={formValue.nationality}
            options={Nationalities.map((nationality) => ({
              label: nationality,
              value: nationality,
            }))}
          />
          <WInput
            type="text"
            label="City of birth"
            name="city_of_birth"
            onChange={inputChange}
            value={formValue.city_of_birth ? formValue.city_of_birth : ''}
          />
          <WSelect
            label="Country of birth"
            name="country_of_birth"
            onChange={inputChange}
            defaultValue={formValue.country_of_birth}
            options={Countries.map((country) => ({
              label: country,
              value: country,
            }))}
          />
        </WContainer>
        <WContainer extraClasses="tw-w-[500px]">
          <p className="tw-text-2xl tw-mb-6">Tax Information</p>
          <WSelect
            label="Country of taxation"
            name="taxation_country"
            onChange={inputChange}
            defaultValue={formValue.taxation_country}
            options={Countries.map((country) => ({
              label: country,
              value: country,
            }))}
          />
          <WInput
            type="text"
            label="VAT number"
            name="vat_number"
            labelSecondary="(If Applicable)"
            onChange={inputChange}
            value={formValue.vat_number ? formValue.vat_number : ''}
          />
          <WSelect
            label="Tax ID type"
            name="tax_id_type"
            onChange={inputChange}
            defaultValue={formValue.tax_id_type}
            options={taxIdTypeOptions}
          />
          <WInput
            type="text"
            label="Tax ID number"
            name="tax_id_number"
            onChange={inputChange}
            value={formValue.tax_id_number ? formValue.tax_id_number : ''}
          />
        </WContainer>

        <WContainer extraClasses="tw-w-[500px]">
          <p className="tw-text-2xl tw-mb-6">Contact Details</p>
          <WInput
            type="text"
            label="Email address"
            name="email_address"
            onChange={inputChange}
            value={formValue.email_address ? formValue.email_address : ''}
          />
          <WInput
            type="text"
            label="Phone number"
            name="phone_number"
            onChange={inputChange}
            value={formValue.phone_number ? formValue.phone_number : ''}
          />
          <WInput
            type="text"
            label="Street Address"
            labelSecondary="(Enter the address that you reside)"
            name="street_address"
            onChange={inputChange}
            value={formValue.street_address ? formValue.street_address : ''}
          />
          <WInput
            type="text"
            label="County/State"
            name="county"
            onChange={inputChange}
            value={formValue.county ? formValue.county : ''}
          />
          <WInput
            type="text"
            label="Zip/Postcode"
            name="postcode"
            onChange={inputChange}
            value={formValue.postcode ? formValue.postcode : ''}
          />
          <WSelect
            label="Country"
            name="taxation_country"
            onChange={inputChange}
            defaultValue={formValue.country}
            options={Countries.map((country) => ({
              label: country,
              value: country,
            }))}
          />
        </WContainer>
        {/*
          The footer is different depending on whether we are editing by default or not
          So if we are in onboarding mode (edit by default) or if we editing the info in the Profile tab
         */}
        {editByDefault && <PersonalInfoFormFooter />}
        {!editByDefault && (
          <OnboardedFormFooter
            setIsOpenPasswordModal={setIsOpenPasswordModal}
          />
        )}
      </WFormProvider>
    </>
  );
};

export default PersonalInfoForm;

function PersonalInfoFormFooter() {
  const { handleFormSubmit, formData } = useFormContext();
  // try to see if you're in a wizard context, if so, trigger the next step, otherwise just submit the form
  const { nextStep } = useWizardContext();
  if (nextStep) {
    return (
      <div className="tw-flex tw-justify-end tw-mt-4">
        <WButton
          onClick={() => {
            handleFormSubmit(formData);
            nextStep();
          }}
          variant="primary"
          label="Next"
        />
      </div>
    );
  } else {
    <div className="tw-flex tw-justify-end tw-mt-4">
      <WButton
        onClick={() => {
          handleFormSubmit(formData);
        }}
        variant="primary"
        label="Next"
      />
      ;
    </div>;
  }
}

function OnboardedFormFooter({ setIsOpenPasswordModal }) {
  const { handleFormSubmit, formData, isEditing, setIsEditing } =
    useFormContext();
  return (
    // show primary button with edit icon when not editing, show Save button when editing
    <div className="tw-flex tw-justify-start tw-items-center tw-space-x-2 tw-mt-8">
      {!isEditing && (
        <WButton
          onClick={() => {
            setIsEditing(true);
          }}
          variant="primary-lg"
          label="Edit"
          icon="edit"
        />
      )}
      {isEditing && (
        <>
          <WButton
            onClick={() => {
              setIsOpenPasswordModal(true);
            }}
            variant="primary-lg"
            label="Save"
          />
          <WButton
            onClick={() => {
              setIsEditing(false);
            }}
            variant="link-secondary"
            label="Cancel"
          />
        </>
      )}
    </div>
  );
}

type FormFooterProps = {
  setIsOpenPasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
};

function FormFooter({ setIsOpenPasswordModal }: FormFooterProps) {
  const { handleFormSubmit, formData, isEditing } = useFormContext();
  return (
    <>
      {isEditing && (
        <div className="tw-flex tw-justify-start">
          <WButton
            onClick={() => {
              setIsOpenPasswordModal(true);
            }}
            variant="primary"
            label="Save"
          />
        </div>
      )}
    </>
  );
}

function ModalFooterSubmit() {
  const { handleFormSubmit, formData, isEditing } = useFormContext();
  return (
    <>
      <div className="tw-flex tw-justify-end">
        <WButton
          onClick={() => {
            handleFormSubmit(formData);
          }}
          variant="primary"
          label="Submit"
        />
      </div>
    </>
  );
}
