/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdditionalContactDetails,
  ArtistDetails,
  ClientBankDetails,
  ClientIdentityDocumentSubmission,
  ClientInterestRegistration,
  ClientPersonalInformation,
  ClientSocietyFormTask,
  ClientUser,
  CreatePandadocsSessionId,
  DailyPayment,
  FinancialYears,
  IdentityDocumentTypes,
  IdentityVerficationSubmission,
  LoginWithEmailorUsernameAuthToken,
  MembershipForms,
  MembershipStatus,
  OnboardingProgress,
  OnboardingSteps,
  PandadocDocumentIds,
  PandadocEgress,
  Payment,
  PreregisteredSocietyPost,
  PreregisteredSocietyPostId,
  PrimaryDiscography,
  PrimaryDiscographyExport,
  Representative,
  SigninAuthToken,
  Signup,
  SignupAuthToken,
  Society,
  SocietyForm,
  StatementsJson,
  TokenBlacklist,
  TokenObtainPair,
  TokenRefresh,
  TokenVerify,
  UserSettings,
} from '../models';
import {
    AdditionalContactDetailsFromJSON,
    AdditionalContactDetailsToJSON,
    ArtistDetailsFromJSON,
    ArtistDetailsToJSON,
    ClientBankDetailsFromJSON,
    ClientBankDetailsToJSON,
    ClientIdentityDocumentSubmissionFromJSON,
    ClientIdentityDocumentSubmissionToJSON,
    ClientInterestRegistrationFromJSON,
    ClientInterestRegistrationToJSON,
    ClientPersonalInformationFromJSON,
    ClientPersonalInformationToJSON,
    ClientSocietyFormTaskFromJSON,
    ClientSocietyFormTaskToJSON,
    ClientUserFromJSON,
    ClientUserToJSON,
    CreatePandadocsSessionIdFromJSON,
    CreatePandadocsSessionIdToJSON,
    DailyPaymentFromJSON,
    DailyPaymentToJSON,
    FinancialYearsFromJSON,
    FinancialYearsToJSON,
    IdentityDocumentTypesFromJSON,
    IdentityDocumentTypesToJSON,
    IdentityVerficationSubmissionFromJSON,
    IdentityVerficationSubmissionToJSON,
    LoginWithEmailorUsernameAuthTokenFromJSON,
    LoginWithEmailorUsernameAuthTokenToJSON,
    MembershipFormsFromJSON,
    MembershipFormsToJSON,
    MembershipStatusFromJSON,
    MembershipStatusToJSON,
    OnboardingProgressFromJSON,
    OnboardingProgressToJSON,
    OnboardingStepsFromJSON,
    OnboardingStepsToJSON,
    PandadocDocumentIdsFromJSON,
    PandadocDocumentIdsToJSON,
    PandadocEgressFromJSON,
    PandadocEgressToJSON,
    PaymentFromJSON,
    PaymentToJSON,
    PreregisteredSocietyPostFromJSON,
    PreregisteredSocietyPostToJSON,
    PreregisteredSocietyPostIdFromJSON,
    PreregisteredSocietyPostIdToJSON,
    PrimaryDiscographyFromJSON,
    PrimaryDiscographyToJSON,
    PrimaryDiscographyExportFromJSON,
    PrimaryDiscographyExportToJSON,
    RepresentativeFromJSON,
    RepresentativeToJSON,
    SigninAuthTokenFromJSON,
    SigninAuthTokenToJSON,
    SignupFromJSON,
    SignupToJSON,
    SignupAuthTokenFromJSON,
    SignupAuthTokenToJSON,
    SocietyFromJSON,
    SocietyToJSON,
    SocietyFormFromJSON,
    SocietyFormToJSON,
    StatementsJsonFromJSON,
    StatementsJsonToJSON,
    TokenBlacklistFromJSON,
    TokenBlacklistToJSON,
    TokenObtainPairFromJSON,
    TokenObtainPairToJSON,
    TokenRefreshFromJSON,
    TokenRefreshToJSON,
    TokenVerifyFromJSON,
    TokenVerifyToJSON,
    UserSettingsFromJSON,
    UserSettingsToJSON,
} from '../models';

export interface AdminApproveMembershipFormsRequest {
    formId: string;
    membershipForms?: MembershipForms;
}

export interface ClientSignClientSocietyFormTaskRequest {
    userId: string;
    taskId: string;
    clientSocietyFormTask?: ClientSocietyFormTask;
}

export interface CreateAdditionalContactDetailsRequest {
    userId: string;
    additionalContactDetails?: AdditionalContactDetails;
}

export interface CreateClientBankDetailsRequest {
    userId: string;
    clientBankDetails?: ClientBankDetails;
}

export interface CreateClientIdentityDocumentSubmissionRequest {
    userId: string;
    id?: string;
    dateCreated?: Date;
    file?: Blob;
    filename?: string | null;
    status?: CreateClientIdentityDocumentSubmissionStatusEnum;
    documentExpiryDate?: string | null;
    documentType?: CreateClientIdentityDocumentSubmissionDocumentTypeEnum;
    fileUrl?: string;
}

export interface CreateClientInterestRegistrationRequest {
    clientInterestRegistration?: ClientInterestRegistration;
}

export interface CreateClientPersonalInformationRequest {
    userId: string;
    clientPersonalInformation?: ClientPersonalInformation;
}

export interface CreateClientSessionCreatePandadocsSessionIdRequest {
    taskId: string;
    createPandadocsSessionId?: CreatePandadocsSessionId;
}

export interface CreateGeneratePrimaryDiscographyExportRequest {
    userId: string;
    body?: object;
}

export interface CreateIdentityVerficationSubmissionRequest {
    userId: string;
    passportFile: Blob;
    registeredWithPpl: boolean;
    streetAddress: string;
    postcode: string;
    county: string;
    country: string;
    witnessFirstName: string;
    witnessLastName: string;
    witnessEmail: string;
    proofOfAddressFile?: Blob;
    pplPerformerName?: string;
    pplPerformerId?: string;
}

export interface CreatePandadocDocumentIdsRequest {
    pandadocDocumentIds?: PandadocDocumentIds;
}

export interface CreatePreregisteredSocietyPostIdRequest {
    userId: string;
    preregisteredSocietyPost?: PreregisteredSocietyPost;
}

export interface CreateRepresentativeRequest {
    userId: string;
    representative?: Representative;
}

export interface CreateSigninAuthTokenRequest {
    loginWithEmailorUsernameAuthToken?: LoginWithEmailorUsernameAuthToken;
}

export interface CreateSignupAuthTokenRequest {
    signup?: Signup;
}

export interface CreateTokenBlacklistRequest {
    tokenBlacklist?: TokenBlacklist;
}

export interface CreateTokenObtainPairRequest {
    tokenObtainPair?: TokenObtainPair;
}

export interface CreateTokenRefreshRequest {
    tokenRefresh?: TokenRefresh;
}

export interface CreateTokenVerifyRequest {
    tokenVerify?: TokenVerify;
}

export interface GetAnnotatedEvidenceSignedUrlsPrimaryDiscographyRequest {
    userId: string;
}

export interface ListClientSocietyFormTasksRequest {
    userId: string;
}

export interface ListDailyPaymentsRequest {
    userId: string;
    financialYear?: string;
}

export interface ListMembershipFormsRequest {
    userId: string;
}

export interface ListMembershipStatusesRequest {
    userId: string;
}

export interface ListPaymentsRequest {
    userId: string;
    financialYear?: string;
    format?: string;
}

export interface ListSocietiesRequest {
    userId: string;
}

export interface ListSocietyFormsRequest {
    userId: string;
    societyId: string;
}

export interface PartialUpdateUserSettingsRequest {
    userId: string;
    userSettings?: UserSettings;
}

export interface RetrieveAdditionalContactDetailsRequest {
    userId: string;
}

export interface RetrieveArtistDetailsRequest {
    userId: string;
}

export interface RetrieveByEmailHashClientInterestRegistrationRequest {
    emailHash: string;
}

export interface RetrieveByUsernameUserRequest {
    username: string;
}

export interface RetrieveClientBankDetailsRequest {
    userId: string;
}

export interface RetrieveClientPersonalInformationRequest {
    userId: string;
}

export interface RetrieveFinancialYearsRequest {
    userId: string;
}

export interface RetrieveLatestClientIdentityDocumentSubmissionRequest {
    userId: string;
    documentType: string;
}

export interface RetrieveLatestPrimaryDiscographyExportRequest {
    userId: string;
}

export interface RetrieveOnboardingProgressRequest {
    userId: string;
}

export interface RetrieveOnboardingStepsRequest {
    userId: string;
}

export interface RetrievePandadocEgressRequest {
    userId: string;
    societyFormId: string;
}

export interface RetrievePrimaryDiscographyRequest {
    userId: string;
}

export interface RetrievePrimaryDiscographyExportRequest {
    userId: string;
    exportId: string;
}

export interface RetrieveRepresentativeRequest {
    userId: string;
}

export interface RetrieveStatementsJsonRequest {
    userId: string;
    paymentDay?: string;
    paymentDays?: Array<string>;
    format?: string;
}

export interface RetrieveUserRequest {
    userId: string;
}

export interface RetrieveUserSettingsRequest {
    userId: string;
}

export interface UpdateAdditionalContactDetailsRequest {
    userId: string;
    additionalContactDetails?: AdditionalContactDetails;
}

export interface UpdateClientBankDetailsRequest {
    userId: string;
    clientBankDetails?: ClientBankDetails;
}

export interface UpdateClientIdentityDocumentSubmissionRequest {
    userId: string;
    documentId: string;
    id?: string;
    dateCreated?: Date;
    file?: Blob;
    filename?: string | null;
    status?: UpdateClientIdentityDocumentSubmissionStatusEnum;
    documentExpiryDate?: string | null;
    documentType?: UpdateClientIdentityDocumentSubmissionDocumentTypeEnum;
    fileUrl?: string;
}

export interface UpdateClientPersonalInformationRequest {
    userId: string;
    clientPersonalInformation?: ClientPersonalInformation;
}

export interface UpdateClientSocietyFormTaskRequest {
    userId: string;
    clientSocietyFormTask?: ClientSocietyFormTask;
}

export interface UpdatePandadocEgressRequest {
    societyFormId: string;
    body?: any | null;
}

export interface UpdatePhotoClientPersonalInformationRequest {
    userId: string;
    user?: string;
    url?: string;
    oldPassword?: string;
    password?: string;
    title?: string | null;
    gender?: string | null;
    cityOfBirth?: string | null;
    countryOfBirth?: string | null;
    fullName?: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    department?: UpdatePhotoClientPersonalInformationDepartmentEnum;
    departmentChoices?: Array<string>;
    userPhoto?: Blob;
    userPhotoUrl?: string;
    dateOfBirth?: Date | null;
    aliases?: Array<string>;
    nationality?: string | null;
    taxationCountry?: string | null;
    vatNumber?: string | null;
    taxIdType?: UpdatePhotoClientPersonalInformationTaxIdTypeEnum;
    taxIdNumber?: string | null;
    emailAddress?: string | null;
    streetAddress?: string | null;
    country?: string | null;
    phoneNumber?: string | null;
    county?: string | null;
    postcode?: string | null;
    westburyId?: string;
}

export interface UpdateRepresentativeRequest {
    userId: string;
    representative?: Representative;
}

export interface UpdateSignupAuthTokenRequest {
    signup?: Signup;
}

export interface UpdateUserSettingsRequest {
    userId: string;
    userSettings?: UserSettings;
}

/**
 * 
 */
export class PortalApi extends runtime.BaseAPI {

    /**
     * 
     */
    async adminApproveMembershipFormsRaw(requestParameters: AdminApproveMembershipFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MembershipForms>> {
        if (requestParameters.formId === null || requestParameters.formId === undefined) {
            throw new runtime.RequiredError('formId','Required parameter requestParameters.formId was null or undefined when calling adminApproveMembershipForms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/tasks/forms/{form_id}/actions/admin-approve/`.replace(`{${"form_id"}}`, encodeURIComponent(String(requestParameters.formId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MembershipFormsToJSON(requestParameters.membershipForms),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MembershipFormsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async adminApproveMembershipForms(requestParameters: AdminApproveMembershipFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MembershipForms> {
        const response = await this.adminApproveMembershipFormsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async clientSignClientSocietyFormTaskRaw(requestParameters: ClientSignClientSocietyFormTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientSocietyFormTask>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling clientSignClientSocietyFormTask.');
        }

        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling clientSignClientSocietyFormTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/client_society_form_tasks/{task_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"task_id"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientSocietyFormTaskToJSON(requestParameters.clientSocietyFormTask),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientSocietyFormTaskFromJSON(jsonValue));
    }

    /**
     * 
     */
    async clientSignClientSocietyFormTask(requestParameters: ClientSignClientSocietyFormTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientSocietyFormTask> {
        const response = await this.clientSignClientSocietyFormTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createAdditionalContactDetailsRaw(requestParameters: CreateAdditionalContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdditionalContactDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createAdditionalContactDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/additional-contact-details/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdditionalContactDetailsToJSON(requestParameters.additionalContactDetails),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdditionalContactDetailsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createAdditionalContactDetails(requestParameters: CreateAdditionalContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdditionalContactDetails> {
        const response = await this.createAdditionalContactDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createClientBankDetailsRaw(requestParameters: CreateClientBankDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientBankDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createClientBankDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/bank-details/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientBankDetailsToJSON(requestParameters.clientBankDetails),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientBankDetailsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createClientBankDetails(requestParameters: CreateClientBankDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientBankDetails> {
        const response = await this.createClientBankDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createClientIdentityDocumentSubmissionRaw(requestParameters: CreateClientIdentityDocumentSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientIdentityDocumentSubmission>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createClientIdentityDocumentSubmission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('id', requestParameters.id as any);
        }

        if (requestParameters.dateCreated !== undefined) {
            formParams.append('date_created', requestParameters.dateCreated as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.filename !== undefined) {
            formParams.append('filename', requestParameters.filename as any);
        }

        if (requestParameters.status !== undefined) {
            formParams.append('status', requestParameters.status as any);
        }

        if (requestParameters.documentExpiryDate !== undefined) {
            formParams.append('document_expiry_date', requestParameters.documentExpiryDate as any);
        }

        if (requestParameters.documentType !== undefined) {
            formParams.append('document_type', requestParameters.documentType as any);
        }

        if (requestParameters.fileUrl !== undefined) {
            formParams.append('file_url', requestParameters.fileUrl as any);
        }

        const response = await this.request({
            path: `/portal/users/{user_id}/identity-document-submissions/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientIdentityDocumentSubmissionFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createClientIdentityDocumentSubmission(requestParameters: CreateClientIdentityDocumentSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientIdentityDocumentSubmission> {
        const response = await this.createClientIdentityDocumentSubmissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create user and user settings based on expression of interest information. Create EOI review and discography ID submission admin tasks.
     */
    async createClientInterestRegistrationRaw(requestParameters: CreateClientInterestRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientInterestRegistration>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/interest-registrations/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientInterestRegistrationToJSON(requestParameters.clientInterestRegistration),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientInterestRegistrationFromJSON(jsonValue));
    }

    /**
     * Create user and user settings based on expression of interest information. Create EOI review and discography ID submission admin tasks.
     */
    async createClientInterestRegistration(requestParameters: CreateClientInterestRegistrationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientInterestRegistration> {
        const response = await this.createClientInterestRegistrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createClientPersonalInformationRaw(requestParameters: CreateClientPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientPersonalInformation>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createClientPersonalInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/personal-information/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientPersonalInformationToJSON(requestParameters.clientPersonalInformation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientPersonalInformationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createClientPersonalInformation(requestParameters: CreateClientPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientPersonalInformation> {
        const response = await this.createClientPersonalInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createClientSessionCreatePandadocsSessionIdRaw(requestParameters: CreateClientSessionCreatePandadocsSessionIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatePandadocsSessionId>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling createClientSessionCreatePandadocsSessionId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/pandadocs/{task_id}/session_id/`.replace(`{${"task_id"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePandadocsSessionIdToJSON(requestParameters.createPandadocsSessionId),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePandadocsSessionIdFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createClientSessionCreatePandadocsSessionId(requestParameters: CreateClientSessionCreatePandadocsSessionIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatePandadocsSessionId> {
        const response = await this.createClientSessionCreatePandadocsSessionIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createGeneratePrimaryDiscographyExportRaw(requestParameters: CreateGeneratePrimaryDiscographyExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createGeneratePrimaryDiscographyExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/primary-discography/exports/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 
     */
    async createGeneratePrimaryDiscographyExport(requestParameters: CreateGeneratePrimaryDiscographyExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.createGeneratePrimaryDiscographyExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createIdentityVerficationSubmissionRaw(requestParameters: CreateIdentityVerficationSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdentityVerficationSubmission>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.passportFile === null || requestParameters.passportFile === undefined) {
            throw new runtime.RequiredError('passportFile','Required parameter requestParameters.passportFile was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.registeredWithPpl === null || requestParameters.registeredWithPpl === undefined) {
            throw new runtime.RequiredError('registeredWithPpl','Required parameter requestParameters.registeredWithPpl was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.streetAddress === null || requestParameters.streetAddress === undefined) {
            throw new runtime.RequiredError('streetAddress','Required parameter requestParameters.streetAddress was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.postcode === null || requestParameters.postcode === undefined) {
            throw new runtime.RequiredError('postcode','Required parameter requestParameters.postcode was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.county === null || requestParameters.county === undefined) {
            throw new runtime.RequiredError('county','Required parameter requestParameters.county was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.country === null || requestParameters.country === undefined) {
            throw new runtime.RequiredError('country','Required parameter requestParameters.country was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.witnessFirstName === null || requestParameters.witnessFirstName === undefined) {
            throw new runtime.RequiredError('witnessFirstName','Required parameter requestParameters.witnessFirstName was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.witnessLastName === null || requestParameters.witnessLastName === undefined) {
            throw new runtime.RequiredError('witnessLastName','Required parameter requestParameters.witnessLastName was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.witnessEmail === null || requestParameters.witnessEmail === undefined) {
            throw new runtime.RequiredError('witnessEmail','Required parameter requestParameters.witnessEmail was null or undefined when calling createIdentityVerficationSubmission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.passportFile !== undefined) {
            formParams.append('passport_file', requestParameters.passportFile as any);
        }

        if (requestParameters.proofOfAddressFile !== undefined) {
            formParams.append('proof_of_address_file', requestParameters.proofOfAddressFile as any);
        }

        if (requestParameters.registeredWithPpl !== undefined) {
            formParams.append('registered_with_ppl', requestParameters.registeredWithPpl as any);
        }

        if (requestParameters.pplPerformerName !== undefined) {
            formParams.append('ppl_performer_name', requestParameters.pplPerformerName as any);
        }

        if (requestParameters.pplPerformerId !== undefined) {
            formParams.append('ppl_performer_id', requestParameters.pplPerformerId as any);
        }

        if (requestParameters.streetAddress !== undefined) {
            formParams.append('street_address', requestParameters.streetAddress as any);
        }

        if (requestParameters.postcode !== undefined) {
            formParams.append('postcode', requestParameters.postcode as any);
        }

        if (requestParameters.county !== undefined) {
            formParams.append('county', requestParameters.county as any);
        }

        if (requestParameters.country !== undefined) {
            formParams.append('country', requestParameters.country as any);
        }

        if (requestParameters.witnessFirstName !== undefined) {
            formParams.append('witness_first_name', requestParameters.witnessFirstName as any);
        }

        if (requestParameters.witnessLastName !== undefined) {
            formParams.append('witness_last_name', requestParameters.witnessLastName as any);
        }

        if (requestParameters.witnessEmail !== undefined) {
            formParams.append('witness_email', requestParameters.witnessEmail as any);
        }

        const response = await this.request({
            path: `/portal/users/{user_id}/identity-verification-submissions/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityVerficationSubmissionFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createIdentityVerficationSubmission(requestParameters: CreateIdentityVerficationSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdentityVerficationSubmission> {
        const response = await this.createIdentityVerficationSubmissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createPandadocDocumentIdsRaw(requestParameters: CreatePandadocDocumentIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PandadocDocumentIds>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/pandadocs/document_id/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PandadocDocumentIdsToJSON(requestParameters.pandadocDocumentIds),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PandadocDocumentIdsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createPandadocDocumentIds(requestParameters: CreatePandadocDocumentIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PandadocDocumentIds> {
        const response = await this.createPandadocDocumentIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createPreregisteredSocietyPostIdRaw(requestParameters: CreatePreregisteredSocietyPostIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreregisteredSocietyPostId>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createPreregisteredSocietyPostId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/preregistered-societies/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PreregisteredSocietyPostToJSON(requestParameters.preregisteredSocietyPost),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreregisteredSocietyPostIdFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createPreregisteredSocietyPostId(requestParameters: CreatePreregisteredSocietyPostIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreregisteredSocietyPostId> {
        const response = await this.createPreregisteredSocietyPostIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createRepresentativeRaw(requestParameters: CreateRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Representative>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createRepresentative.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/representative/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RepresentativeToJSON(requestParameters.representative),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepresentativeFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createRepresentative(requestParameters: CreateRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Representative> {
        const response = await this.createRepresentativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createSigninAuthTokenRaw(requestParameters: CreateSigninAuthTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SigninAuthToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/signin/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginWithEmailorUsernameAuthTokenToJSON(requestParameters.loginWithEmailorUsernameAuthToken),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SigninAuthTokenFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createSigninAuthToken(requestParameters: CreateSigninAuthTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SigninAuthToken> {
        const response = await this.createSigninAuthTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createSignupAuthTokenRaw(requestParameters: CreateSignupAuthTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignupAuthToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/signup/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignupToJSON(requestParameters.signup),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignupAuthTokenFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createSignupAuthToken(requestParameters: CreateSignupAuthTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignupAuthToken> {
        const response = await this.createSignupAuthTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a token and blacklists it. Must be used with the `rest_framework_simplejwt.token_blacklist` app installed.
     */
    async createTokenBlacklistRaw(requestParameters: CreateTokenBlacklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenBlacklist>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/api/token/blacklist/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenBlacklistToJSON(requestParameters.tokenBlacklist),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenBlacklistFromJSON(jsonValue));
    }

    /**
     * Takes a token and blacklists it. Must be used with the `rest_framework_simplejwt.token_blacklist` app installed.
     */
    async createTokenBlacklist(requestParameters: CreateTokenBlacklistRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenBlacklist> {
        const response = await this.createTokenBlacklistRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     */
    async createTokenObtainPairRaw(requestParameters: CreateTokenObtainPairRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenObtainPair>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/api/token/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenObtainPairToJSON(requestParameters.tokenObtainPair),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenObtainPairFromJSON(jsonValue));
    }

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     */
    async createTokenObtainPair(requestParameters: CreateTokenObtainPairRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenObtainPair> {
        const response = await this.createTokenObtainPairRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     */
    async createTokenRefreshRaw(requestParameters: CreateTokenRefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenRefresh>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/api/token/refresh/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenRefreshToJSON(requestParameters.tokenRefresh),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenRefreshFromJSON(jsonValue));
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     */
    async createTokenRefresh(requestParameters: CreateTokenRefreshRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenRefresh> {
        const response = await this.createTokenRefreshRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     */
    async createTokenVerifyRaw(requestParameters: CreateTokenVerifyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenVerify>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/api/token/verify/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenVerifyToJSON(requestParameters.tokenVerify),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenVerifyFromJSON(jsonValue));
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     */
    async createTokenVerify(requestParameters: CreateTokenVerifyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenVerify> {
        const response = await this.createTokenVerifyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getAnnotatedEvidenceSignedUrlsPrimaryDiscographyRaw(requestParameters: GetAnnotatedEvidenceSignedUrlsPrimaryDiscographyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrimaryDiscography>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getAnnotatedEvidenceSignedUrlsPrimaryDiscography.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/primary-discography/annotated-evidence-signed-urls/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrimaryDiscographyFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getAnnotatedEvidenceSignedUrlsPrimaryDiscography(requestParameters: GetAnnotatedEvidenceSignedUrlsPrimaryDiscographyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrimaryDiscography> {
        const response = await this.getAnnotatedEvidenceSignedUrlsPrimaryDiscographyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listClientSocietyFormTasksRaw(requestParameters: ListClientSocietyFormTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientSocietyFormTask>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listClientSocietyFormTasks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/client_society_form_tasks`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientSocietyFormTaskFromJSON));
    }

    /**
     * 
     */
    async listClientSocietyFormTasks(requestParameters: ListClientSocietyFormTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientSocietyFormTask>> {
        const response = await this.listClientSocietyFormTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listDailyPaymentsRaw(requestParameters: ListDailyPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DailyPayment>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listDailyPayments.');
        }

        const queryParameters: any = {};

        if (requestParameters.financialYear !== undefined) {
            queryParameters['financial_year'] = requestParameters.financialYear;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/payments/daily/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DailyPaymentFromJSON));
    }

    /**
     * 
     */
    async listDailyPayments(requestParameters: ListDailyPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DailyPayment>> {
        const response = await this.listDailyPaymentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listMembershipFormsRaw(requestParameters: ListMembershipFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MembershipForms>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listMembershipForms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/tasks/forms/{user_id}/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MembershipFormsFromJSON));
    }

    /**
     * 
     */
    async listMembershipForms(requestParameters: ListMembershipFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MembershipForms>> {
        const response = await this.listMembershipFormsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listMembershipStatusesRaw(requestParameters: ListMembershipStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MembershipStatus>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listMembershipStatuses.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/memberships/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MembershipStatusFromJSON));
    }

    /**
     * 
     */
    async listMembershipStatuses(requestParameters: ListMembershipStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MembershipStatus>> {
        const response = await this.listMembershipStatusesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listPaymentsRaw(requestParameters: ListPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Payment>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listPayments.');
        }

        const queryParameters: any = {};

        if (requestParameters.financialYear !== undefined) {
            queryParameters['financial_year'] = requestParameters.financialYear;
        }

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/payments/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentFromJSON));
    }

    /**
     * 
     */
    async listPayments(requestParameters: ListPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Payment>> {
        const response = await this.listPaymentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listSocietiesRaw(requestParameters: ListSocietiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Society>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listSocieties.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/societies/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SocietyFromJSON));
    }

    /**
     * 
     */
    async listSocieties(requestParameters: ListSocietiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Society>> {
        const response = await this.listSocietiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listSocietyFormsRaw(requestParameters: ListSocietyFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SocietyForm>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listSocietyForms.');
        }

        if (requestParameters.societyId === null || requestParameters.societyId === undefined) {
            throw new runtime.RequiredError('societyId','Required parameter requestParameters.societyId was null or undefined when calling listSocietyForms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/societies/{society_id}/forms/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"society_id"}}`, encodeURIComponent(String(requestParameters.societyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SocietyFormFromJSON));
    }

    /**
     * 
     */
    async listSocietyForms(requestParameters: ListSocietyFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SocietyForm>> {
        const response = await this.listSocietyFormsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async partialUpdateUserSettingsRaw(requestParameters: PartialUpdateUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSettings>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling partialUpdateUserSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/settings/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserSettingsToJSON(requestParameters.userSettings),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSettingsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async partialUpdateUserSettings(requestParameters: PartialUpdateUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSettings> {
        const response = await this.partialUpdateUserSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveAdditionalContactDetailsRaw(requestParameters: RetrieveAdditionalContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdditionalContactDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveAdditionalContactDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/additional-contact-details/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdditionalContactDetailsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveAdditionalContactDetails(requestParameters: RetrieveAdditionalContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdditionalContactDetails> {
        const response = await this.retrieveAdditionalContactDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveArtistDetailsRaw(requestParameters: RetrieveArtistDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArtistDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveArtistDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/artist-details/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtistDetailsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveArtistDetails(requestParameters: RetrieveArtistDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArtistDetails> {
        const response = await this.retrieveArtistDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveByEmailHashClientInterestRegistrationRaw(requestParameters: RetrieveByEmailHashClientInterestRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientInterestRegistration>> {
        if (requestParameters.emailHash === null || requestParameters.emailHash === undefined) {
            throw new runtime.RequiredError('emailHash','Required parameter requestParameters.emailHash was null or undefined when calling retrieveByEmailHashClientInterestRegistration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{email_hash}/interest-registration/`.replace(`{${"email_hash"}}`, encodeURIComponent(String(requestParameters.emailHash))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientInterestRegistrationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveByEmailHashClientInterestRegistration(requestParameters: RetrieveByEmailHashClientInterestRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientInterestRegistration> {
        const response = await this.retrieveByEmailHashClientInterestRegistrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveByUsernameUserRaw(requestParameters: RetrieveByUsernameUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientUser>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling retrieveByUsernameUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{username}/`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientUserFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveByUsernameUser(requestParameters: RetrieveByUsernameUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientUser> {
        const response = await this.retrieveByUsernameUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveClientBankDetailsRaw(requestParameters: RetrieveClientBankDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientBankDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveClientBankDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/bank-details/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientBankDetailsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveClientBankDetails(requestParameters: RetrieveClientBankDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientBankDetails> {
        const response = await this.retrieveClientBankDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveClientPersonalInformationRaw(requestParameters: RetrieveClientPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientPersonalInformation>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveClientPersonalInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/personal-information/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientPersonalInformationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveClientPersonalInformation(requestParameters: RetrieveClientPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientPersonalInformation> {
        const response = await this.retrieveClientPersonalInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveFinancialYearsRaw(requestParameters: RetrieveFinancialYearsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FinancialYears>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveFinancialYears.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/financial-years/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FinancialYearsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveFinancialYears(requestParameters: RetrieveFinancialYearsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FinancialYears> {
        const response = await this.retrieveFinancialYearsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveIdentityDocumentTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdentityDocumentTypes>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/identity-document-types/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityDocumentTypesFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveIdentityDocumentTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdentityDocumentTypes> {
        const response = await this.retrieveIdentityDocumentTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveLatestClientIdentityDocumentSubmissionRaw(requestParameters: RetrieveLatestClientIdentityDocumentSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientIdentityDocumentSubmission>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveLatestClientIdentityDocumentSubmission.');
        }

        if (requestParameters.documentType === null || requestParameters.documentType === undefined) {
            throw new runtime.RequiredError('documentType','Required parameter requestParameters.documentType was null or undefined when calling retrieveLatestClientIdentityDocumentSubmission.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentType !== undefined) {
            queryParameters['document_type'] = requestParameters.documentType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/identity-document-submissions/latest/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientIdentityDocumentSubmissionFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveLatestClientIdentityDocumentSubmission(requestParameters: RetrieveLatestClientIdentityDocumentSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientIdentityDocumentSubmission> {
        const response = await this.retrieveLatestClientIdentityDocumentSubmissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveLatestPrimaryDiscographyExportRaw(requestParameters: RetrieveLatestPrimaryDiscographyExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrimaryDiscographyExport>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveLatestPrimaryDiscographyExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/primary-discography/exports/latest/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrimaryDiscographyExportFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveLatestPrimaryDiscographyExport(requestParameters: RetrieveLatestPrimaryDiscographyExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrimaryDiscographyExport> {
        const response = await this.retrieveLatestPrimaryDiscographyExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveOnboardingProgressRaw(requestParameters: RetrieveOnboardingProgressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OnboardingProgress>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveOnboardingProgress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/onboarding-progress/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingProgressFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveOnboardingProgress(requestParameters: RetrieveOnboardingProgressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OnboardingProgress> {
        const response = await this.retrieveOnboardingProgressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveOnboardingStepsRaw(requestParameters: RetrieveOnboardingStepsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OnboardingSteps>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveOnboardingSteps.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/onboarding-steps/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingStepsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveOnboardingSteps(requestParameters: RetrieveOnboardingStepsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OnboardingSteps> {
        const response = await this.retrieveOnboardingStepsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrievePandadocEgressRaw(requestParameters: RetrievePandadocEgressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PandadocEgress>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrievePandadocEgress.');
        }

        if (requestParameters.societyFormId === null || requestParameters.societyFormId === undefined) {
            throw new runtime.RequiredError('societyFormId','Required parameter requestParameters.societyFormId was null or undefined when calling retrievePandadocEgress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/pandadocs/{user_id}/egress/{society_form_id}/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"society_form_id"}}`, encodeURIComponent(String(requestParameters.societyFormId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PandadocEgressFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrievePandadocEgress(requestParameters: RetrievePandadocEgressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PandadocEgress> {
        const response = await this.retrievePandadocEgressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrievePrimaryDiscographyRaw(requestParameters: RetrievePrimaryDiscographyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrimaryDiscography>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrievePrimaryDiscography.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/primary-discography/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrimaryDiscographyFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrievePrimaryDiscography(requestParameters: RetrievePrimaryDiscographyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrimaryDiscography> {
        const response = await this.retrievePrimaryDiscographyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrievePrimaryDiscographyExportRaw(requestParameters: RetrievePrimaryDiscographyExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrimaryDiscographyExport>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrievePrimaryDiscographyExport.');
        }

        if (requestParameters.exportId === null || requestParameters.exportId === undefined) {
            throw new runtime.RequiredError('exportId','Required parameter requestParameters.exportId was null or undefined when calling retrievePrimaryDiscographyExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/primary-discography/exports/{export_id}/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"export_id"}}`, encodeURIComponent(String(requestParameters.exportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrimaryDiscographyExportFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrievePrimaryDiscographyExport(requestParameters: RetrievePrimaryDiscographyExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrimaryDiscographyExport> {
        const response = await this.retrievePrimaryDiscographyExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveRepresentativeRaw(requestParameters: RetrieveRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Representative>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveRepresentative.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/representative/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepresentativeFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveRepresentative(requestParameters: RetrieveRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Representative> {
        const response = await this.retrieveRepresentativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveStatementsJsonRaw(requestParameters: RetrieveStatementsJsonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatementsJson>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveStatementsJson.');
        }

        const queryParameters: any = {};

        if (requestParameters.paymentDay !== undefined) {
            queryParameters['payment_day'] = requestParameters.paymentDay;
        }

        if (requestParameters.paymentDays) {
            queryParameters['payment_days'] = requestParameters.paymentDays;
        }

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/statements/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatementsJsonFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveStatementsJson(requestParameters: RetrieveStatementsJsonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatementsJson> {
        const response = await this.retrieveStatementsJsonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveUserRaw(requestParameters: RetrieveUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientUser>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientUserFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveUser(requestParameters: RetrieveUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientUser> {
        const response = await this.retrieveUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveUserSettingsRaw(requestParameters: RetrieveUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSettings>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveUserSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/settings/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSettingsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveUserSettings(requestParameters: RetrieveUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSettings> {
        const response = await this.retrieveUserSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateAdditionalContactDetailsRaw(requestParameters: UpdateAdditionalContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdditionalContactDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateAdditionalContactDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/additional-contact-details/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdditionalContactDetailsToJSON(requestParameters.additionalContactDetails),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdditionalContactDetailsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateAdditionalContactDetails(requestParameters: UpdateAdditionalContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdditionalContactDetails> {
        const response = await this.updateAdditionalContactDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateClientBankDetailsRaw(requestParameters: UpdateClientBankDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientBankDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateClientBankDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/bank-details/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientBankDetailsToJSON(requestParameters.clientBankDetails),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientBankDetailsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateClientBankDetails(requestParameters: UpdateClientBankDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientBankDetails> {
        const response = await this.updateClientBankDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateClientIdentityDocumentSubmissionRaw(requestParameters: UpdateClientIdentityDocumentSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientIdentityDocumentSubmission>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateClientIdentityDocumentSubmission.');
        }

        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling updateClientIdentityDocumentSubmission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('id', requestParameters.id as any);
        }

        if (requestParameters.dateCreated !== undefined) {
            formParams.append('date_created', requestParameters.dateCreated as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.filename !== undefined) {
            formParams.append('filename', requestParameters.filename as any);
        }

        if (requestParameters.status !== undefined) {
            formParams.append('status', requestParameters.status as any);
        }

        if (requestParameters.documentExpiryDate !== undefined) {
            formParams.append('document_expiry_date', requestParameters.documentExpiryDate as any);
        }

        if (requestParameters.documentType !== undefined) {
            formParams.append('document_type', requestParameters.documentType as any);
        }

        if (requestParameters.fileUrl !== undefined) {
            formParams.append('file_url', requestParameters.fileUrl as any);
        }

        const response = await this.request({
            path: `/portal/users/{user_id}/identity-document-submissions/{document_id}/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"document_id"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientIdentityDocumentSubmissionFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateClientIdentityDocumentSubmission(requestParameters: UpdateClientIdentityDocumentSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientIdentityDocumentSubmission> {
        const response = await this.updateClientIdentityDocumentSubmissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateClientPersonalInformationRaw(requestParameters: UpdateClientPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientPersonalInformation>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateClientPersonalInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/personal-information/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientPersonalInformationToJSON(requestParameters.clientPersonalInformation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientPersonalInformationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateClientPersonalInformation(requestParameters: UpdateClientPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientPersonalInformation> {
        const response = await this.updateClientPersonalInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateClientSocietyFormTaskRaw(requestParameters: UpdateClientSocietyFormTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientSocietyFormTask>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateClientSocietyFormTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/client_society_form_tasks`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientSocietyFormTaskToJSON(requestParameters.clientSocietyFormTask),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientSocietyFormTaskFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateClientSocietyFormTask(requestParameters: UpdateClientSocietyFormTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientSocietyFormTask> {
        const response = await this.updateClientSocietyFormTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updatePandadocEgressRaw(requestParameters: UpdatePandadocEgressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PandadocEgress>> {
        if (requestParameters.societyFormId === null || requestParameters.societyFormId === undefined) {
            throw new runtime.RequiredError('societyFormId','Required parameter requestParameters.societyFormId was null or undefined when calling updatePandadocEgress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/pandadocs/str:user_id>/ingress/{society_form_id}/`.replace(`{${"society_form_id"}}`, encodeURIComponent(String(requestParameters.societyFormId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PandadocEgressFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updatePandadocEgress(requestParameters: UpdatePandadocEgressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PandadocEgress> {
        const response = await this.updatePandadocEgressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updatePhotoClientPersonalInformationRaw(requestParameters: UpdatePhotoClientPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientPersonalInformation>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updatePhotoClientPersonalInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.user !== undefined) {
            formParams.append('user', requestParameters.user as any);
        }

        if (requestParameters.url !== undefined) {
            formParams.append('url', requestParameters.url as any);
        }

        if (requestParameters.oldPassword !== undefined) {
            formParams.append('old_password', requestParameters.oldPassword as any);
        }

        if (requestParameters.password !== undefined) {
            formParams.append('password', requestParameters.password as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }

        if (requestParameters.gender !== undefined) {
            formParams.append('gender', requestParameters.gender as any);
        }

        if (requestParameters.cityOfBirth !== undefined) {
            formParams.append('city_of_birth', requestParameters.cityOfBirth as any);
        }

        if (requestParameters.countryOfBirth !== undefined) {
            formParams.append('country_of_birth', requestParameters.countryOfBirth as any);
        }

        if (requestParameters.fullName !== undefined) {
            formParams.append('full_name', requestParameters.fullName as any);
        }

        if (requestParameters.firstName !== undefined) {
            formParams.append('first_name', requestParameters.firstName as any);
        }

        if (requestParameters.middleName !== undefined) {
            formParams.append('middle_name', requestParameters.middleName as any);
        }

        if (requestParameters.lastName !== undefined) {
            formParams.append('last_name', requestParameters.lastName as any);
        }

        if (requestParameters.department !== undefined) {
            formParams.append('department', requestParameters.department as any);
        }

        if (requestParameters.departmentChoices) {
            formParams.append('department_choices', requestParameters.departmentChoices.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters.userPhoto !== undefined) {
            formParams.append('user_photo', requestParameters.userPhoto as any);
        }

        if (requestParameters.userPhotoUrl !== undefined) {
            formParams.append('user_photo_url', requestParameters.userPhotoUrl as any);
        }

        if (requestParameters.dateOfBirth !== undefined) {
            formParams.append('date_of_birth', requestParameters.dateOfBirth as any);
        }

        if (requestParameters.aliases) {
            formParams.append('aliases', requestParameters.aliases.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters.nationality !== undefined) {
            formParams.append('nationality', requestParameters.nationality as any);
        }

        if (requestParameters.taxationCountry !== undefined) {
            formParams.append('taxation_country', requestParameters.taxationCountry as any);
        }

        if (requestParameters.vatNumber !== undefined) {
            formParams.append('vat_number', requestParameters.vatNumber as any);
        }

        if (requestParameters.taxIdType !== undefined) {
            formParams.append('tax_id_type', requestParameters.taxIdType as any);
        }

        if (requestParameters.taxIdNumber !== undefined) {
            formParams.append('tax_id_number', requestParameters.taxIdNumber as any);
        }

        if (requestParameters.emailAddress !== undefined) {
            formParams.append('email_address', requestParameters.emailAddress as any);
        }

        if (requestParameters.streetAddress !== undefined) {
            formParams.append('street_address', requestParameters.streetAddress as any);
        }

        if (requestParameters.country !== undefined) {
            formParams.append('country', requestParameters.country as any);
        }

        if (requestParameters.phoneNumber !== undefined) {
            formParams.append('phone_number', requestParameters.phoneNumber as any);
        }

        if (requestParameters.county !== undefined) {
            formParams.append('county', requestParameters.county as any);
        }

        if (requestParameters.postcode !== undefined) {
            formParams.append('postcode', requestParameters.postcode as any);
        }

        if (requestParameters.westburyId !== undefined) {
            formParams.append('westbury_id', requestParameters.westburyId as any);
        }

        const response = await this.request({
            path: `/portal/users/{user_id}/personal-information/photo/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientPersonalInformationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updatePhotoClientPersonalInformation(requestParameters: UpdatePhotoClientPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientPersonalInformation> {
        const response = await this.updatePhotoClientPersonalInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateRepresentativeRaw(requestParameters: UpdateRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Representative>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateRepresentative.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/representative/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RepresentativeToJSON(requestParameters.representative),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepresentativeFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateRepresentative(requestParameters: UpdateRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Representative> {
        const response = await this.updateRepresentativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateSignupAuthTokenRaw(requestParameters: UpdateSignupAuthTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignupAuthToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/signup/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SignupToJSON(requestParameters.signup),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignupAuthTokenFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateSignupAuthToken(requestParameters: UpdateSignupAuthTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignupAuthToken> {
        const response = await this.updateSignupAuthTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateUserSettingsRaw(requestParameters: UpdateUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSettings>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUserSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/settings/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserSettingsToJSON(requestParameters.userSettings),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSettingsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateUserSettings(requestParameters: UpdateUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSettings> {
        const response = await this.updateUserSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const CreateClientIdentityDocumentSubmissionStatusEnum = {
    PendingDecision: 'pending_decision',
    Invalid: 'invalid',
    Accepted: 'accepted'
} as const;
export type CreateClientIdentityDocumentSubmissionStatusEnum = typeof CreateClientIdentityDocumentSubmissionStatusEnum[keyof typeof CreateClientIdentityDocumentSubmissionStatusEnum];
/**
 * @export
 */
export const CreateClientIdentityDocumentSubmissionDocumentTypeEnum = {
    Passport: 'Passport',
    CurrentAgreement: 'Current_Agreement',
    TerminationConfirmation: 'Termination_Confirmation',
    ProofOfAddress: 'Proof_Of_Address',
    PowerOfAttorney: 'Power_Of_Attorney',
    TermsOfAgreement: 'Terms_Of_Agreement'
} as const;
export type CreateClientIdentityDocumentSubmissionDocumentTypeEnum = typeof CreateClientIdentityDocumentSubmissionDocumentTypeEnum[keyof typeof CreateClientIdentityDocumentSubmissionDocumentTypeEnum];
/**
 * @export
 */
export const UpdateClientIdentityDocumentSubmissionStatusEnum = {
    PendingDecision: 'pending_decision',
    Invalid: 'invalid',
    Accepted: 'accepted'
} as const;
export type UpdateClientIdentityDocumentSubmissionStatusEnum = typeof UpdateClientIdentityDocumentSubmissionStatusEnum[keyof typeof UpdateClientIdentityDocumentSubmissionStatusEnum];
/**
 * @export
 */
export const UpdateClientIdentityDocumentSubmissionDocumentTypeEnum = {
    Passport: 'Passport',
    CurrentAgreement: 'Current_Agreement',
    TerminationConfirmation: 'Termination_Confirmation',
    ProofOfAddress: 'Proof_Of_Address',
    PowerOfAttorney: 'Power_Of_Attorney',
    TermsOfAgreement: 'Terms_Of_Agreement'
} as const;
export type UpdateClientIdentityDocumentSubmissionDocumentTypeEnum = typeof UpdateClientIdentityDocumentSubmissionDocumentTypeEnum[keyof typeof UpdateClientIdentityDocumentSubmissionDocumentTypeEnum];
/**
 * @export
 */
export const UpdatePhotoClientPersonalInformationDepartmentEnum = {
    Membership: 'Membership',
    Discography: 'Discography',
    Distribution: 'Distribution'
} as const;
export type UpdatePhotoClientPersonalInformationDepartmentEnum = typeof UpdatePhotoClientPersonalInformationDepartmentEnum[keyof typeof UpdatePhotoClientPersonalInformationDepartmentEnum];
/**
 * @export
 */
export const UpdatePhotoClientPersonalInformationTaxIdTypeEnum = {
    TinTaxpayerIdentificationNumber: 'TIN - Taxpayer Identification Number',
    ItinIndividualTaxpayerIdentificationNumber: 'ITIN - Individual Taxpayer Identification Number',
    FtinForeignTaxpayerIdentificationNumber: 'FTIN - Foreign Taxpayer Identification Number',
    PibPoreskiIdentifikacioniBroj: 'PIB - Poreski Identifikacioni Broj',
    PpsPersonalPublicService: 'PPS - Personal Public Service',
    NipNumerIdentyfikacjiPodatkowej: 'NIP - Numer Identyfikacji Podatkowej',
    FederalTaxId: 'Federal Tax ID',
    DepartmentOfStateId: 'Department of State ID',
    NationalInsuranceNumber: 'National Insurance Number',
    SocialSecurityNumber: 'Social Security Number'
} as const;
export type UpdatePhotoClientPersonalInformationTaxIdTypeEnum = typeof UpdatePhotoClientPersonalInformationTaxIdTypeEnum[keyof typeof UpdatePhotoClientPersonalInformationTaxIdTypeEnum];
