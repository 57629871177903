import React, { useState, ReactNode } from 'react';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import Footer from './Footer/Footer';

/*
    A component that wraps the page content and provides the sidebar, header, and footer.
    Can be used to wrap any page content.
*/

interface PageLayoutProps {
  children: ReactNode;
}

function PageLayout({ children }: PageLayoutProps) {
  const [sidebarSmall, setSidebarSmall] = useState(false);

  return (
    <div className="tw-h-screen tw-flex tw-flex-col tw-bg-surfaceBlack80">
      {/* Header */}
      <div className="">
        <Header />
      </div>

      {/* Main content */}
      <div className="tw-flex tw-grow tw-pb-[60px] tw-bg-surfaceBlack80">
        {/* Sidebar */}
        <aside
          className="tw-w-1/6 tw-bg-surfaceBlack80 tw-p-6"
          style={{ borderRight: '1px solid #535D66', maxWidth: '218px' }}
        >
          {/* 218px is taken from Figma: 170px + 2*24px */}
          <Sidebar sidebarToggle={sidebarSmall} setToggle={setSidebarSmall} />
        </aside>

        {/* Main content area */}
        <main
          className="tw-w-5/6 tw-bg-surfaceBlack80 tw-pl-6"
          style={{ maxWidth: '1580px' }}
        >
          {/* 1080px is taken from Figma */}
          {children}
        </main>
      </div>

      {/* Footer */}
      <div className="tw-bg-surfaceBlack90">
        <Footer />
      </div>
    </div>
  );
}

export default PageLayout;
