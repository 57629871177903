import React, { useEffect, useState } from 'react';
import Countries from './Forms/Countries.json';

import { CreateIdentityVerficationSubmissionRequest } from 'shared_components/generated/client';
import { useApi } from 'shared_components/context';
import { useUserId } from 'shared_components/context/user';
import WModal from 'shared_components/components/WModal';
import { WizardProvider } from 'shared_components/components/WForms/Wizard';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import WFileUpload from 'shared_components/components/WForms/WFileUpload/WFileUpload';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { z } from 'zod';
import { useWizardContext } from 'shared_components/components/WForms/Wizard';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import { WRadioButton } from 'shared_components/components/WForms/WRadioButton/WRadioButton';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';
import { Constants } from 'shared_components/utils/Countries';
interface Props {
  verifyShowModal: boolean;
  setIsOpenVerificationModal?: any; // react state update
  verifyModalClose: () => void;
  fetchOnboardingSteps?: () => void;
}

function VerificationModal({
  verifyShowModal,
  verifyModalClose,
  setIsOpenVerificationModal,
}: Props) {
  const { clientApi: api } = useApi();
  const { userId: userId } = useUserId();

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const emptyFormState = {
    passportFile: undefined,
    proofOfAddressFile: undefined,
    proofOfAddressFileName: '',
    registeredWithPpl: 'false',
    streetAddress: '',
    postcode: '',
    county: '',
    country: '',
    witnessFirstName: '',
    witnessLastName: '',
    witnessEmail: '',
  };
  const [formData, setFormData] = useState(emptyFormState);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | null
  ) => {
    if (event && event.target.files !== null) {
      setFormData({ ...formData, [event.target.name]: event.target.files[0] });
    }
  };

  const inputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    if (userId === undefined) {
      return;
    }

    const formDataValue: CreateIdentityVerficationSubmissionRequest = {
      userId,
      ...formData,
    };
    const res = api
      .createIdentityVerficationSubmission(formDataValue)
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  };

  const step1Schema = z.object({
    registeredWithPpl: z.enum(['true', 'false']),
    passportFile: z
      .any()
      .refine(() => formData.passportFile != undefined, 'Passport is required.')
      .refine(
        () => formData.passportFile?.size <= Constants.MAX_DOCUMENT_SIZE,
        `Max file size is 5MB.`
      )
      .refine(
        () =>
          [
            ...Constants.DOCUMENT_FILE_TYPES,
            ...Constants.IMAGE_FILE_TYPES,
          ].includes(formData.passportFile?.type),
        'Only .jpg, .jpeg, .png, .webp and .pdf files are accepted.'
      ),
    proofOfAddressFile: z.instanceof(File).optional(),
  });

  const refinedSchema = step1Schema.refine(
    (data) => {
      if (data.registeredWithPpl === 'true') {
        return true;
      }
      if (data.proofOfAddressFile != undefined) {
        // proofOfAddressFile needs to be less than MAX_DOCUMENT_SIZE and of type ACCEPTED_IMAGE_TYPES
        return (
          data.proofOfAddressFile.size <= Constants.MAX_DOCUMENT_SIZE &&
          ACCEPTED_IMAGE_TYPES.includes(data.proofOfAddressFile.type)
        );
      }
    },
    {
      path: ['proofOfAddressFile'],
      message:
        'Proof of Address must be a jpg, jpeg, png or pdf file and less than 5MB.',
    }
  );
  const step2Schema = z.object({
    streetAddress: z.string().nonempty('Street Address is required.'),
    county: z.string().nonempty('County is required.'),
    country: z.string().nonempty('Country is required.'),
    postcode: z.string().nonempty('Postcode is required.'),
  });

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const step3Schema = z.object({
    witnessEmail: z.string().regex(emailRegex, 'Invalid email address.'),
    witnessFirstName: z.string().nonempty('First name is required.'),
    witnessLastName: z.string().nonempty('Last name is required.'),
  });

  // Wizard steps uses fake forms for validation purposes only

  const wizardSteps = [
    <div className="" key={'Step1'}>
      <WFormProvider
        formData={formData}
        editByDefault={true}
        submitOnEnter={false}
        handleSubmit={async () => {}}
        schema={refinedSchema}
      >
        <p className="tw-text-2xl tw-mb-2">Verify Identity</p>
        <p className="tw-mb-6">Please upload your verification document(s)</p>

        <WRadioButton
          name="registeredWithPpl"
          label="Are you registered with PPL?"
          selectedValue={formData.registeredWithPpl}
          items={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          onChange={(e) => {
            inputChange(e);
          }}
        />

        <>
          <WFileUpload
            label="Passport"
            name="passportFile"
            subTitle="Drag and drop or click to upload"
            value={formData.passportFile}
            onFileChange={(e) => {
              handleInputChange(e);
            }}
          />
          {formData.registeredWithPpl === 'false' && (
            <WFileUpload
              label="Proof of Address (Utility Bill/Council Tax Bill)"
              name="proofOfAddressFile"
              subTitle="Drag and drop or click to upload"
              value={formData.proofOfAddressFile}
              onFileChange={(e) => {
                handleInputChange(e);
              }}
            />
          )}
        </>

        <div className="tw-flex tw-justify-end tw-mt-4">
          <WizardFooterContinue />
        </div>
      </WFormProvider>
    </div>,
    <>
      <WFormProvider
        formData={formData}
        editByDefault={true}
        handleSubmit={async () => {}}
        schema={step2Schema}
        submitOnEnter={false}
      >
        <p className="tw-text-2xl tw-mb-2">Enter Address</p>
        <p className="tw-mb-6">Please enter the address that you reside</p>
        <WInput
          type="text"
          name="streetAddress"
          label="Street Address"
          value={formData.streetAddress}
          onChange={(e) => {
            inputChange(e);
          }}
        />
        <WInput
          type="text"
          name="county"
          label="County/State"
          value={formData.county}
          onChange={(e) => {
            inputChange(e);
          }}
        />
        <WSelect
          name="country"
          label="Country"
          defaultValue={formData.country}
          options={Countries.map((country) => ({
            label: country,
            value: country,
          }))}
          onChange={(e) => {
            inputChange(e);
          }}
        />
        <WInput
          type="text"
          name="postcode"
          label="ZIP/Postcode"
          value={formData.postcode}
          onChange={(e) => {
            inputChange(e);
          }}
        />
        <WizardFooterContinue />
      </WFormProvider>
    </>,
    <>
      <WFormProvider
        formData={formData}
        editByDefault={true}
        handleSubmit={handleSubmit}
        submitOnEnter={false}
        schema={step3Schema}
        onSuccess={() => {
          setFormData(emptyFormState);
          setIsOpenVerificationModal(false);
          setTimeout(() => {
            setShowSuccessModal(true);
          }, 100);
        }}
      >
        <p className="tw-text-2xl tw-mb-2">Witness details</p>
        <p className="tw-mb-6">
          We need this so they can sign the power of attorney
        </p>
        <WInput
          type="text"
          name="witnessFirstName"
          label="Witness First Name"
          value={formData.witnessFirstName}
          onChange={(e) => {
            inputChange(e);
          }}
        />
        <WInput
          type="text"
          name="witnessLastName"
          label="Witness Last Name"
          value={formData.witnessLastName}
          onChange={(e) => {
            inputChange(e);
          }}
        />
        <WInput
          type="email"
          name="witnessEmail"
          label="Witness Email"
          value={formData.witnessEmail}
          onChange={(e) => {
            inputChange(e);
          }}
        />
        <FormFooterSubmit />
      </WFormProvider>
    </>,
  ];
  const MAX_FILE_SIZE = 5000000; // 5MB
  const ACCEPTED_IMAGE_TYPES = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/webp',
    'application/pdf',
  ];

  return (
    <>
      <WModal
        title=""
        isOpen={showSuccessModal}
        onClose={() => {
          verifyModalClose();
          setShowSuccessModal(false);
        }}
      >
        <ThankyouBlock
          title="Thank you"
          description="WMC will be in contact once your information has been reviewed."
        />
      </WModal>

      <WModal
        disableBackdropClick={true}
        title=""
        isOpen={verifyShowModal}
        onClose={() => {
          verifyModalClose();
        }}
      >
        <div className="tw-flex tw-flex-col tw-w-[450px]">
          <WizardProvider steps={wizardSteps}></WizardProvider>
        </div>
      </WModal>
    </>
  );
}

export default VerificationModal;

function WizardFooterContinue() {
  const { nextStep, currentStep, prevStep } = useWizardContext();
  const { validateForm } = useFormContext();
  useEffect(() => {}, []);
  return (
    <div className="tw-flex tw-justify-between">
      {currentStep !== 0 && (
        <WButton
          variant="secondary"
          label="Back"
          onClick={() => {
            const errors = validateForm();
            if (Object.keys(errors).length === 0) {
              prevStep();
            }
          }}
        />
      )}
      <WButton
        variant="primary"
        label="Continue"
        onClick={() => {
          const errors = validateForm();

          if (Object.keys(errors).length === 0) {
            nextStep();
          }
        }}
      />
    </div>
  );
}

function FormFooterSubmit() {
  const { handleFormSubmit, formData } = useFormContext();
  const { currentStep, prevStep, nextStep } = useWizardContext();

  return (
    <div className="tw-flex tw-justify-between">
      {currentStep !== 0 && (
        <WButton
          variant="secondary"
          label="Back"
          onClick={() => {
            prevStep();
          }}
        />
      )}
      <WButton
        variant="primary"
        label="Submit"
        onClick={() => {
          handleFormSubmit(formData);
          nextStep();
        }}
      />
    </div>
  );
}
