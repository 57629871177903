import React from 'react';
import { useUser } from 'shared_components/context/user';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import WDropdown from 'shared_components/components/WDropdownContent/WDropdown';
import { useNavigate } from 'react-router-dom';
import { useApi } from 'shared_components/context';
import { getTokenFromSessionStorage } from 'shared_components/context/auth';
import SquarePlaceholder100 from 'shared_components/public/assets/SquarePlaceholder100.svg';

export const Header = () => {
  const user = useUser();
  const navigate = useNavigate();
  const api = useApi().clientApi;
  const handleSignOut = async () => {
    try {
      // const refreshToken = getTokenFromSessionStorage()?.refresh;
      // if (!refreshToken) {
      //   return;
      // }
      // await api.createTokenBlacklist({
      //   tokenBlacklist: { refresh: refreshToken },
      // });

      localStorage.removeItem('token');
      sessionStorage.removeItem('token');

      navigate('/signin');
    } catch (error) {
      console.error('Error during logout', error);
    }
  };

  return (
    <div className="tw-flex tw-flex-row tw-justify-end tw-max-w-[1704px] tw-p-6">
      <WDropdown
        trigger={
          <div className="tw-flex tw-flex-row tw-items-center tw-text-white tw-justify-start tw-space-x-2">
            <span>Welcome back {user.firstName}</span>{' '}
            <img
              className="tw-rounded-full tw-ml-6 tw-w-[25px]"
              src={user.imageUrl || SquarePlaceholder100}
              alt="profile img"
            />
            <i className="wi wi-20px wi-chevron-down"></i>
          </div>
        }
        content={
          <div className="tw-flex tw-flex-col tw-px-6 tw-py-2">
            <WButton
              label="Sign out"
              variant="primary"
              onClick={handleSignOut}
            />
          </div>
        }
      ></WDropdown>
    </div>
  );
};

export default Header;
