/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdditionalContactDetails,
  AdminApproveIdentityDocument,
  AdminBankDetails,
  AdminClientList,
  AdminCreateSociety,
  AdminIdentityDocumentSubmission,
  AdminInterestRegistration,
  AdminPersonalInformation,
  AdminSociety,
  AdminSocietyFormTask,
  AdminTaskClient,
  AdminUpdateSociety,
  AdminUpdateSocietyCredentials,
  ArtistDetails,
  ClientSocietyFormTask,
  ClientUser,
  Company,
  CompanyFormRole,
  CompanyInformation,
  CompanyInformationLogoImage,
  CreatePandadocsSessionId,
  DailyPayment,
  Debug,
  DeleteDeclinedArtistRunStatus,
  FinancialYears,
  IdentityDocumentTypes,
  IdentityVerficationSubmission,
  ListSecondaryDiscographies,
  LoginWithEmailorUsernameAuthToken,
  MembershipForms,
  MembershipStatus,
  OnboardingProgress,
  OnboardingSteps,
  PandadocDocumentIds,
  PandadocEgress,
  Payment,
  PipelineRun,
  PipelineRunRequest,
  PipelineRunStatus,
  PreregisteredSocietyPost,
  PreregisteredSocietyPostId,
  PrimaryDiscography,
  PrimaryDiscographyExport,
  Representative,
  SecondaryDiscography,
  SignMembershipForms,
  SigninAuthToken,
  Signup,
  SignupAuthToken,
  Society,
  SocietyContactInformation,
  SocietyForm,
  SocietyFormRole,
  SocietyLogins,
  SocietyPortalTasks,
  StatementsJson,
  TaxFormRole,
  TermsOfAgreement,
  TermsOfAgreementConfirmation,
  TokenBlacklist,
  TokenObtainPair,
  TokenRefresh,
  TokenVerify,
  UpdateSecondaryDiscography,
  UploadMembershipForms,
  UserSettings,
} from '../models';
import {
    AdditionalContactDetailsFromJSON,
    AdditionalContactDetailsToJSON,
    AdminApproveIdentityDocumentFromJSON,
    AdminApproveIdentityDocumentToJSON,
    AdminBankDetailsFromJSON,
    AdminBankDetailsToJSON,
    AdminClientListFromJSON,
    AdminClientListToJSON,
    AdminCreateSocietyFromJSON,
    AdminCreateSocietyToJSON,
    AdminIdentityDocumentSubmissionFromJSON,
    AdminIdentityDocumentSubmissionToJSON,
    AdminInterestRegistrationFromJSON,
    AdminInterestRegistrationToJSON,
    AdminPersonalInformationFromJSON,
    AdminPersonalInformationToJSON,
    AdminSocietyFromJSON,
    AdminSocietyToJSON,
    AdminSocietyFormTaskFromJSON,
    AdminSocietyFormTaskToJSON,
    AdminTaskClientFromJSON,
    AdminTaskClientToJSON,
    AdminUpdateSocietyFromJSON,
    AdminUpdateSocietyToJSON,
    AdminUpdateSocietyCredentialsFromJSON,
    AdminUpdateSocietyCredentialsToJSON,
    ArtistDetailsFromJSON,
    ArtistDetailsToJSON,
    ClientSocietyFormTaskFromJSON,
    ClientSocietyFormTaskToJSON,
    ClientUserFromJSON,
    ClientUserToJSON,
    CompanyFromJSON,
    CompanyToJSON,
    CompanyFormRoleFromJSON,
    CompanyFormRoleToJSON,
    CompanyInformationFromJSON,
    CompanyInformationToJSON,
    CompanyInformationLogoImageFromJSON,
    CompanyInformationLogoImageToJSON,
    CreatePandadocsSessionIdFromJSON,
    CreatePandadocsSessionIdToJSON,
    DailyPaymentFromJSON,
    DailyPaymentToJSON,
    DebugFromJSON,
    DebugToJSON,
    DeleteDeclinedArtistRunStatusFromJSON,
    DeleteDeclinedArtistRunStatusToJSON,
    FinancialYearsFromJSON,
    FinancialYearsToJSON,
    IdentityDocumentTypesFromJSON,
    IdentityDocumentTypesToJSON,
    IdentityVerficationSubmissionFromJSON,
    IdentityVerficationSubmissionToJSON,
    ListSecondaryDiscographiesFromJSON,
    ListSecondaryDiscographiesToJSON,
    LoginWithEmailorUsernameAuthTokenFromJSON,
    LoginWithEmailorUsernameAuthTokenToJSON,
    MembershipFormsFromJSON,
    MembershipFormsToJSON,
    MembershipStatusFromJSON,
    MembershipStatusToJSON,
    OnboardingProgressFromJSON,
    OnboardingProgressToJSON,
    OnboardingStepsFromJSON,
    OnboardingStepsToJSON,
    PandadocDocumentIdsFromJSON,
    PandadocDocumentIdsToJSON,
    PandadocEgressFromJSON,
    PandadocEgressToJSON,
    PaymentFromJSON,
    PaymentToJSON,
    PipelineRunFromJSON,
    PipelineRunToJSON,
    PipelineRunRequestFromJSON,
    PipelineRunRequestToJSON,
    PipelineRunStatusFromJSON,
    PipelineRunStatusToJSON,
    PreregisteredSocietyPostFromJSON,
    PreregisteredSocietyPostToJSON,
    PreregisteredSocietyPostIdFromJSON,
    PreregisteredSocietyPostIdToJSON,
    PrimaryDiscographyFromJSON,
    PrimaryDiscographyToJSON,
    PrimaryDiscographyExportFromJSON,
    PrimaryDiscographyExportToJSON,
    RepresentativeFromJSON,
    RepresentativeToJSON,
    SecondaryDiscographyFromJSON,
    SecondaryDiscographyToJSON,
    SignMembershipFormsFromJSON,
    SignMembershipFormsToJSON,
    SigninAuthTokenFromJSON,
    SigninAuthTokenToJSON,
    SignupFromJSON,
    SignupToJSON,
    SignupAuthTokenFromJSON,
    SignupAuthTokenToJSON,
    SocietyFromJSON,
    SocietyToJSON,
    SocietyContactInformationFromJSON,
    SocietyContactInformationToJSON,
    SocietyFormFromJSON,
    SocietyFormToJSON,
    SocietyFormRoleFromJSON,
    SocietyFormRoleToJSON,
    SocietyLoginsFromJSON,
    SocietyLoginsToJSON,
    SocietyPortalTasksFromJSON,
    SocietyPortalTasksToJSON,
    StatementsJsonFromJSON,
    StatementsJsonToJSON,
    TaxFormRoleFromJSON,
    TaxFormRoleToJSON,
    TermsOfAgreementFromJSON,
    TermsOfAgreementToJSON,
    TermsOfAgreementConfirmationFromJSON,
    TermsOfAgreementConfirmationToJSON,
    TokenBlacklistFromJSON,
    TokenBlacklistToJSON,
    TokenObtainPairFromJSON,
    TokenObtainPairToJSON,
    TokenRefreshFromJSON,
    TokenRefreshToJSON,
    TokenVerifyFromJSON,
    TokenVerifyToJSON,
    UpdateSecondaryDiscographyFromJSON,
    UpdateSecondaryDiscographyToJSON,
    UploadMembershipFormsFromJSON,
    UploadMembershipFormsToJSON,
    UserSettingsFromJSON,
    UserSettingsToJSON,
} from '../models';

export interface AdminApproveMembershipFormsRequest {
    formId: string;
    membershipForms?: MembershipForms;
}

export interface AdminCompleteSocietyPortalTasksRequest {
    taskId: string;
    societyPortalTasks?: SocietyPortalTasks;
}

export interface AdminSignSignMembershipFormsRequest {
    formId: string;
    signMembershipForms?: SignMembershipForms;
}

export interface AdminUploadUploadMembershipFormsRequest {
    formId: string;
    societyFormSubRoleId: string;
    file: Blob;
    user: number;
    societyForm: string;
    id?: string;
    dateCreated?: Date;
    dateUpdated?: Date;
    complete?: boolean;
    dateCompleted?: Date | null;
    pandadocsSessionId?: string | null;
    pandadocsDocumentId?: string | null;
    pandadocsDocumentS3Key?: string | null;
    status?: AdminUploadUploadMembershipFormsStatusEnum;
}

export interface ApproveAdminApproveIdentityDocumentRequest {
    userId: string;
    documentId: string;
    dateOfBirth: string;
    documentNumber: string;
    documentExpiryDate: string;
}

export interface ClientSignClientSocietyFormTaskRequest {
    userId: string;
    taskId: string;
    clientSocietyFormTask?: ClientSocietyFormTask;
}

export interface CreateAdditionalContactDetailsRequest {
    userId: string;
    additionalContactDetails?: AdditionalContactDetails;
}

export interface CreateAdminBankDetailsRequest {
    userId: string;
    adminBankDetails?: AdminBankDetails;
}

export interface CreateAdminCreateSocietyRequest {
    readableName: string;
    id?: string;
    logo?: Blob;
    country?: string | null;
    website?: string | null;
    portal?: string | null;
    phone?: string | null;
    address?: string | null;
    additionalTerritories?: Array<string>;
    formEmail?: string | null;
    formPostalAddress?: string | null;
    formPortal?: string | null;
    formReminderFrequency?: CreateAdminCreateSocietyFormReminderFrequencyEnum;
    formStopReminderFrequency?: CreateAdminCreateSocietyFormStopReminderFrequencyEnum;
    formReminderSendTo?: string | null;
    taxForms?: string;
    roleForms?: string;
    contactInformation?: string;
}

export interface CreateAdminIdentityDocumentSubmissionRequest {
    userId: string;
    id?: string;
    dateCreated?: Date;
    file?: Blob;
    filename?: string | null;
    status?: CreateAdminIdentityDocumentSubmissionStatusEnum;
    documentExpiryDate?: string | null;
    documentSignedDate?: string | null;
    documentType?: CreateAdminIdentityDocumentSubmissionDocumentTypeEnum;
    fileUrl?: string;
}

export interface CreateAdminInterestRegistrationRequest {
    adminInterestRegistration?: AdminInterestRegistration;
}

export interface CreateAdminPersonalInformationRequest {
    userId: string;
    adminPersonalInformation?: AdminPersonalInformation;
}

export interface CreateClientSessionCreatePandadocsSessionIdRequest {
    taskId: string;
    createPandadocsSessionId?: CreatePandadocsSessionId;
}

export interface CreateClientTasksTermsOfAgreementConfirmationRequest {
    userId: string;
    termsOfAgreementConfirmation?: TermsOfAgreementConfirmation;
}

export interface CreateCompanyFormRoleRequest {
    companyFormRole?: CompanyFormRole;
}

export interface CreateGeneratePrimaryDiscographyExportRequest {
    userId: string;
    body?: object;
}

export interface CreateIdentityVerficationSubmissionRequest {
    userId: string;
    passportFile: Blob;
    registeredWithPpl: boolean;
    streetAddress: string;
    postcode: string;
    county: string;
    country: string;
    witnessFirstName: string;
    witnessLastName: string;
    witnessEmail: string;
    proofOfAddressFile?: Blob;
    pplPerformerName?: string;
    pplPerformerId?: string;
}

export interface CreatePandadocDocumentIdsRequest {
    pandadocDocumentIds?: PandadocDocumentIds;
}

export interface CreatePipelineRunRequest {
    pipelineRunRequest?: PipelineRunRequest;
}

export interface CreatePreregisteredSocietyPostIdRequest {
    userId: string;
    preregisteredSocietyPost?: PreregisteredSocietyPost;
}

export interface CreateRepresentativeRequest {
    userId: string;
    representative?: Representative;
}

export interface CreateSigninAuthTokenRequest {
    loginWithEmailorUsernameAuthToken?: LoginWithEmailorUsernameAuthToken;
}

export interface CreateSignupAuthTokenRequest {
    signup?: Signup;
}

export interface CreateTermsOfAgreementRequest {
    userId: string;
    termsOfAgreement?: TermsOfAgreement;
}

export interface CreateTokenBlacklistRequest {
    tokenBlacklist?: TokenBlacklist;
}

export interface CreateTokenObtainPairRequest {
    tokenObtainPair?: TokenObtainPair;
}

export interface CreateTokenRefreshRequest {
    tokenRefresh?: TokenRefresh;
}

export interface CreateTokenVerifyRequest {
    tokenVerify?: TokenVerify;
}

export interface DeclineAdminDeclineIdentityDocumentRequest {
    userId: string;
    documentId: string;
}

export interface DeclineAdminInterestRegistrationRequest {
    userId: string;
    adminInterestRegistration?: AdminInterestRegistration;
}

export interface DestroyAdminTaskClientRequest {
    taskId: string;
}

export interface GenerateSecondaryDiscographyRequest {
    userId: string;
    secondaryDiscography?: SecondaryDiscography;
}

export interface GetAnnotatedEvidenceSignedUrlsPrimaryDiscographyRequest {
    userId: string;
}

export interface ListAdminIdentityDocumentSubmissionsRequest {
    userId: string;
}

export interface ListClientSocietyFormTasksRequest {
    userId: string;
}

export interface ListDailyPaymentsRequest {
    userId: string;
    financialYear?: string;
}

export interface ListListSecondaryDiscographiesRequest {
    userId: string;
}

export interface ListMembershipFormsRequest {
    userId: string;
}

export interface ListMembershipStatusesRequest {
    userId: string;
}

export interface ListPaymentsRequest {
    userId: string;
    financialYear?: string;
    format?: string;
}

export interface ListSocietiesRequest {
    userId: string;
}

export interface ListSocietyContactInformationsRequest {
    societyId: string;
}

export interface ListSocietyFormRolesRequest {
    societyId: string;
}

export interface ListSocietyFormsRequest {
    userId: string;
    societyId: string;
}

export interface ListSocietyLoginsRequest {
    userId: string;
}

export interface ListSocietyPortalTasksRequest {
    userId: string;
}

export interface ListTaxFormRolesRequest {
    societyId: string;
}

export interface PartialUpdateAdminPersonalInformationRequest {
    userId: string;
    adminPersonalInformation?: AdminPersonalInformation;
}

export interface PartialUpdateUserSettingsRequest {
    userId: string;
    userSettings?: UserSettings;
}

export interface RetrieveAdditionalContactDetailsRequest {
    userId: string;
}

export interface RetrieveAdminBankDetailsRequest {
    userId: string;
}

export interface RetrieveAdminInterestRegistrationRequest {
    userId: string;
}

export interface RetrieveAdminPersonalInformationRequest {
    userId: string;
}

export interface RetrieveAdminSocietyRequest {
    societyId: string;
}

export interface RetrieveArtistDetailsRequest {
    userId: string;
}

export interface RetrieveByEmailHashAdminInterestRegistrationRequest {
    emailHash: string;
}

export interface RetrieveByUsernameUserRequest {
    username: string;
}

export interface RetrieveDeleteDeclinedArtistRunStatusRequest {
    userId: string;
}

export interface RetrieveFinancialYearsRequest {
    userId: string;
}

export interface RetrieveLatestAdminIdentityDocumentSubmissionRequest {
    userId: string;
    documentType: string;
}

export interface RetrieveLatestPrimaryDiscographyExportRequest {
    userId: string;
}

export interface RetrieveOnboardingProgressRequest {
    userId: string;
}

export interface RetrieveOnboardingStepsRequest {
    userId: string;
}

export interface RetrievePandadocEgressRequest {
    userId: string;
    societyFormId: string;
}

export interface RetrievePipelineRunStatusRequest {
    userId: string;
}

export interface RetrievePrimaryDiscographyRequest {
    userId: string;
}

export interface RetrievePrimaryDiscographyExportRequest {
    userId: string;
    exportId: string;
}

export interface RetrieveRepresentativeRequest {
    userId: string;
}

export interface RetrieveStatementsJsonRequest {
    userId: string;
    paymentDay?: string;
    paymentDays?: Array<string>;
    format?: string;
}

export interface RetrieveTermsOfAgreementRequest {
    userId: string;
}

export interface RetrieveUserRequest {
    userId: string;
}

export interface RetrieveUserSettingsRequest {
    userId: string;
}

export interface UpdateAdditionalContactDetailsRequest {
    userId: string;
    additionalContactDetails?: AdditionalContactDetails;
}

export interface UpdateAdminBankDetailsRequest {
    userId: string;
    adminBankDetails?: AdminBankDetails;
}

export interface UpdateAdminIdentityDocumentSubmissionRequest {
    userId: string;
    documentId: string;
    id?: string;
    dateCreated?: Date;
    file?: Blob;
    filename?: string | null;
    status?: UpdateAdminIdentityDocumentSubmissionStatusEnum;
    documentExpiryDate?: string | null;
    documentSignedDate?: string | null;
    documentType?: UpdateAdminIdentityDocumentSubmissionDocumentTypeEnum;
    fileUrl?: string;
}

export interface UpdateAdminInterestRegistrationRequest {
    userId: string;
    adminInterestRegistration?: AdminInterestRegistration;
}

export interface UpdateAdminPersonalInformationRequest {
    userId: string;
    adminPersonalInformation?: AdminPersonalInformation;
}

export interface UpdateAdminUpdateSocietyRequest {
    societyId: string;
    readableName: string;
    id?: string;
    logo?: Blob;
    country?: string | null;
    website?: string | null;
    portal?: string | null;
    phone?: string | null;
    address?: string | null;
    additionalTerritories?: Array<string>;
    formEmail?: string | null;
    formPostalAddress?: string | null;
    formPortal?: string | null;
    formReminderFrequency?: UpdateAdminUpdateSocietyFormReminderFrequencyEnum;
    formStopReminderFrequency?: UpdateAdminUpdateSocietyFormStopReminderFrequencyEnum;
    formReminderSendTo?: string | null;
    taxForms?: string;
    roleForms?: string;
    contactInformation?: string;
}

export interface UpdateAdminUpdateSocietyCredentialsRequest {
    userId: string;
    societyId: string;
    adminUpdateSocietyCredentials?: AdminUpdateSocietyCredentials;
}

export interface UpdateClientSocietyFormTaskRequest {
    userId: string;
    clientSocietyFormTask?: ClientSocietyFormTask;
}

export interface UpdateCompanyRequest {
    company?: Company;
}

export interface UpdateCompanyFormRoleRequest {
    companyFormRole?: CompanyFormRole;
}

export interface UpdateCompanyInformationRequest {
    companyInformation?: CompanyInformation;
}

export interface UpdateLogoImageCompanyInformationLogoImageRequest {
    logoImage: Blob;
    logoImageUrl?: string;
}

export interface UpdatePandadocEgressRequest {
    societyFormId: string;
    body?: any | null;
}

export interface UpdatePhotoAdminPersonalInformationRequest {
    userId: string;
    user?: string;
    url?: string;
    oldPassword?: string;
    password?: string;
    role?: UpdatePhotoAdminPersonalInformationRoleEnum;
    title?: string | null;
    gender?: string | null;
    cityOfBirth?: string | null;
    countryOfBirth?: string | null;
    fullName?: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    department?: UpdatePhotoAdminPersonalInformationDepartmentEnum;
    departmentChoices?: Array<string>;
    userPhoto?: Blob;
    userPhotoUrl?: string;
    dateOfBirth?: Date | null;
    aliases?: Array<string>;
    nationality?: string | null;
    taxationCountry?: string | null;
    vatNumber?: string | null;
    taxIdType?: UpdatePhotoAdminPersonalInformationTaxIdTypeEnum;
    taxIdNumber?: string | null;
    emailAddress?: string | null;
    streetAddress?: string | null;
    country?: string | null;
    phoneNumber?: string | null;
    county?: string | null;
    postcode?: string | null;
    westburyId?: string;
}

export interface UpdateRepresentativeRequest {
    userId: string;
    representative?: Representative;
}

export interface UpdateSignupAuthTokenRequest {
    signup?: Signup;
}

export interface UpdateTermsOfAgreementRequest {
    userId: string;
    termsOfAgreement?: TermsOfAgreement;
}

export interface UpdateUpdateSecondaryDiscographyRequest {
    exportId: string;
    updateSecondaryDiscography?: UpdateSecondaryDiscography;
}

export interface UpdateUserSettingsRequest {
    userId: string;
    userSettings?: UserSettings;
}

/**
 * 
 */
export class PortalApi extends runtime.BaseAPI {

    /**
     * 
     */
    async adminApproveMembershipFormsRaw(requestParameters: AdminApproveMembershipFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MembershipForms>> {
        if (requestParameters.formId === null || requestParameters.formId === undefined) {
            throw new runtime.RequiredError('formId','Required parameter requestParameters.formId was null or undefined when calling adminApproveMembershipForms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/tasks/forms/{form_id}/actions/admin-approve/`.replace(`{${"form_id"}}`, encodeURIComponent(String(requestParameters.formId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MembershipFormsToJSON(requestParameters.membershipForms),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MembershipFormsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async adminApproveMembershipForms(requestParameters: AdminApproveMembershipFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MembershipForms> {
        const response = await this.adminApproveMembershipFormsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async adminCompleteSocietyPortalTasksRaw(requestParameters: AdminCompleteSocietyPortalTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocietyPortalTasks>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling adminCompleteSocietyPortalTasks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/tasks/portal/{task_id}/actions/admin-complete/`.replace(`{${"task_id"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SocietyPortalTasksToJSON(requestParameters.societyPortalTasks),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocietyPortalTasksFromJSON(jsonValue));
    }

    /**
     * 
     */
    async adminCompleteSocietyPortalTasks(requestParameters: AdminCompleteSocietyPortalTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocietyPortalTasks> {
        const response = await this.adminCompleteSocietyPortalTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async adminSignSignMembershipFormsRaw(requestParameters: AdminSignSignMembershipFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignMembershipForms>> {
        if (requestParameters.formId === null || requestParameters.formId === undefined) {
            throw new runtime.RequiredError('formId','Required parameter requestParameters.formId was null or undefined when calling adminSignSignMembershipForms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/tasks/forms/{form_id}/actions/admin-sign/`.replace(`{${"form_id"}}`, encodeURIComponent(String(requestParameters.formId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SignMembershipFormsToJSON(requestParameters.signMembershipForms),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignMembershipFormsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async adminSignSignMembershipForms(requestParameters: AdminSignSignMembershipFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignMembershipForms> {
        const response = await this.adminSignSignMembershipFormsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async adminUploadUploadMembershipFormsRaw(requestParameters: AdminUploadUploadMembershipFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadMembershipForms>> {
        if (requestParameters.formId === null || requestParameters.formId === undefined) {
            throw new runtime.RequiredError('formId','Required parameter requestParameters.formId was null or undefined when calling adminUploadUploadMembershipForms.');
        }

        if (requestParameters.societyFormSubRoleId === null || requestParameters.societyFormSubRoleId === undefined) {
            throw new runtime.RequiredError('societyFormSubRoleId','Required parameter requestParameters.societyFormSubRoleId was null or undefined when calling adminUploadUploadMembershipForms.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling adminUploadUploadMembershipForms.');
        }

        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling adminUploadUploadMembershipForms.');
        }

        if (requestParameters.societyForm === null || requestParameters.societyForm === undefined) {
            throw new runtime.RequiredError('societyForm','Required parameter requestParameters.societyForm was null or undefined when calling adminUploadUploadMembershipForms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('id', requestParameters.id as any);
        }

        if (requestParameters.societyFormSubRoleId !== undefined) {
            formParams.append('society_form_sub_role_id', requestParameters.societyFormSubRoleId as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.dateCreated !== undefined) {
            formParams.append('date_created', requestParameters.dateCreated as any);
        }

        if (requestParameters.dateUpdated !== undefined) {
            formParams.append('date_updated', requestParameters.dateUpdated as any);
        }

        if (requestParameters.complete !== undefined) {
            formParams.append('complete', requestParameters.complete as any);
        }

        if (requestParameters.dateCompleted !== undefined) {
            formParams.append('date_completed', requestParameters.dateCompleted as any);
        }

        if (requestParameters.pandadocsSessionId !== undefined) {
            formParams.append('pandadocs_session_id', requestParameters.pandadocsSessionId as any);
        }

        if (requestParameters.pandadocsDocumentId !== undefined) {
            formParams.append('pandadocs_document_id', requestParameters.pandadocsDocumentId as any);
        }

        if (requestParameters.pandadocsDocumentS3Key !== undefined) {
            formParams.append('pandadocs_document_s3_key', requestParameters.pandadocsDocumentS3Key as any);
        }

        if (requestParameters.status !== undefined) {
            formParams.append('status', requestParameters.status as any);
        }

        if (requestParameters.user !== undefined) {
            formParams.append('user', requestParameters.user as any);
        }

        if (requestParameters.societyForm !== undefined) {
            formParams.append('society_form', requestParameters.societyForm as any);
        }

        const response = await this.request({
            path: `/portal/tasks/forms/{form_id}/actions/admin-upload/`.replace(`{${"form_id"}}`, encodeURIComponent(String(requestParameters.formId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadMembershipFormsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async adminUploadUploadMembershipForms(requestParameters: AdminUploadUploadMembershipFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadMembershipForms> {
        const response = await this.adminUploadUploadMembershipFormsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async approveAdminApproveIdentityDocumentRaw(requestParameters: ApproveAdminApproveIdentityDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminApproveIdentityDocument>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling approveAdminApproveIdentityDocument.');
        }

        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling approveAdminApproveIdentityDocument.');
        }

        if (requestParameters.dateOfBirth === null || requestParameters.dateOfBirth === undefined) {
            throw new runtime.RequiredError('dateOfBirth','Required parameter requestParameters.dateOfBirth was null or undefined when calling approveAdminApproveIdentityDocument.');
        }

        if (requestParameters.documentNumber === null || requestParameters.documentNumber === undefined) {
            throw new runtime.RequiredError('documentNumber','Required parameter requestParameters.documentNumber was null or undefined when calling approveAdminApproveIdentityDocument.');
        }

        if (requestParameters.documentExpiryDate === null || requestParameters.documentExpiryDate === undefined) {
            throw new runtime.RequiredError('documentExpiryDate','Required parameter requestParameters.documentExpiryDate was null or undefined when calling approveAdminApproveIdentityDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.dateOfBirth !== undefined) {
            formParams.append('date_of_birth', requestParameters.dateOfBirth as any);
        }

        if (requestParameters.documentNumber !== undefined) {
            formParams.append('document_number', requestParameters.documentNumber as any);
        }

        if (requestParameters.documentExpiryDate !== undefined) {
            formParams.append('document_expiry_date', requestParameters.documentExpiryDate as any);
        }

        const response = await this.request({
            path: `/portal/users/{user_id}/identity-document-submissions/{document_id}/approve/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"document_id"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminApproveIdentityDocumentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async approveAdminApproveIdentityDocument(requestParameters: ApproveAdminApproveIdentityDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminApproveIdentityDocument> {
        const response = await this.approveAdminApproveIdentityDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async clientSignClientSocietyFormTaskRaw(requestParameters: ClientSignClientSocietyFormTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientSocietyFormTask>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling clientSignClientSocietyFormTask.');
        }

        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling clientSignClientSocietyFormTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/client_society_form_tasks/{task_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"task_id"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientSocietyFormTaskToJSON(requestParameters.clientSocietyFormTask),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientSocietyFormTaskFromJSON(jsonValue));
    }

    /**
     * 
     */
    async clientSignClientSocietyFormTask(requestParameters: ClientSignClientSocietyFormTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientSocietyFormTask> {
        const response = await this.clientSignClientSocietyFormTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createAdditionalContactDetailsRaw(requestParameters: CreateAdditionalContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdditionalContactDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createAdditionalContactDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/additional-contact-details/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdditionalContactDetailsToJSON(requestParameters.additionalContactDetails),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdditionalContactDetailsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createAdditionalContactDetails(requestParameters: CreateAdditionalContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdditionalContactDetails> {
        const response = await this.createAdditionalContactDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createAdminBankDetailsRaw(requestParameters: CreateAdminBankDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminBankDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createAdminBankDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/bank-details/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminBankDetailsToJSON(requestParameters.adminBankDetails),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminBankDetailsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createAdminBankDetails(requestParameters: CreateAdminBankDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminBankDetails> {
        const response = await this.createAdminBankDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createAdminCreateSocietyRaw(requestParameters: CreateAdminCreateSocietyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminCreateSociety>> {
        if (requestParameters.readableName === null || requestParameters.readableName === undefined) {
            throw new runtime.RequiredError('readableName','Required parameter requestParameters.readableName was null or undefined when calling createAdminCreateSociety.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('id', requestParameters.id as any);
        }

        if (requestParameters.readableName !== undefined) {
            formParams.append('readable_name', requestParameters.readableName as any);
        }

        if (requestParameters.logo !== undefined) {
            formParams.append('logo', requestParameters.logo as any);
        }

        if (requestParameters.country !== undefined) {
            formParams.append('country', requestParameters.country as any);
        }

        if (requestParameters.website !== undefined) {
            formParams.append('website', requestParameters.website as any);
        }

        if (requestParameters.portal !== undefined) {
            formParams.append('portal', requestParameters.portal as any);
        }

        if (requestParameters.phone !== undefined) {
            formParams.append('phone', requestParameters.phone as any);
        }

        if (requestParameters.address !== undefined) {
            formParams.append('address', requestParameters.address as any);
        }

        if (requestParameters.additionalTerritories) {
            formParams.append('additional_territories', requestParameters.additionalTerritories.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters.formEmail !== undefined) {
            formParams.append('form_email', requestParameters.formEmail as any);
        }

        if (requestParameters.formPostalAddress !== undefined) {
            formParams.append('form_postal_address', requestParameters.formPostalAddress as any);
        }

        if (requestParameters.formPortal !== undefined) {
            formParams.append('form_portal', requestParameters.formPortal as any);
        }

        if (requestParameters.formReminderFrequency !== undefined) {
            formParams.append('form_reminder_frequency', requestParameters.formReminderFrequency as any);
        }

        if (requestParameters.formStopReminderFrequency !== undefined) {
            formParams.append('form_stop_reminder_frequency', requestParameters.formStopReminderFrequency as any);
        }

        if (requestParameters.formReminderSendTo !== undefined) {
            formParams.append('form_reminder_send_to', requestParameters.formReminderSendTo as any);
        }

        if (requestParameters.taxForms !== undefined) {
            formParams.append('tax_forms', requestParameters.taxForms as any);
        }

        if (requestParameters.roleForms !== undefined) {
            formParams.append('role_forms', requestParameters.roleForms as any);
        }

        if (requestParameters.contactInformation !== undefined) {
            formParams.append('contact_information', requestParameters.contactInformation as any);
        }

        const response = await this.request({
            path: `/portal/societies/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminCreateSocietyFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createAdminCreateSociety(requestParameters: CreateAdminCreateSocietyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminCreateSociety> {
        const response = await this.createAdminCreateSocietyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createAdminIdentityDocumentSubmissionRaw(requestParameters: CreateAdminIdentityDocumentSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminIdentityDocumentSubmission>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createAdminIdentityDocumentSubmission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('id', requestParameters.id as any);
        }

        if (requestParameters.dateCreated !== undefined) {
            formParams.append('date_created', requestParameters.dateCreated as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.filename !== undefined) {
            formParams.append('filename', requestParameters.filename as any);
        }

        if (requestParameters.status !== undefined) {
            formParams.append('status', requestParameters.status as any);
        }

        if (requestParameters.documentExpiryDate !== undefined) {
            formParams.append('document_expiry_date', requestParameters.documentExpiryDate as any);
        }

        if (requestParameters.documentSignedDate !== undefined) {
            formParams.append('document_signed_date', requestParameters.documentSignedDate as any);
        }

        if (requestParameters.documentType !== undefined) {
            formParams.append('document_type', requestParameters.documentType as any);
        }

        if (requestParameters.fileUrl !== undefined) {
            formParams.append('file_url', requestParameters.fileUrl as any);
        }

        const response = await this.request({
            path: `/portal/users/{user_id}/identity-document-submissions/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminIdentityDocumentSubmissionFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createAdminIdentityDocumentSubmission(requestParameters: CreateAdminIdentityDocumentSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminIdentityDocumentSubmission> {
        const response = await this.createAdminIdentityDocumentSubmissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create user and user settings based on expression of interest information. Create EOI review and discography ID submission admin tasks.
     */
    async createAdminInterestRegistrationRaw(requestParameters: CreateAdminInterestRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminInterestRegistration>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/interest-registrations/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminInterestRegistrationToJSON(requestParameters.adminInterestRegistration),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminInterestRegistrationFromJSON(jsonValue));
    }

    /**
     * Create user and user settings based on expression of interest information. Create EOI review and discography ID submission admin tasks.
     */
    async createAdminInterestRegistration(requestParameters: CreateAdminInterestRegistrationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminInterestRegistration> {
        const response = await this.createAdminInterestRegistrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createAdminPersonalInformationRaw(requestParameters: CreateAdminPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminPersonalInformation>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createAdminPersonalInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/personal-information/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminPersonalInformationToJSON(requestParameters.adminPersonalInformation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminPersonalInformationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createAdminPersonalInformation(requestParameters: CreateAdminPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminPersonalInformation> {
        const response = await this.createAdminPersonalInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createClientSessionCreatePandadocsSessionIdRaw(requestParameters: CreateClientSessionCreatePandadocsSessionIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatePandadocsSessionId>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling createClientSessionCreatePandadocsSessionId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/pandadocs/{task_id}/session_id/`.replace(`{${"task_id"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePandadocsSessionIdToJSON(requestParameters.createPandadocsSessionId),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePandadocsSessionIdFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createClientSessionCreatePandadocsSessionId(requestParameters: CreateClientSessionCreatePandadocsSessionIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatePandadocsSessionId> {
        const response = await this.createClientSessionCreatePandadocsSessionIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createClientTasksTermsOfAgreementConfirmationRaw(requestParameters: CreateClientTasksTermsOfAgreementConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TermsOfAgreementConfirmation>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createClientTasksTermsOfAgreementConfirmation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/terms-of-agreement/confirm`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TermsOfAgreementConfirmationToJSON(requestParameters.termsOfAgreementConfirmation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TermsOfAgreementConfirmationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createClientTasksTermsOfAgreementConfirmation(requestParameters: CreateClientTasksTermsOfAgreementConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TermsOfAgreementConfirmation> {
        const response = await this.createClientTasksTermsOfAgreementConfirmationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createCompanyFormRoleRaw(requestParameters: CreateCompanyFormRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyFormRole>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/company/forms/roles/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyFormRoleToJSON(requestParameters.companyFormRole),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFormRoleFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createCompanyFormRole(requestParameters: CreateCompanyFormRoleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyFormRole> {
        const response = await this.createCompanyFormRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createGeneratePrimaryDiscographyExportRaw(requestParameters: CreateGeneratePrimaryDiscographyExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createGeneratePrimaryDiscographyExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/primary-discography/exports/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 
     */
    async createGeneratePrimaryDiscographyExport(requestParameters: CreateGeneratePrimaryDiscographyExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.createGeneratePrimaryDiscographyExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createIdentityVerficationSubmissionRaw(requestParameters: CreateIdentityVerficationSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdentityVerficationSubmission>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.passportFile === null || requestParameters.passportFile === undefined) {
            throw new runtime.RequiredError('passportFile','Required parameter requestParameters.passportFile was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.registeredWithPpl === null || requestParameters.registeredWithPpl === undefined) {
            throw new runtime.RequiredError('registeredWithPpl','Required parameter requestParameters.registeredWithPpl was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.streetAddress === null || requestParameters.streetAddress === undefined) {
            throw new runtime.RequiredError('streetAddress','Required parameter requestParameters.streetAddress was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.postcode === null || requestParameters.postcode === undefined) {
            throw new runtime.RequiredError('postcode','Required parameter requestParameters.postcode was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.county === null || requestParameters.county === undefined) {
            throw new runtime.RequiredError('county','Required parameter requestParameters.county was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.country === null || requestParameters.country === undefined) {
            throw new runtime.RequiredError('country','Required parameter requestParameters.country was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.witnessFirstName === null || requestParameters.witnessFirstName === undefined) {
            throw new runtime.RequiredError('witnessFirstName','Required parameter requestParameters.witnessFirstName was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.witnessLastName === null || requestParameters.witnessLastName === undefined) {
            throw new runtime.RequiredError('witnessLastName','Required parameter requestParameters.witnessLastName was null or undefined when calling createIdentityVerficationSubmission.');
        }

        if (requestParameters.witnessEmail === null || requestParameters.witnessEmail === undefined) {
            throw new runtime.RequiredError('witnessEmail','Required parameter requestParameters.witnessEmail was null or undefined when calling createIdentityVerficationSubmission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.passportFile !== undefined) {
            formParams.append('passport_file', requestParameters.passportFile as any);
        }

        if (requestParameters.proofOfAddressFile !== undefined) {
            formParams.append('proof_of_address_file', requestParameters.proofOfAddressFile as any);
        }

        if (requestParameters.registeredWithPpl !== undefined) {
            formParams.append('registered_with_ppl', requestParameters.registeredWithPpl as any);
        }

        if (requestParameters.pplPerformerName !== undefined) {
            formParams.append('ppl_performer_name', requestParameters.pplPerformerName as any);
        }

        if (requestParameters.pplPerformerId !== undefined) {
            formParams.append('ppl_performer_id', requestParameters.pplPerformerId as any);
        }

        if (requestParameters.streetAddress !== undefined) {
            formParams.append('street_address', requestParameters.streetAddress as any);
        }

        if (requestParameters.postcode !== undefined) {
            formParams.append('postcode', requestParameters.postcode as any);
        }

        if (requestParameters.county !== undefined) {
            formParams.append('county', requestParameters.county as any);
        }

        if (requestParameters.country !== undefined) {
            formParams.append('country', requestParameters.country as any);
        }

        if (requestParameters.witnessFirstName !== undefined) {
            formParams.append('witness_first_name', requestParameters.witnessFirstName as any);
        }

        if (requestParameters.witnessLastName !== undefined) {
            formParams.append('witness_last_name', requestParameters.witnessLastName as any);
        }

        if (requestParameters.witnessEmail !== undefined) {
            formParams.append('witness_email', requestParameters.witnessEmail as any);
        }

        const response = await this.request({
            path: `/portal/users/{user_id}/identity-verification-submissions/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityVerficationSubmissionFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createIdentityVerficationSubmission(requestParameters: CreateIdentityVerficationSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdentityVerficationSubmission> {
        const response = await this.createIdentityVerficationSubmissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createPandadocDocumentIdsRaw(requestParameters: CreatePandadocDocumentIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PandadocDocumentIds>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/pandadocs/document_id/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PandadocDocumentIdsToJSON(requestParameters.pandadocDocumentIds),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PandadocDocumentIdsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createPandadocDocumentIds(requestParameters: CreatePandadocDocumentIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PandadocDocumentIds> {
        const response = await this.createPandadocDocumentIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createPipelineRunRaw(requestParameters: CreatePipelineRunRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineRun>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/dagster/primary-discography/pipeline-runs/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PipelineRunRequestToJSON(requestParameters.pipelineRunRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineRunFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createPipelineRun(requestParameters: CreatePipelineRunRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineRun> {
        const response = await this.createPipelineRunRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createPreregisteredSocietyPostIdRaw(requestParameters: CreatePreregisteredSocietyPostIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreregisteredSocietyPostId>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createPreregisteredSocietyPostId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/preregistered-societies/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PreregisteredSocietyPostToJSON(requestParameters.preregisteredSocietyPost),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreregisteredSocietyPostIdFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createPreregisteredSocietyPostId(requestParameters: CreatePreregisteredSocietyPostIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreregisteredSocietyPostId> {
        const response = await this.createPreregisteredSocietyPostIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createRepresentativeRaw(requestParameters: CreateRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Representative>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createRepresentative.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/representative/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RepresentativeToJSON(requestParameters.representative),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepresentativeFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createRepresentative(requestParameters: CreateRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Representative> {
        const response = await this.createRepresentativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createSigninAuthTokenRaw(requestParameters: CreateSigninAuthTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SigninAuthToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/signin/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginWithEmailorUsernameAuthTokenToJSON(requestParameters.loginWithEmailorUsernameAuthToken),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SigninAuthTokenFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createSigninAuthToken(requestParameters: CreateSigninAuthTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SigninAuthToken> {
        const response = await this.createSigninAuthTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createSignupAuthTokenRaw(requestParameters: CreateSignupAuthTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignupAuthToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/signup/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignupToJSON(requestParameters.signup),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignupAuthTokenFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createSignupAuthToken(requestParameters: CreateSignupAuthTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignupAuthToken> {
        const response = await this.createSignupAuthTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createTermsOfAgreementRaw(requestParameters: CreateTermsOfAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TermsOfAgreement>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createTermsOfAgreement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/terms-of-agreement`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TermsOfAgreementToJSON(requestParameters.termsOfAgreement),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TermsOfAgreementFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createTermsOfAgreement(requestParameters: CreateTermsOfAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TermsOfAgreement> {
        const response = await this.createTermsOfAgreementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a token and blacklists it. Must be used with the `rest_framework_simplejwt.token_blacklist` app installed.
     */
    async createTokenBlacklistRaw(requestParameters: CreateTokenBlacklistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenBlacklist>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/api/token/blacklist/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenBlacklistToJSON(requestParameters.tokenBlacklist),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenBlacklistFromJSON(jsonValue));
    }

    /**
     * Takes a token and blacklists it. Must be used with the `rest_framework_simplejwt.token_blacklist` app installed.
     */
    async createTokenBlacklist(requestParameters: CreateTokenBlacklistRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenBlacklist> {
        const response = await this.createTokenBlacklistRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     */
    async createTokenObtainPairRaw(requestParameters: CreateTokenObtainPairRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenObtainPair>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/api/token/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenObtainPairToJSON(requestParameters.tokenObtainPair),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenObtainPairFromJSON(jsonValue));
    }

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     */
    async createTokenObtainPair(requestParameters: CreateTokenObtainPairRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenObtainPair> {
        const response = await this.createTokenObtainPairRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     */
    async createTokenRefreshRaw(requestParameters: CreateTokenRefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenRefresh>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/api/token/refresh/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenRefreshToJSON(requestParameters.tokenRefresh),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenRefreshFromJSON(jsonValue));
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     */
    async createTokenRefresh(requestParameters: CreateTokenRefreshRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenRefresh> {
        const response = await this.createTokenRefreshRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     */
    async createTokenVerifyRaw(requestParameters: CreateTokenVerifyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenVerify>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/api/token/verify/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenVerifyToJSON(requestParameters.tokenVerify),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenVerifyFromJSON(jsonValue));
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     */
    async createTokenVerify(requestParameters: CreateTokenVerifyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenVerify> {
        const response = await this.createTokenVerifyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async declineAdminDeclineIdentityDocumentRaw(requestParameters: DeclineAdminDeclineIdentityDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling declineAdminDeclineIdentityDocument.');
        }

        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling declineAdminDeclineIdentityDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/identity-document-submissions/{document_id}/decline/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"document_id"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 
     */
    async declineAdminDeclineIdentityDocument(requestParameters: DeclineAdminDeclineIdentityDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.declineAdminDeclineIdentityDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Decline EOI for user with user_id Deletes user from DB
     */
    async declineAdminInterestRegistrationRaw(requestParameters: DeclineAdminInterestRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminInterestRegistration>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling declineAdminInterestRegistration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/interest-registration/decline`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminInterestRegistrationToJSON(requestParameters.adminInterestRegistration),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminInterestRegistrationFromJSON(jsonValue));
    }

    /**
     * Decline EOI for user with user_id Deletes user from DB
     */
    async declineAdminInterestRegistration(requestParameters: DeclineAdminInterestRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminInterestRegistration> {
        const response = await this.declineAdminInterestRegistrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async destroyAdminTaskClientRaw(requestParameters: DestroyAdminTaskClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling destroyAdminTaskClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/tasks/{task_id}/`.replace(`{${"task_id"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async destroyAdminTaskClient(requestParameters: DestroyAdminTaskClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.destroyAdminTaskClientRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve secondary discography data
     */
    async generateSecondaryDiscographyRaw(requestParameters: GenerateSecondaryDiscographyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SecondaryDiscography>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling generateSecondaryDiscography.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/secondary-discography/exports/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SecondaryDiscographyToJSON(requestParameters.secondaryDiscography),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SecondaryDiscographyFromJSON(jsonValue));
    }

    /**
     * Retrieve secondary discography data
     */
    async generateSecondaryDiscography(requestParameters: GenerateSecondaryDiscographyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SecondaryDiscography> {
        const response = await this.generateSecondaryDiscographyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getAnnotatedEvidenceSignedUrlsPrimaryDiscographyRaw(requestParameters: GetAnnotatedEvidenceSignedUrlsPrimaryDiscographyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrimaryDiscography>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getAnnotatedEvidenceSignedUrlsPrimaryDiscography.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/primary-discography/annotated-evidence-signed-urls/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrimaryDiscographyFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getAnnotatedEvidenceSignedUrlsPrimaryDiscography(requestParameters: GetAnnotatedEvidenceSignedUrlsPrimaryDiscographyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrimaryDiscography> {
        const response = await this.getAnnotatedEvidenceSignedUrlsPrimaryDiscographyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listAdminIdentityDocumentSubmissionsRaw(requestParameters: ListAdminIdentityDocumentSubmissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AdminIdentityDocumentSubmission>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listAdminIdentityDocumentSubmissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/identity-document-submissions/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminIdentityDocumentSubmissionFromJSON));
    }

    /**
     * 
     */
    async listAdminIdentityDocumentSubmissions(requestParameters: ListAdminIdentityDocumentSubmissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AdminIdentityDocumentSubmission>> {
        const response = await this.listAdminIdentityDocumentSubmissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listAdminSocietiesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AdminSociety>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/societies/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminSocietyFromJSON));
    }

    /**
     * 
     */
    async listAdminSocieties(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AdminSociety>> {
        const response = await this.listAdminSocietiesRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listAdminSocietyFormTasksRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AdminSocietyFormTask>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/tasks/forms/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminSocietyFormTaskFromJSON));
    }

    /**
     * 
     */
    async listAdminSocietyFormTasks(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AdminSocietyFormTask>> {
        const response = await this.listAdminSocietyFormTasksRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listAdminTaskClientsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AdminTaskClient>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/tasks/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminTaskClientFromJSON));
    }

    /**
     * 
     */
    async listAdminTaskClients(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AdminTaskClient>> {
        const response = await this.listAdminTaskClientsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listClientSocietyFormTasksRaw(requestParameters: ListClientSocietyFormTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientSocietyFormTask>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listClientSocietyFormTasks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/client_society_form_tasks`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientSocietyFormTaskFromJSON));
    }

    /**
     * 
     */
    async listClientSocietyFormTasks(requestParameters: ListClientSocietyFormTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientSocietyFormTask>> {
        const response = await this.listClientSocietyFormTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listCompanyFormRolesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyFormRole>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/company/forms/roles/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyFormRoleFromJSON));
    }

    /**
     * 
     */
    async listCompanyFormRoles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyFormRole>> {
        const response = await this.listCompanyFormRolesRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listDailyPaymentsRaw(requestParameters: ListDailyPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DailyPayment>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listDailyPayments.');
        }

        const queryParameters: any = {};

        if (requestParameters.financialYear !== undefined) {
            queryParameters['financial_year'] = requestParameters.financialYear;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/payments/daily/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DailyPaymentFromJSON));
    }

    /**
     * 
     */
    async listDailyPayments(requestParameters: ListDailyPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DailyPayment>> {
        const response = await this.listDailyPaymentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listDebugsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Debug>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/debug/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DebugFromJSON));
    }

    /**
     * 
     */
    async listDebugs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Debug>> {
        const response = await this.listDebugsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all secondary discography runs for user
     */
    async listListSecondaryDiscographiesRaw(requestParameters: ListListSecondaryDiscographiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ListSecondaryDiscographies>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listListSecondaryDiscographies.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/secondary-discography/exports/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListSecondaryDiscographiesFromJSON));
    }

    /**
     * List all secondary discography runs for user
     */
    async listListSecondaryDiscographies(requestParameters: ListListSecondaryDiscographiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ListSecondaryDiscographies>> {
        const response = await this.listListSecondaryDiscographiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listMembershipFormsRaw(requestParameters: ListMembershipFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MembershipForms>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listMembershipForms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/tasks/forms/{user_id}/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MembershipFormsFromJSON));
    }

    /**
     * 
     */
    async listMembershipForms(requestParameters: ListMembershipFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MembershipForms>> {
        const response = await this.listMembershipFormsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listMembershipStatusesRaw(requestParameters: ListMembershipStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MembershipStatus>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listMembershipStatuses.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/memberships/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MembershipStatusFromJSON));
    }

    /**
     * 
     */
    async listMembershipStatuses(requestParameters: ListMembershipStatusesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MembershipStatus>> {
        const response = await this.listMembershipStatusesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listPaymentsRaw(requestParameters: ListPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Payment>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listPayments.');
        }

        const queryParameters: any = {};

        if (requestParameters.financialYear !== undefined) {
            queryParameters['financial_year'] = requestParameters.financialYear;
        }

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/payments/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentFromJSON));
    }

    /**
     * 
     */
    async listPayments(requestParameters: ListPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Payment>> {
        const response = await this.listPaymentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listSocietiesRaw(requestParameters: ListSocietiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Society>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listSocieties.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/societies/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SocietyFromJSON));
    }

    /**
     * 
     */
    async listSocieties(requestParameters: ListSocietiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Society>> {
        const response = await this.listSocietiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listSocietyContactInformationsRaw(requestParameters: ListSocietyContactInformationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SocietyContactInformation>>> {
        if (requestParameters.societyId === null || requestParameters.societyId === undefined) {
            throw new runtime.RequiredError('societyId','Required parameter requestParameters.societyId was null or undefined when calling listSocietyContactInformations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/society/contact/{society_id}/`.replace(`{${"society_id"}}`, encodeURIComponent(String(requestParameters.societyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SocietyContactInformationFromJSON));
    }

    /**
     * 
     */
    async listSocietyContactInformations(requestParameters: ListSocietyContactInformationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SocietyContactInformation>> {
        const response = await this.listSocietyContactInformationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listSocietyFormRolesRaw(requestParameters: ListSocietyFormRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SocietyFormRole>>> {
        if (requestParameters.societyId === null || requestParameters.societyId === undefined) {
            throw new runtime.RequiredError('societyId','Required parameter requestParameters.societyId was null or undefined when calling listSocietyFormRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/society/form/{society_id}/roles/`.replace(`{${"society_id"}}`, encodeURIComponent(String(requestParameters.societyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SocietyFormRoleFromJSON));
    }

    /**
     * 
     */
    async listSocietyFormRoles(requestParameters: ListSocietyFormRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SocietyFormRole>> {
        const response = await this.listSocietyFormRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listSocietyFormsRaw(requestParameters: ListSocietyFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SocietyForm>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listSocietyForms.');
        }

        if (requestParameters.societyId === null || requestParameters.societyId === undefined) {
            throw new runtime.RequiredError('societyId','Required parameter requestParameters.societyId was null or undefined when calling listSocietyForms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/societies/{society_id}/forms/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"society_id"}}`, encodeURIComponent(String(requestParameters.societyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SocietyFormFromJSON));
    }

    /**
     * 
     */
    async listSocietyForms(requestParameters: ListSocietyFormsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SocietyForm>> {
        const response = await this.listSocietyFormsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listSocietyLoginsRaw(requestParameters: ListSocietyLoginsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SocietyLogins>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listSocietyLogins.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/society-logins/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SocietyLoginsFromJSON));
    }

    /**
     * 
     */
    async listSocietyLogins(requestParameters: ListSocietyLoginsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SocietyLogins>> {
        const response = await this.listSocietyLoginsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listSocietyPortalTasksRaw(requestParameters: ListSocietyPortalTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SocietyPortalTasks>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listSocietyPortalTasks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/tasks/portal/{user_id}/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SocietyPortalTasksFromJSON));
    }

    /**
     * 
     */
    async listSocietyPortalTasks(requestParameters: ListSocietyPortalTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SocietyPortalTasks>> {
        const response = await this.listSocietyPortalTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listTaxFormRolesRaw(requestParameters: ListTaxFormRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TaxFormRole>>> {
        if (requestParameters.societyId === null || requestParameters.societyId === undefined) {
            throw new runtime.RequiredError('societyId','Required parameter requestParameters.societyId was null or undefined when calling listTaxFormRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/society/tax/form/{society_id}/roles/`.replace(`{${"society_id"}}`, encodeURIComponent(String(requestParameters.societyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaxFormRoleFromJSON));
    }

    /**
     * 
     */
    async listTaxFormRoles(requestParameters: ListTaxFormRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TaxFormRole>> {
        const response = await this.listTaxFormRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async listUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AdminClientList>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminClientListFromJSON));
    }

    /**
     * 
     */
    async listUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AdminClientList>> {
        const response = await this.listUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async partialUpdateAdminPersonalInformationRaw(requestParameters: PartialUpdateAdminPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminPersonalInformation>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling partialUpdateAdminPersonalInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/personal-information/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AdminPersonalInformationToJSON(requestParameters.adminPersonalInformation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminPersonalInformationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async partialUpdateAdminPersonalInformation(requestParameters: PartialUpdateAdminPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminPersonalInformation> {
        const response = await this.partialUpdateAdminPersonalInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async partialUpdateUserSettingsRaw(requestParameters: PartialUpdateUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSettings>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling partialUpdateUserSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/settings/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserSettingsToJSON(requestParameters.userSettings),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSettingsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async partialUpdateUserSettings(requestParameters: PartialUpdateUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSettings> {
        const response = await this.partialUpdateUserSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveAdditionalContactDetailsRaw(requestParameters: RetrieveAdditionalContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdditionalContactDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveAdditionalContactDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/additional-contact-details/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdditionalContactDetailsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveAdditionalContactDetails(requestParameters: RetrieveAdditionalContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdditionalContactDetails> {
        const response = await this.retrieveAdditionalContactDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveAdminBankDetailsRaw(requestParameters: RetrieveAdminBankDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminBankDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveAdminBankDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/bank-details/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminBankDetailsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveAdminBankDetails(requestParameters: RetrieveAdminBankDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminBankDetails> {
        const response = await this.retrieveAdminBankDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveAdminInterestRegistrationRaw(requestParameters: RetrieveAdminInterestRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminInterestRegistration>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveAdminInterestRegistration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/interest-registration/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminInterestRegistrationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveAdminInterestRegistration(requestParameters: RetrieveAdminInterestRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminInterestRegistration> {
        const response = await this.retrieveAdminInterestRegistrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveAdminPersonalInformationRaw(requestParameters: RetrieveAdminPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminPersonalInformation>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveAdminPersonalInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/personal-information/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminPersonalInformationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveAdminPersonalInformation(requestParameters: RetrieveAdminPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminPersonalInformation> {
        const response = await this.retrieveAdminPersonalInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveAdminSocietyRaw(requestParameters: RetrieveAdminSocietyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminSociety>> {
        if (requestParameters.societyId === null || requestParameters.societyId === undefined) {
            throw new runtime.RequiredError('societyId','Required parameter requestParameters.societyId was null or undefined when calling retrieveAdminSociety.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/societies/{society_id}/`.replace(`{${"society_id"}}`, encodeURIComponent(String(requestParameters.societyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminSocietyFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveAdminSociety(requestParameters: RetrieveAdminSocietyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminSociety> {
        const response = await this.retrieveAdminSocietyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveArtistDetailsRaw(requestParameters: RetrieveArtistDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArtistDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveArtistDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/artist-details/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtistDetailsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveArtistDetails(requestParameters: RetrieveArtistDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArtistDetails> {
        const response = await this.retrieveArtistDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveByEmailHashAdminInterestRegistrationRaw(requestParameters: RetrieveByEmailHashAdminInterestRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminInterestRegistration>> {
        if (requestParameters.emailHash === null || requestParameters.emailHash === undefined) {
            throw new runtime.RequiredError('emailHash','Required parameter requestParameters.emailHash was null or undefined when calling retrieveByEmailHashAdminInterestRegistration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{email_hash}/interest-registration/`.replace(`{${"email_hash"}}`, encodeURIComponent(String(requestParameters.emailHash))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminInterestRegistrationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveByEmailHashAdminInterestRegistration(requestParameters: RetrieveByEmailHashAdminInterestRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminInterestRegistration> {
        const response = await this.retrieveByEmailHashAdminInterestRegistrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveByUsernameUserRaw(requestParameters: RetrieveByUsernameUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientUser>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling retrieveByUsernameUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{username}/`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientUserFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveByUsernameUser(requestParameters: RetrieveByUsernameUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientUser> {
        const response = await this.retrieveByUsernameUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveCompanyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Company>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/company/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveCompany(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Company> {
        const response = await this.retrieveCompanyRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveCompanyInformationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyInformation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/company_information/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyInformationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveCompanyInformation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyInformation> {
        const response = await this.retrieveCompanyInformationRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveDeleteDeclinedArtistRunStatusRaw(requestParameters: RetrieveDeleteDeclinedArtistRunStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteDeclinedArtistRunStatus>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveDeleteDeclinedArtistRunStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/dagster/delete-declined-artist/{user_id}/pipeline-runs/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteDeclinedArtistRunStatusFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveDeleteDeclinedArtistRunStatus(requestParameters: RetrieveDeleteDeclinedArtistRunStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteDeclinedArtistRunStatus> {
        const response = await this.retrieveDeleteDeclinedArtistRunStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveFinancialYearsRaw(requestParameters: RetrieveFinancialYearsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FinancialYears>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveFinancialYears.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/financial-years/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FinancialYearsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveFinancialYears(requestParameters: RetrieveFinancialYearsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FinancialYears> {
        const response = await this.retrieveFinancialYearsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveIdentityDocumentTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdentityDocumentTypes>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/identity-document-types/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityDocumentTypesFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveIdentityDocumentTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdentityDocumentTypes> {
        const response = await this.retrieveIdentityDocumentTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveLatestAdminIdentityDocumentSubmissionRaw(requestParameters: RetrieveLatestAdminIdentityDocumentSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminIdentityDocumentSubmission>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveLatestAdminIdentityDocumentSubmission.');
        }

        if (requestParameters.documentType === null || requestParameters.documentType === undefined) {
            throw new runtime.RequiredError('documentType','Required parameter requestParameters.documentType was null or undefined when calling retrieveLatestAdminIdentityDocumentSubmission.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentType !== undefined) {
            queryParameters['document_type'] = requestParameters.documentType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/identity-document-submissions/latest/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminIdentityDocumentSubmissionFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveLatestAdminIdentityDocumentSubmission(requestParameters: RetrieveLatestAdminIdentityDocumentSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminIdentityDocumentSubmission> {
        const response = await this.retrieveLatestAdminIdentityDocumentSubmissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveLatestPrimaryDiscographyExportRaw(requestParameters: RetrieveLatestPrimaryDiscographyExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrimaryDiscographyExport>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveLatestPrimaryDiscographyExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/primary-discography/exports/latest/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrimaryDiscographyExportFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveLatestPrimaryDiscographyExport(requestParameters: RetrieveLatestPrimaryDiscographyExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrimaryDiscographyExport> {
        const response = await this.retrieveLatestPrimaryDiscographyExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveOnboardingProgressRaw(requestParameters: RetrieveOnboardingProgressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OnboardingProgress>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveOnboardingProgress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/onboarding-progress/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingProgressFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveOnboardingProgress(requestParameters: RetrieveOnboardingProgressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OnboardingProgress> {
        const response = await this.retrieveOnboardingProgressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveOnboardingStepsRaw(requestParameters: RetrieveOnboardingStepsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OnboardingSteps>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveOnboardingSteps.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/onboarding-steps/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingStepsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveOnboardingSteps(requestParameters: RetrieveOnboardingStepsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OnboardingSteps> {
        const response = await this.retrieveOnboardingStepsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrievePandadocEgressRaw(requestParameters: RetrievePandadocEgressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PandadocEgress>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrievePandadocEgress.');
        }

        if (requestParameters.societyFormId === null || requestParameters.societyFormId === undefined) {
            throw new runtime.RequiredError('societyFormId','Required parameter requestParameters.societyFormId was null or undefined when calling retrievePandadocEgress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/pandadocs/{user_id}/egress/{society_form_id}/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"society_form_id"}}`, encodeURIComponent(String(requestParameters.societyFormId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PandadocEgressFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrievePandadocEgress(requestParameters: RetrievePandadocEgressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PandadocEgress> {
        const response = await this.retrievePandadocEgressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrievePipelineRunStatusRaw(requestParameters: RetrievePipelineRunStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineRunStatus>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrievePipelineRunStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/dagster/primary-discography/{user_id}/pipeline-runs/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineRunStatusFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrievePipelineRunStatus(requestParameters: RetrievePipelineRunStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineRunStatus> {
        const response = await this.retrievePipelineRunStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrievePrimaryDiscographyRaw(requestParameters: RetrievePrimaryDiscographyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrimaryDiscography>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrievePrimaryDiscography.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/primary-discography/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrimaryDiscographyFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrievePrimaryDiscography(requestParameters: RetrievePrimaryDiscographyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrimaryDiscography> {
        const response = await this.retrievePrimaryDiscographyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrievePrimaryDiscographyExportRaw(requestParameters: RetrievePrimaryDiscographyExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrimaryDiscographyExport>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrievePrimaryDiscographyExport.');
        }

        if (requestParameters.exportId === null || requestParameters.exportId === undefined) {
            throw new runtime.RequiredError('exportId','Required parameter requestParameters.exportId was null or undefined when calling retrievePrimaryDiscographyExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/primary-discography/exports/{export_id}/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"export_id"}}`, encodeURIComponent(String(requestParameters.exportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrimaryDiscographyExportFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrievePrimaryDiscographyExport(requestParameters: RetrievePrimaryDiscographyExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrimaryDiscographyExport> {
        const response = await this.retrievePrimaryDiscographyExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveRepresentativeRaw(requestParameters: RetrieveRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Representative>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveRepresentative.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/representative/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepresentativeFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveRepresentative(requestParameters: RetrieveRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Representative> {
        const response = await this.retrieveRepresentativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveStatementsJsonRaw(requestParameters: RetrieveStatementsJsonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatementsJson>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveStatementsJson.');
        }

        const queryParameters: any = {};

        if (requestParameters.paymentDay !== undefined) {
            queryParameters['payment_day'] = requestParameters.paymentDay;
        }

        if (requestParameters.paymentDays) {
            queryParameters['payment_days'] = requestParameters.paymentDays;
        }

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/statements/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatementsJsonFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveStatementsJson(requestParameters: RetrieveStatementsJsonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatementsJson> {
        const response = await this.retrieveStatementsJsonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveTermsOfAgreementRaw(requestParameters: RetrieveTermsOfAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TermsOfAgreement>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveTermsOfAgreement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/terms-of-agreement`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TermsOfAgreementFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveTermsOfAgreement(requestParameters: RetrieveTermsOfAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TermsOfAgreement> {
        const response = await this.retrieveTermsOfAgreementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveUserRaw(requestParameters: RetrieveUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientUser>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientUserFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveUser(requestParameters: RetrieveUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientUser> {
        const response = await this.retrieveUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async retrieveUserSettingsRaw(requestParameters: RetrieveUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSettings>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling retrieveUserSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/portal/users/{user_id}/settings/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSettingsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async retrieveUserSettings(requestParameters: RetrieveUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSettings> {
        const response = await this.retrieveUserSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateAdditionalContactDetailsRaw(requestParameters: UpdateAdditionalContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdditionalContactDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateAdditionalContactDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/additional-contact-details/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdditionalContactDetailsToJSON(requestParameters.additionalContactDetails),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdditionalContactDetailsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateAdditionalContactDetails(requestParameters: UpdateAdditionalContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdditionalContactDetails> {
        const response = await this.updateAdditionalContactDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateAdminBankDetailsRaw(requestParameters: UpdateAdminBankDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminBankDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateAdminBankDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/bank-details/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminBankDetailsToJSON(requestParameters.adminBankDetails),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminBankDetailsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateAdminBankDetails(requestParameters: UpdateAdminBankDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminBankDetails> {
        const response = await this.updateAdminBankDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateAdminIdentityDocumentSubmissionRaw(requestParameters: UpdateAdminIdentityDocumentSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminIdentityDocumentSubmission>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateAdminIdentityDocumentSubmission.');
        }

        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling updateAdminIdentityDocumentSubmission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('id', requestParameters.id as any);
        }

        if (requestParameters.dateCreated !== undefined) {
            formParams.append('date_created', requestParameters.dateCreated as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.filename !== undefined) {
            formParams.append('filename', requestParameters.filename as any);
        }

        if (requestParameters.status !== undefined) {
            formParams.append('status', requestParameters.status as any);
        }

        if (requestParameters.documentExpiryDate !== undefined) {
            formParams.append('document_expiry_date', requestParameters.documentExpiryDate as any);
        }

        if (requestParameters.documentSignedDate !== undefined) {
            formParams.append('document_signed_date', requestParameters.documentSignedDate as any);
        }

        if (requestParameters.documentType !== undefined) {
            formParams.append('document_type', requestParameters.documentType as any);
        }

        if (requestParameters.fileUrl !== undefined) {
            formParams.append('file_url', requestParameters.fileUrl as any);
        }

        const response = await this.request({
            path: `/portal/users/{user_id}/identity-document-submissions/{document_id}/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"document_id"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminIdentityDocumentSubmissionFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateAdminIdentityDocumentSubmission(requestParameters: UpdateAdminIdentityDocumentSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminIdentityDocumentSubmission> {
        const response = await this.updateAdminIdentityDocumentSubmissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateAdminInterestRegistrationRaw(requestParameters: UpdateAdminInterestRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminInterestRegistration>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateAdminInterestRegistration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/interest-registration/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminInterestRegistrationToJSON(requestParameters.adminInterestRegistration),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminInterestRegistrationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateAdminInterestRegistration(requestParameters: UpdateAdminInterestRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminInterestRegistration> {
        const response = await this.updateAdminInterestRegistrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateAdminPersonalInformationRaw(requestParameters: UpdateAdminPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminPersonalInformation>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateAdminPersonalInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/personal-information/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminPersonalInformationToJSON(requestParameters.adminPersonalInformation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminPersonalInformationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateAdminPersonalInformation(requestParameters: UpdateAdminPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminPersonalInformation> {
        const response = await this.updateAdminPersonalInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateAdminUpdateSocietyRaw(requestParameters: UpdateAdminUpdateSocietyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminUpdateSociety>> {
        if (requestParameters.societyId === null || requestParameters.societyId === undefined) {
            throw new runtime.RequiredError('societyId','Required parameter requestParameters.societyId was null or undefined when calling updateAdminUpdateSociety.');
        }

        if (requestParameters.readableName === null || requestParameters.readableName === undefined) {
            throw new runtime.RequiredError('readableName','Required parameter requestParameters.readableName was null or undefined when calling updateAdminUpdateSociety.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('id', requestParameters.id as any);
        }

        if (requestParameters.readableName !== undefined) {
            formParams.append('readable_name', requestParameters.readableName as any);
        }

        if (requestParameters.logo !== undefined) {
            formParams.append('logo', requestParameters.logo as any);
        }

        if (requestParameters.country !== undefined) {
            formParams.append('country', requestParameters.country as any);
        }

        if (requestParameters.website !== undefined) {
            formParams.append('website', requestParameters.website as any);
        }

        if (requestParameters.portal !== undefined) {
            formParams.append('portal', requestParameters.portal as any);
        }

        if (requestParameters.phone !== undefined) {
            formParams.append('phone', requestParameters.phone as any);
        }

        if (requestParameters.address !== undefined) {
            formParams.append('address', requestParameters.address as any);
        }

        if (requestParameters.additionalTerritories) {
            formParams.append('additional_territories', requestParameters.additionalTerritories.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters.formEmail !== undefined) {
            formParams.append('form_email', requestParameters.formEmail as any);
        }

        if (requestParameters.formPostalAddress !== undefined) {
            formParams.append('form_postal_address', requestParameters.formPostalAddress as any);
        }

        if (requestParameters.formPortal !== undefined) {
            formParams.append('form_portal', requestParameters.formPortal as any);
        }

        if (requestParameters.formReminderFrequency !== undefined) {
            formParams.append('form_reminder_frequency', requestParameters.formReminderFrequency as any);
        }

        if (requestParameters.formStopReminderFrequency !== undefined) {
            formParams.append('form_stop_reminder_frequency', requestParameters.formStopReminderFrequency as any);
        }

        if (requestParameters.formReminderSendTo !== undefined) {
            formParams.append('form_reminder_send_to', requestParameters.formReminderSendTo as any);
        }

        if (requestParameters.taxForms !== undefined) {
            formParams.append('tax_forms', requestParameters.taxForms as any);
        }

        if (requestParameters.roleForms !== undefined) {
            formParams.append('role_forms', requestParameters.roleForms as any);
        }

        if (requestParameters.contactInformation !== undefined) {
            formParams.append('contact_information', requestParameters.contactInformation as any);
        }

        const response = await this.request({
            path: `/portal/societies/{society_id}/`.replace(`{${"society_id"}}`, encodeURIComponent(String(requestParameters.societyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminUpdateSocietyFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateAdminUpdateSociety(requestParameters: UpdateAdminUpdateSocietyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminUpdateSociety> {
        const response = await this.updateAdminUpdateSocietyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateAdminUpdateSocietyCredentialsRaw(requestParameters: UpdateAdminUpdateSocietyCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminUpdateSocietyCredentials>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateAdminUpdateSocietyCredentials.');
        }

        if (requestParameters.societyId === null || requestParameters.societyId === undefined) {
            throw new runtime.RequiredError('societyId','Required parameter requestParameters.societyId was null or undefined when calling updateAdminUpdateSocietyCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/societies/{society_id}/login/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"society_id"}}`, encodeURIComponent(String(requestParameters.societyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminUpdateSocietyCredentialsToJSON(requestParameters.adminUpdateSocietyCredentials),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminUpdateSocietyCredentialsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateAdminUpdateSocietyCredentials(requestParameters: UpdateAdminUpdateSocietyCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminUpdateSocietyCredentials> {
        const response = await this.updateAdminUpdateSocietyCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateClientSocietyFormTaskRaw(requestParameters: UpdateClientSocietyFormTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientSocietyFormTask>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateClientSocietyFormTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/client_society_form_tasks`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientSocietyFormTaskToJSON(requestParameters.clientSocietyFormTask),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientSocietyFormTaskFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateClientSocietyFormTask(requestParameters: UpdateClientSocietyFormTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientSocietyFormTask> {
        const response = await this.updateClientSocietyFormTaskRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateCompanyRaw(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Company>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/company/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToJSON(requestParameters.company),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateCompany(requestParameters: UpdateCompanyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Company> {
        const response = await this.updateCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateCompanyFormRoleRaw(requestParameters: UpdateCompanyFormRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyFormRole>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/company/forms/roles/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyFormRoleToJSON(requestParameters.companyFormRole),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFormRoleFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateCompanyFormRole(requestParameters: UpdateCompanyFormRoleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyFormRole> {
        const response = await this.updateCompanyFormRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateCompanyInformationRaw(requestParameters: UpdateCompanyInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyInformation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/company_information/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyInformationToJSON(requestParameters.companyInformation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyInformationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateCompanyInformation(requestParameters: UpdateCompanyInformationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyInformation> {
        const response = await this.updateCompanyInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateLogoImageCompanyInformationLogoImageRaw(requestParameters: UpdateLogoImageCompanyInformationLogoImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyInformationLogoImage>> {
        if (requestParameters.logoImage === null || requestParameters.logoImage === undefined) {
            throw new runtime.RequiredError('logoImage','Required parameter requestParameters.logoImage was null or undefined when calling updateLogoImageCompanyInformationLogoImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.logoImage !== undefined) {
            formParams.append('logo_image', requestParameters.logoImage as any);
        }

        if (requestParameters.logoImageUrl !== undefined) {
            formParams.append('logo_image_url', requestParameters.logoImageUrl as any);
        }

        const response = await this.request({
            path: `/portal/company_information/logo_image/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyInformationLogoImageFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateLogoImageCompanyInformationLogoImage(requestParameters: UpdateLogoImageCompanyInformationLogoImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyInformationLogoImage> {
        const response = await this.updateLogoImageCompanyInformationLogoImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updatePandadocEgressRaw(requestParameters: UpdatePandadocEgressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PandadocEgress>> {
        if (requestParameters.societyFormId === null || requestParameters.societyFormId === undefined) {
            throw new runtime.RequiredError('societyFormId','Required parameter requestParameters.societyFormId was null or undefined when calling updatePandadocEgress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/pandadocs/str:user_id>/ingress/{society_form_id}/`.replace(`{${"society_form_id"}}`, encodeURIComponent(String(requestParameters.societyFormId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PandadocEgressFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updatePandadocEgress(requestParameters: UpdatePandadocEgressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PandadocEgress> {
        const response = await this.updatePandadocEgressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updatePhotoAdminPersonalInformationRaw(requestParameters: UpdatePhotoAdminPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminPersonalInformation>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updatePhotoAdminPersonalInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.user !== undefined) {
            formParams.append('user', requestParameters.user as any);
        }

        if (requestParameters.url !== undefined) {
            formParams.append('url', requestParameters.url as any);
        }

        if (requestParameters.oldPassword !== undefined) {
            formParams.append('old_password', requestParameters.oldPassword as any);
        }

        if (requestParameters.password !== undefined) {
            formParams.append('password', requestParameters.password as any);
        }

        if (requestParameters.role !== undefined) {
            formParams.append('role', requestParameters.role as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }

        if (requestParameters.gender !== undefined) {
            formParams.append('gender', requestParameters.gender as any);
        }

        if (requestParameters.cityOfBirth !== undefined) {
            formParams.append('city_of_birth', requestParameters.cityOfBirth as any);
        }

        if (requestParameters.countryOfBirth !== undefined) {
            formParams.append('country_of_birth', requestParameters.countryOfBirth as any);
        }

        if (requestParameters.fullName !== undefined) {
            formParams.append('full_name', requestParameters.fullName as any);
        }

        if (requestParameters.firstName !== undefined) {
            formParams.append('first_name', requestParameters.firstName as any);
        }

        if (requestParameters.middleName !== undefined) {
            formParams.append('middle_name', requestParameters.middleName as any);
        }

        if (requestParameters.lastName !== undefined) {
            formParams.append('last_name', requestParameters.lastName as any);
        }

        if (requestParameters.department !== undefined) {
            formParams.append('department', requestParameters.department as any);
        }

        if (requestParameters.departmentChoices) {
            formParams.append('department_choices', requestParameters.departmentChoices.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters.userPhoto !== undefined) {
            formParams.append('user_photo', requestParameters.userPhoto as any);
        }

        if (requestParameters.userPhotoUrl !== undefined) {
            formParams.append('user_photo_url', requestParameters.userPhotoUrl as any);
        }

        if (requestParameters.dateOfBirth !== undefined) {
            formParams.append('date_of_birth', requestParameters.dateOfBirth as any);
        }

        if (requestParameters.aliases) {
            formParams.append('aliases', requestParameters.aliases.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters.nationality !== undefined) {
            formParams.append('nationality', requestParameters.nationality as any);
        }

        if (requestParameters.taxationCountry !== undefined) {
            formParams.append('taxation_country', requestParameters.taxationCountry as any);
        }

        if (requestParameters.vatNumber !== undefined) {
            formParams.append('vat_number', requestParameters.vatNumber as any);
        }

        if (requestParameters.taxIdType !== undefined) {
            formParams.append('tax_id_type', requestParameters.taxIdType as any);
        }

        if (requestParameters.taxIdNumber !== undefined) {
            formParams.append('tax_id_number', requestParameters.taxIdNumber as any);
        }

        if (requestParameters.emailAddress !== undefined) {
            formParams.append('email_address', requestParameters.emailAddress as any);
        }

        if (requestParameters.streetAddress !== undefined) {
            formParams.append('street_address', requestParameters.streetAddress as any);
        }

        if (requestParameters.country !== undefined) {
            formParams.append('country', requestParameters.country as any);
        }

        if (requestParameters.phoneNumber !== undefined) {
            formParams.append('phone_number', requestParameters.phoneNumber as any);
        }

        if (requestParameters.county !== undefined) {
            formParams.append('county', requestParameters.county as any);
        }

        if (requestParameters.postcode !== undefined) {
            formParams.append('postcode', requestParameters.postcode as any);
        }

        if (requestParameters.westburyId !== undefined) {
            formParams.append('westbury_id', requestParameters.westburyId as any);
        }

        const response = await this.request({
            path: `/portal/users/{user_id}/personal-information/photo/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminPersonalInformationFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updatePhotoAdminPersonalInformation(requestParameters: UpdatePhotoAdminPersonalInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminPersonalInformation> {
        const response = await this.updatePhotoAdminPersonalInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateRepresentativeRaw(requestParameters: UpdateRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Representative>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateRepresentative.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/representative/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RepresentativeToJSON(requestParameters.representative),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepresentativeFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateRepresentative(requestParameters: UpdateRepresentativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Representative> {
        const response = await this.updateRepresentativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateSignupAuthTokenRaw(requestParameters: UpdateSignupAuthTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignupAuthToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/signup/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SignupToJSON(requestParameters.signup),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignupAuthTokenFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateSignupAuthToken(requestParameters: UpdateSignupAuthTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignupAuthToken> {
        const response = await this.updateSignupAuthTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateTermsOfAgreementRaw(requestParameters: UpdateTermsOfAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TermsOfAgreement>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateTermsOfAgreement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/terms-of-agreement`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TermsOfAgreementToJSON(requestParameters.termsOfAgreement),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TermsOfAgreementFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateTermsOfAgreement(requestParameters: UpdateTermsOfAgreementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TermsOfAgreement> {
        const response = await this.updateTermsOfAgreementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update secondary discography run date_sent_to_society
     */
    async updateUpdateSecondaryDiscographyRaw(requestParameters: UpdateUpdateSecondaryDiscographyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateSecondaryDiscography>> {
        if (requestParameters.exportId === null || requestParameters.exportId === undefined) {
            throw new runtime.RequiredError('exportId','Required parameter requestParameters.exportId was null or undefined when calling updateUpdateSecondaryDiscography.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/secondary-discography/exports/{export_id}/`.replace(`{${"export_id"}}`, encodeURIComponent(String(requestParameters.exportId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSecondaryDiscographyToJSON(requestParameters.updateSecondaryDiscography),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateSecondaryDiscographyFromJSON(jsonValue));
    }

    /**
     * Update secondary discography run date_sent_to_society
     */
    async updateUpdateSecondaryDiscography(requestParameters: UpdateUpdateSecondaryDiscographyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateSecondaryDiscography> {
        const response = await this.updateUpdateSecondaryDiscographyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateUserSettingsRaw(requestParameters: UpdateUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSettings>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUserSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/portal/users/{user_id}/settings/`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserSettingsToJSON(requestParameters.userSettings),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSettingsFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateUserSettings(requestParameters: UpdateUserSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSettings> {
        const response = await this.updateUserSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const AdminUploadUploadMembershipFormsStatusEnum = {
    ClientToSign: 'client_to_sign',
    WithClient: 'with_client',
    AdminToSign: 'admin_to_sign',
    AwaitingUpload: 'awaiting_upload',
    Approved: 'approved'
} as const;
export type AdminUploadUploadMembershipFormsStatusEnum = typeof AdminUploadUploadMembershipFormsStatusEnum[keyof typeof AdminUploadUploadMembershipFormsStatusEnum];
/**
 * @export
 */
export const CreateAdminCreateSocietyFormReminderFrequencyEnum = {
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly'
} as const;
export type CreateAdminCreateSocietyFormReminderFrequencyEnum = typeof CreateAdminCreateSocietyFormReminderFrequencyEnum[keyof typeof CreateAdminCreateSocietyFormReminderFrequencyEnum];
/**
 * @export
 */
export const CreateAdminCreateSocietyFormStopReminderFrequencyEnum = {
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly'
} as const;
export type CreateAdminCreateSocietyFormStopReminderFrequencyEnum = typeof CreateAdminCreateSocietyFormStopReminderFrequencyEnum[keyof typeof CreateAdminCreateSocietyFormStopReminderFrequencyEnum];
/**
 * @export
 */
export const CreateAdminIdentityDocumentSubmissionStatusEnum = {
    PendingDecision: 'pending_decision',
    Invalid: 'invalid',
    Accepted: 'accepted'
} as const;
export type CreateAdminIdentityDocumentSubmissionStatusEnum = typeof CreateAdminIdentityDocumentSubmissionStatusEnum[keyof typeof CreateAdminIdentityDocumentSubmissionStatusEnum];
/**
 * @export
 */
export const CreateAdminIdentityDocumentSubmissionDocumentTypeEnum = {
    Passport: 'Passport',
    CurrentAgreement: 'Current_Agreement',
    TerminationConfirmation: 'Termination_Confirmation',
    ProofOfAddress: 'Proof_Of_Address',
    PowerOfAttorney: 'Power_Of_Attorney',
    TermsOfAgreement: 'Terms_Of_Agreement'
} as const;
export type CreateAdminIdentityDocumentSubmissionDocumentTypeEnum = typeof CreateAdminIdentityDocumentSubmissionDocumentTypeEnum[keyof typeof CreateAdminIdentityDocumentSubmissionDocumentTypeEnum];
/**
 * @export
 */
export const UpdateAdminIdentityDocumentSubmissionStatusEnum = {
    PendingDecision: 'pending_decision',
    Invalid: 'invalid',
    Accepted: 'accepted'
} as const;
export type UpdateAdminIdentityDocumentSubmissionStatusEnum = typeof UpdateAdminIdentityDocumentSubmissionStatusEnum[keyof typeof UpdateAdminIdentityDocumentSubmissionStatusEnum];
/**
 * @export
 */
export const UpdateAdminIdentityDocumentSubmissionDocumentTypeEnum = {
    Passport: 'Passport',
    CurrentAgreement: 'Current_Agreement',
    TerminationConfirmation: 'Termination_Confirmation',
    ProofOfAddress: 'Proof_Of_Address',
    PowerOfAttorney: 'Power_Of_Attorney',
    TermsOfAgreement: 'Terms_Of_Agreement'
} as const;
export type UpdateAdminIdentityDocumentSubmissionDocumentTypeEnum = typeof UpdateAdminIdentityDocumentSubmissionDocumentTypeEnum[keyof typeof UpdateAdminIdentityDocumentSubmissionDocumentTypeEnum];
/**
 * @export
 */
export const UpdateAdminUpdateSocietyFormReminderFrequencyEnum = {
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly'
} as const;
export type UpdateAdminUpdateSocietyFormReminderFrequencyEnum = typeof UpdateAdminUpdateSocietyFormReminderFrequencyEnum[keyof typeof UpdateAdminUpdateSocietyFormReminderFrequencyEnum];
/**
 * @export
 */
export const UpdateAdminUpdateSocietyFormStopReminderFrequencyEnum = {
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly'
} as const;
export type UpdateAdminUpdateSocietyFormStopReminderFrequencyEnum = typeof UpdateAdminUpdateSocietyFormStopReminderFrequencyEnum[keyof typeof UpdateAdminUpdateSocietyFormStopReminderFrequencyEnum];
/**
 * @export
 */
export const UpdatePhotoAdminPersonalInformationRoleEnum = {
    PerformerProducer: 'Performer/Producer'
} as const;
export type UpdatePhotoAdminPersonalInformationRoleEnum = typeof UpdatePhotoAdminPersonalInformationRoleEnum[keyof typeof UpdatePhotoAdminPersonalInformationRoleEnum];
/**
 * @export
 */
export const UpdatePhotoAdminPersonalInformationDepartmentEnum = {
    Membership: 'Membership',
    Discography: 'Discography',
    Distribution: 'Distribution'
} as const;
export type UpdatePhotoAdminPersonalInformationDepartmentEnum = typeof UpdatePhotoAdminPersonalInformationDepartmentEnum[keyof typeof UpdatePhotoAdminPersonalInformationDepartmentEnum];
/**
 * @export
 */
export const UpdatePhotoAdminPersonalInformationTaxIdTypeEnum = {
    TinTaxpayerIdentificationNumber: 'TIN - Taxpayer Identification Number',
    ItinIndividualTaxpayerIdentificationNumber: 'ITIN - Individual Taxpayer Identification Number',
    FtinForeignTaxpayerIdentificationNumber: 'FTIN - Foreign Taxpayer Identification Number',
    PibPoreskiIdentifikacioniBroj: 'PIB - Poreski Identifikacioni Broj',
    PpsPersonalPublicService: 'PPS - Personal Public Service',
    NipNumerIdentyfikacjiPodatkowej: 'NIP - Numer Identyfikacji Podatkowej',
    FederalTaxId: 'Federal Tax ID',
    DepartmentOfStateId: 'Department of State ID',
    NationalInsuranceNumber: 'National Insurance Number',
    SocialSecurityNumber: 'Social Security Number'
} as const;
export type UpdatePhotoAdminPersonalInformationTaxIdTypeEnum = typeof UpdatePhotoAdminPersonalInformationTaxIdTypeEnum[keyof typeof UpdatePhotoAdminPersonalInformationTaxIdTypeEnum];
