import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, FormControl, Modal } from 'react-bootstrap';
import Countries from './Forms/Countries.json';
import Nationalities from './Forms/Nationalities.json';
import thumbImage from 'shared_components/public/assets/ThumbsUp.svg';
import thumbDown from 'shared_components/public/assets/ThumbsDown.svg';
import FormSelect from './Forms/FormSelect';
import { useApi } from 'shared_components/context';
import { useUserId } from 'shared_components/context/user';
import {
  ClientBankDetails,
  ClientPersonalInformation,
  ClientPersonalInformationTaxIdTypeEnum,
} from 'shared_components/generated/client';

interface Props {
  showClientInfo: () => void;
}

function ClientPersonalInfo({ showClientInfo }: Props) {
  const { clientApi: api } = useApi();
  const { userId } = useUserId();
  const [show, setShow] = useState(true);
  const [piForm, setPiForm] = useState(true);
  const [srForm, setSrForm] = useState(false);
  const [payForm, setPayForm] = useState(false);
  const [societiesList, setSocietiesList] = useState<File[]>([]);
  const [registerSociety, setRegisterSociety] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [piSubmitError, setPiSubmitError] = useState('');
  const [riSubmitError, setRiSubmitError] = useState(false);
  const [formValue, setFormValue] = useState({
    title: '',
    gender: '',
    city_of_birth: '',
    country_of_birth: '',
    full_name: '',
    date_of_birth: '',
    aliases: '',
    nationality: '',
    taxation_country: '',
    vat_number: '',
    tax_id_type: '',
    tax_id_number: '',
    email_address: '',
    phone_number: '',
    street_address: '',
    county: '',
    country: '',
    postcode: '',
    society: '',
    file: societiesList,
    bank_account_name: '',
    bank_name: '',
    bank_sort_code: '',
    bank_iban: '',
    bank_account_holding_branch: '',
    bank_account_number: '',
    bank_address: '',
    bank_routing_code: '',
    bank_bic_swift_code: '',
  });
  const [formValidations, setFormValidations] = useState({
    title: '',
    gender: '',
    city_of_birth: '',
    country_of_birth: '',
    full_name: '',
    date_of_birth: '',
    taxation_country: '',
    email_address: '',
    street_address: '',
    county: '',
    country: '',
    postcode: '',
    emailValid: '',
    society: '',
    file: '',
    bank_account_name: '',
    bank_name: '',
    bank_sort_code: '',
    bank_iban: '',
    bank_account_number: '',
    bank_address: '',
    bank_bic_swift_code: '',
    bank_account_holding_branch: '',
  });

  const EmailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const inputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setFormValidations({
        ...formValidations,
        [event.target.name]: '',
      });
    }
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const blurHandle = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.dataset.required && event.target.value == '') {
      setFormValidations({
        ...formValidations,
        [event.target.name]: 'This field is Required',
      });
    } else {
      if (
        event.target.type == 'email' &&
        EmailRegex.test(event.target.value) === false
      ) {
        setFormValidations({
          ...formValidations,
          emailValid: 'Please enter a valid email',
        });
        return;
      } else {
        setFormValidations({
          ...formValidations,
          emailValid: '',
        });
      }
      setFormValidations({
        ...formValidations,
        [event.target.name]: '',
        emailValid: '',
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [userId]);

  const fetchData = async () => {
    if (userId === undefined) {
      return;
    }
    api
      .retrieveClientPersonalInformation({ userId })
      .then((personalInfo) => {
        setFormValue((prevFormValue) => {
          return {
            ...prevFormValue,
            ...{
              title: personalInfo.title ?? '',
              gender: personalInfo.gender ?? '',
              city_of_birth: personalInfo.cityOfBirth ?? '',
              country_of_birth: personalInfo.countryOfBirth ?? '',
              full_name: personalInfo.fullName ?? '',
              date_of_birth: personalInfo.dateOfBirth
                ? moment(personalInfo.dateOfBirth).format('YYYY-MM-DD')
                : '',
              aliases: (personalInfo.aliases ?? []).join(','),
              nationality: personalInfo.nationality ?? '',
              taxation_country: personalInfo.taxationCountry ?? '',
              vat_number: personalInfo.vatNumber ?? '',
              tax_id_type: personalInfo.taxIdType ?? '',
              tax_id_number: personalInfo.taxIdNumber ?? '',
              email_address: personalInfo.emailAddress ?? '',
              phone_number: personalInfo.phoneNumber ?? '',
              street_address: personalInfo.streetAddress ?? '',
              county: personalInfo.county ?? '',
              country: personalInfo.country ?? '',
              postcode: personalInfo.postcode ?? '',
            },
          };
        });
      })
      .then(() => {});

    api
      .retrieveClientBankDetails({ userId })
      .then((bankDetails) => {
        setFormValue((prevFormValue) => {
          return {
            ...prevFormValue,
            ...{
              bank_account_name: bankDetails.bankAccountName ?? '',
              bank_name: bankDetails.bankName ?? '',
              bank_sort_code: bankDetails.bankSortCode ?? '',
              bank_iban: bankDetails.bankIban ?? '',
              bank_account_holding_branch:
                bankDetails.bankAccountHoldingBranch ?? '',
              bank_account_number: bankDetails.bankAccountNumber ?? '',
              bank_address: bankDetails.bankAddress ?? '',
              bank_routing_code: bankDetails.bankRoutingCode ?? '',
              bank_bic_swift_code: bankDetails.bankBicSwiftCode ?? '',
            },
          };
        });
      })
      .catch(() => {});
  };

  const moveToSecond = (event: React.MouseEvent) => {
    event.preventDefault();

    if (userId === undefined) {
      return;
    }

    if (
      formValue.title &&
      formValue.gender &&
      formValue.city_of_birth &&
      formValue.country_of_birth &&
      formValue.full_name &&
      formValue.date_of_birth &&
      formValue.taxation_country &&
      formValue.email_address &&
      formValue.street_address &&
      formValue.country &&
      formValue.county &&
      formValue.postcode
    ) {
      const formDataValue: ClientPersonalInformation = {
        title: formValue.title ? formValue.title : '',
        gender: formValue.gender ? formValue.gender : '',
        cityOfBirth: formValue.city_of_birth,
        countryOfBirth: formValue.country_of_birth,
        fullName: formValue.full_name,
        dateOfBirth: new Date(formValue.date_of_birth),
        aliases: formValue.aliases.split(',').filter(Boolean),
        nationality: formValue.nationality ? formValue.nationality : '',
        taxationCountry: formValue.taxation_country,
        vatNumber: formValue.vat_number,
        taxIdType: formValue.tax_id_type,
        taxIdNumber: formValue.tax_id_number,
        emailAddress: formValue.email_address,
        phoneNumber: formValue.phone_number,
        streetAddress: formValue.street_address,
        county: formValue.county,
        country: formValue.country,
        postcode: formValue.postcode,
      };

      api
        .updateClientPersonalInformation({
          userId,
          clientPersonalInformation: formDataValue,
        })
        .then(() => {
          setPiForm(false);
          setSrForm(true);
          setPayForm(false);
        })
        .catch(() => {
          setPiSubmitError(
            'There was an error while saving your personal information. Please try again and before that please make sure all the fields have valid information.'
          );
        });
    } else {
      setFormValidations({
        ...formValidations,
        title: formValue.title ? '' : 'This field is Required',
        gender: formValue.gender ? '' : 'This field is Required',
        city_of_birth: formValue.city_of_birth ? '' : 'This field is Required',
        country_of_birth: formValue.country_of_birth
          ? ''
          : 'This field is Required',
        full_name: formValue.full_name ? '' : 'This field is Required',
        date_of_birth: formValue.date_of_birth ? '' : 'This field is Required',
        taxation_country: formValue.taxation_country
          ? ''
          : 'This field is Required',
        email_address: formValue.email_address ? '' : 'This field is Required',
        street_address: formValue.street_address
          ? ''
          : 'This field is Required',
        country: formValue.country ? '' : 'This field is Required',
        county: formValue.county ? '' : 'This field is Required',
        postcode: formValue.postcode ? '' : 'This field is Required',
      });
      return;
    }
  };

  const moveToThird = (event: React.MouseEvent) => {
    if (userId === undefined) {
      return;
    }

    event.preventDefault();

    if (registerSociety) {
      if (registerSociety === 'Yes') {
        societiesList.map((x, i) => {
          if (x.name) {
            setFormValidations({
              ...formValidations,
              file: '',
            });
          } else {
            setFormValidations({
              ...formValidations,
              file: 'Please make sure that all field has valid file included',
            });
            return;
          }
        });

        if (!formValidations.file) {
          /**
           * @todo Figure out what this does – what is society field for?
           */
          Promise.all(
            societiesList.map((file) => {
              return api.createPreregisteredSocietyPostId({
                userId,
                preregisteredSocietyPost: {
                  file,
                  society: 'Yes',
                },
              });
            })
          )
            .then(() => {
              setPiForm(false);
              setSrForm(false);
              setPayForm(true);
            })
            .catch((error) => {
              setSubmitError(error.message);
            });
        }
      } else {
        setPiForm(false);
        setSrForm(false);
        setPayForm(true);
      }
    } else {
      setFormValidations({
        ...formValidations,
        society: formValue.society ? '' : 'This field is Required',
      });
    }
  };

  const saveDetails = (event: React.FormEvent) => {
    event.preventDefault();

    if (userId === undefined) {
      return;
    }

    if (
      formValue.bank_name &&
      formValue.bank_account_number &&
      formValue.bank_account_name &&
      formValue.bank_sort_code &&
      formValue.bank_address &&
      formValue.bank_iban
    ) {
      const bankData: ClientBankDetails = {
        bankAccountName: formValue.bank_account_name,
        bankName: formValue.bank_name,
        bankSortCode: formValue.bank_sort_code,
        bankIban: formValue.bank_iban,
        bankAccountHoldingBranch: formValue.bank_account_holding_branch,
        bankAccountNumber: formValue.bank_account_number,
        bankAddress: formValue.bank_address,
        bankRoutingCode: formValue.bank_routing_code,
        bankBicSwiftCode: formValue.bank_bic_swift_code,
      };

      api
        .createClientBankDetails({ userId, clientBankDetails: bankData })
        .then(() => {
          setFormSubmitted(true);
          setTimeout(() => {
            showClientInfo();
          }, 1200);
        })
        .catch(() => {});
    } else {
      setFormValidations({
        ...formValidations,
        bank_name: formValue.bank_name ? '' : 'This field is Required',
        bank_account_number: formValue.bank_account_number
          ? ''
          : 'This field is Required',
        bank_account_name: formValue.bank_account_name
          ? ''
          : 'This field is Required',
        bank_sort_code: formValue.bank_sort_code
          ? ''
          : 'This field is Required',
        bank_address: formValue.bank_address ? '' : 'This field is Required',
        bank_iban: formValue.bank_iban ? '' : 'This field is Required',
      });
      return;
    }
  };

  const moveBackToFirst = (event: React.MouseEvent) => {
    event.preventDefault();
    setPiForm(true);
    setSrForm(false);
    setPayForm(false);
  };

  const moveBackToSecond = (event: React.MouseEvent) => {
    event.preventDefault();
    setPiForm(false);
    setSrForm(true);
    setPayForm(false);
  };

  // handle input change
  const handleScChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (!e.target.files) {
      return;
    }
    const list = [...societiesList];
    list[index] = e.target.files[0];
    setSocietiesList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index: number) => {
    const list = [...societiesList];
    list.splice(index, 1);
    setSocietiesList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    // setSocietiesList([...societiesList, " "]);
  };

  const resetData = (event: React.MouseEvent) => {
    event.preventDefault();
    setFormSubmitted(false);
    setSubmitError(false);
    showClientInfo();
  };

  const taxIdTypeOptions = Object.values(
    ClientPersonalInformationTaxIdTypeEnum
  ).map((taxIdType) => ({
    label: taxIdType,
    value: taxIdType,
  }));

  return (
    <div className="clientPersonalInfoBlock">
      <div className="piContainer">
        <div className="piBack d-flex">
          {piForm ? (
            <a className="back-button" onClick={showClientInfo}>
              Back to Dashboard
            </a>
          ) : (
            ''
          )}
          {srForm ? (
            <a className="back-button" onClick={moveBackToFirst}>
              Back
            </a>
          ) : (
            ''
          )}
          {payForm ? (
            <a className="back-button" onClick={moveBackToSecond}>
              Back
            </a>
          ) : (
            ''
          )}
        </div>
        {submitError || formSubmitted ? (
          <Modal
            show={show}
            onHide={() => setShow(false)}
            className="verificationModal"
          >
            {submitError ? (
              <Button className="ModalClose" onClick={() => setShow(false)}>
                close
              </Button>
            ) : (
              ''
            )}
            {formSubmitted ? (
              <Button className="ModalClose" onClick={showClientInfo}>
                close
              </Button>
            ) : (
              ''
            )}
            <Modal.Body>
              {submitError ? (
                <div className="thankyouBlock">
                  <div className="thImage">
                    <img
                      src={thumbDown}
                      alt="Thumbs Down"
                      width="180"
                      className="d-block m-auto"
                    />
                  </div>
                  <h3 className="mt-4 pt-1 mb-3 text-center">Sorry!</h3>
                  <p className="text-center">
                    There is an error while submitting your details! <br />{' '}
                    Please try again.
                  </p>
                  <Col xs={12} className="d-flex justify-content-center">
                    <Button
                      variant="primary"
                      type="button"
                      className="x-button px-4 mt-5 ml-auto"
                      onClick={resetData}
                    >
                      Ok
                    </Button>
                  </Col>
                </div>
              ) : (
                ''
              )}
              {formSubmitted ? (
                <div className="thankyouBlock">
                  <div className="thImage">
                    <img
                      src={thumbImage}
                      alt="Thumbs Up"
                      width="180"
                      className="d-block m-auto"
                    />
                  </div>
                  <h3 className="mt-4 pt-1 mb-3 text-center">Thank you</h3>
                  <p className="text-center">
                    Your information has been updated in your profile
                  </p>
                </div>
              ) : (
                ''
              )}
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
        <div className="piFormContainer">
          <div className="piContainerInner">
            <div className="boxColumn">
              <div className="profileProgressSteps d-flex px-25">
                <div
                  className={`progressStep   ${piForm ? 'active' : ''} ${
                    srForm || payForm ? 'Finished' : ''
                  } `}
                >
                  <div className="stepInner">
                    <span className="stepCount d-block">1</span>
                    <span className="stepName d-block">
                      Personal information
                    </span>
                  </div>
                </div>
                <div
                  className={`progressStep   ${srForm ? 'active' : ''} ${
                    payForm ? 'Finished' : ''
                  } `}
                >
                  <div className="stepInner">
                    <span className="stepCount d-block">2</span>
                    <span className="stepName d-block">
                      Society registrations
                    </span>
                  </div>
                </div>
                <div
                  className={`progressStep   ${payForm ? 'active' : ''} ${
                    formSubmitted ? 'Finished' : ''
                  } `}
                >
                  <div className="stepInner">
                    <span className="stepCount d-block">3</span>
                    <span className="stepName d-block">Bank details</span>
                  </div>
                </div>
              </div>
              <div className="px-55">
                {piForm ? (
                  <div className="piFormStep">
                    <h4>Personal information</h4>
                    <Row>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.title ? 'fieldInvalid' : ''
                          }`}
                        >
                          <label>Title</label>
                          <FormSelect
                            className={` ${
                              formValue.title ? 'roleSelected' : ''
                            }`}
                            id="title"
                            name="title"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={formValue.title ? formValue.title : ''}
                          >
                            <option value="">Select </option>
                            <option value="Mrs">Mrs</option>
                            <option value="Mr">Mr</option>
                            <option value="Miss">Miss</option>
                            <option value="Ms">Ms</option>
                          </FormSelect>
                          {formValidations.title ? (
                            <div className="fieldError">
                              {formValidations.title}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.gender ? 'fieldInvalid' : ''
                          }`}
                        >
                          <label>Gender</label>
                          <FormSelect
                            className={` ${
                              formValue.gender ? 'roleSelected' : ''
                            }`}
                            id="gender"
                            name="gender"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={formValue.gender ? formValue.gender : ''}
                          >
                            <option value="">Select</option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                            <option value="Other">Other</option>
                            <option value="Prefer not to say">
                              Prefer not to say
                            </option>
                          </FormSelect>
                          {formValidations.gender ? (
                            <div className="fieldError">
                              {formValidations.gender}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.full_name ? 'fieldInvalid' : ''
                          }`}
                        >
                          <label>Full name</label>
                          <FormControl
                            placeholder="Enter your full name"
                            name="full_name"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={
                              formValue.full_name ? formValue.full_name : ''
                            }
                            data-required="true"
                          />
                          {formValidations.full_name ? (
                            <div className="fieldError">
                              {formValidations.full_name}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div className={`mb-3 d-block fonmGroupControl`}>
                          <label>Aliases</label>
                          <FormControl
                            placeholder="Enter your aliases"
                            name="aliases"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={formValue.aliases ? formValue.aliases : ''}
                          />
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.date_of_birth ? 'fieldInvalid' : ''
                          }`}
                        >
                          <label>Date of birth</label>
                          <FormControl
                            placeholder="Enter your date of birth"
                            type="date"
                            name="date_of_birth"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={formValue.date_of_birth}
                            data-required="true"
                          />
                          {formValidations.date_of_birth ? (
                            <div className="fieldError">
                              {formValidations.date_of_birth}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div className={`mb-3 d-block fonmGroupControl`}>
                          <label>Nationality</label>
                          <FormSelect
                            placeholder="Enter your Nationality"
                            name="nationality"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={
                              formValue.nationality ? formValue.nationality : ''
                            }
                          >
                            <option>Select</option>
                            {Nationalities.map((nationality) => (
                              <option key={nationality}>{nationality}</option>
                            ))}
                          </FormSelect>
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.city_of_birth ? 'fieldInvalid' : ''
                          }`}
                        >
                          <label>City of birth</label>
                          <FormControl
                            placeholder="Enter your city of birth"
                            name="city_of_birth"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={
                              formValue.city_of_birth
                                ? formValue.city_of_birth
                                : ''
                            }
                            data-required="true"
                          />
                          {formValidations.city_of_birth ? (
                            <div className="fieldError">
                              {formValidations.city_of_birth}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.country_of_birth
                              ? 'fieldInvalid'
                              : ''
                          }`}
                        >
                          <label>Country of birth</label>
                          <FormSelect
                            className={` ${
                              formValue.country_of_birth ? 'roleSelected' : ''
                            }`}
                            id="country_of_birth"
                            name="country_of_birth"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={
                              formValue.country_of_birth
                                ? formValue.country_of_birth
                                : ''
                            }
                          >
                            <option>Select</option>
                            {Countries.map((country) => (
                              <option key={country}>{country}</option>
                            ))}
                          </FormSelect>
                          {formValidations.country_of_birth ? (
                            <div className="fieldError">
                              {formValidations.country_of_birth}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                    </Row>
                    <h4>Tax information</h4>
                    <Row>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.taxation_country
                              ? 'fieldInvalid'
                              : ''
                          }`}
                        >
                          <label>
                            Country of taxation{' '}
                            <small>(Enter the country you pay taxes in)</small>
                          </label>
                          <div
                            className={` ${
                              formValidations.taxation_country
                                ? 'fieldInvalid'
                                : ''
                            }`}
                          >
                            <FormSelect
                              name="taxation_country"
                              onChange={inputChange}
                              onBlur={blurHandle}
                              value={
                                formValue.taxation_country
                                  ? formValue.taxation_country
                                  : ''
                              }
                              isrequired="true"
                            >
                              <option value="">Select</option>
                              {Countries.map((country) => (
                                <option value={country} key={country}>
                                  {country}
                                </option>
                              ))}
                            </FormSelect>
                            {formValidations.taxation_country ? (
                              <div className="fieldError">
                                {formValidations.taxation_country}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div className={`mb-3 d-block fonmGroupControl`}>
                          <label>VAT number (if applicable)</label>
                          <FormControl
                            placeholder="Enter your VAT number"
                            name="vat_number"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={
                              formValue.vat_number ? formValue.vat_number : ''
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div className={`mb-3 d-block fonmGroupControl`}>
                          <label>Tax ID type</label>
                          <FormSelect
                            className={` ${
                              formValue.tax_id_type ? 'roleSelected' : ''
                            }`}
                            id="id-type"
                            name="tax_id_type"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={
                              formValue.tax_id_type ? formValue.tax_id_type : ''
                            }
                            options={taxIdTypeOptions}
                          />
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div className={`mb-3 d-block fonmGroupControl`}>
                          <label>Tax ID number</label>
                          <FormControl
                            placeholder="Enter your ID number"
                            name="tax_id_number"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={
                              formValue.tax_id_number
                                ? formValue.tax_id_number
                                : ''
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <h4>Contact details</h4>
                    <Row>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.email_address ||
                            formValidations.emailValid
                              ? 'fieldInvalid'
                              : ''
                          }`}
                        >
                          <label>Email address</label>
                          <FormControl
                            placeholder="Enter your Email address"
                            name="email_address"
                            type="email"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={
                              formValue.email_address
                                ? formValue.email_address
                                : ''
                            }
                            data-required="true"
                          />
                          {formValidations.email_address ? (
                            <div className="fieldError">
                              {formValidations.email_address}
                            </div>
                          ) : (
                            ''
                          )}
                          {formValidations.emailValid ? (
                            <div className="fieldError">
                              {formValidations.emailValid}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div className={`mb-3 d-block fonmGroupControl`}>
                          <label>Phone number</label>
                          <FormControl
                            placeholder="Enter you phone number"
                            name="phone_number"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={
                              formValue.phone_number
                                ? formValue.phone_number
                                : ''
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.street_address ? 'fieldInvalid' : ''
                          }`}
                        >
                          <label>
                            Street address{' '}
                            <small>(Enter the address that you reside)</small>
                          </label>
                          <FormControl
                            placeholder="Enter your street address"
                            name="street_address"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={
                              formValue.street_address
                                ? formValue.street_address
                                : ''
                            }
                            data-required="true"
                          />
                          {formValidations.street_address ? (
                            <div className="fieldError">
                              {formValidations.street_address}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.county ? 'fieldInvalid' : ''
                          }`}
                        >
                          <label>County/State</label>
                          <FormControl
                            placeholder="Enter your County/State"
                            name="county"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={formValue.county ? formValue.county : ''}
                            data-required="true"
                          />
                          {formValidations.county ? (
                            <div className="fieldError">
                              {formValidations.county}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.country ? 'fieldInvalid' : ''
                          }`}
                        >
                          <label>Country</label>
                          <FormSelect
                            placeholder="Enter your country"
                            name="country"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={formValue.country ? formValue.country : ''}
                            isrequired="true"
                          >
                            <option value="">Select</option>
                            {Countries.map((country) => (
                              <option value={formValue.country} key={country}>
                                {country}
                              </option>
                            ))}
                          </FormSelect>
                          {formValidations.country ? (
                            <div className="fieldError">
                              {formValidations.country}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.postcode ? 'fieldInvalid' : ''
                          }`}
                        >
                          <label>Postcode</label>
                          <FormControl
                            placeholder="Enter your postcode"
                            name="postcode"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={formValue.postcode ? formValue.postcode : ''}
                            data-required="true"
                          />
                          {formValidations.postcode ? (
                            <div className="fieldError">
                              {formValidations.postcode}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end">
                      <button className="x-button px-4" onClick={moveToSecond}>
                        Next
                      </button>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {srForm ? (
                  <div className="srFormStep">
                    <Form.Group className="ModalFormGroup">
                      <div className="groupLabel d-flex mb-1">
                        Are you already registered at any societies?
                      </div>
                      <div className="FormRadioGroup">
                        <div
                          key="inline-radio"
                          className="mb-3 styledRadioButtons styledCheckboxButtons d-flex flex-wrap"
                        >
                          <Form.Check
                            inline
                            name="society"
                            type="radio"
                            label="Yes"
                            value="Yes"
                            id="reg-with-sc-y"
                            onChange={(e) => {
                              setRegisterSociety(e.target.value);
                              setFormValidations({
                                ...formValidations,
                                society: '',
                              });
                            }}
                          />
                          <Form.Check
                            inline
                            label="No"
                            value="No"
                            name="society"
                            type="radio"
                            id="reg-with-sc-n"
                            onChange={(e) => {
                              setRegisterSociety(e.target.value);
                              setFormValidations({
                                ...formValidations,
                                society: '',
                              });
                            }}
                          />
                          <Form.Check
                            inline
                            label="Don’t know"
                            value="do not know"
                            name="society"
                            type="radio"
                            id="reg-with-sc-idk"
                            onChange={(e) => {
                              setRegisterSociety(e.target.value);
                              setFormValidations({
                                ...formValidations,
                                society: '',
                              });
                            }}
                          />
                        </div>
                        {formValidations.society ? (
                          <div className="fieldError">
                            {formValidations.society}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </Form.Group>
                    {registerSociety === 'Yes' ? (
                      <Form.Group className="societiesListGroup col-lg-6 documentForm">
                        <div className="groupLabel d-flex mb-1 justify-content-between col-10">
                          <span>Current agent agreement</span>
                          <small>(optional)</small>
                        </div>
                        {societiesList.map((x, i) => (
                          <div className="terminatedAgreement d-flex" key={i}>
                            <div className="fileUpload">
                              <Form.Control
                                type="file"
                                name={`current_agreement${i}`}
                                className="formFileUpload"
                                id={`current_agreement${i}`}
                                onChange={(e) =>
                                  handleScChange(
                                    e as React.ChangeEvent<HTMLInputElement>,
                                    i
                                  )
                                }
                              />
                              <label
                                htmlFor={`current_agreement${i}`}
                                className="fileUploadLabel d-flex"
                              >
                                <span className="fileName">
                                  {x.name ? x.name : 'Please Upload'}
                                </span>
                                <span className="uploadicon iconBlack"></span>
                              </label>
                            </div>
                            <div className="btn-box d-flex">
                              {societiesList.length !== 1 && (
                                <button
                                  className="removeButton alButton btn"
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  -
                                </button>
                              )}
                              {societiesList.length - 1 === i && (
                                <button
                                  className="addButton alButton btn"
                                  onClick={handleAddClick}
                                >
                                  +
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                        {formValidations.file ? (
                          <div className="fieldError">
                            {formValidations.file}
                          </div>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    ) : (
                      ''
                    )}
                    <div className="d-flex justify-content-end mt-4">
                      <button className="x-button px-4" onClick={moveToThird}>
                        Next
                      </button>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {payForm ? (
                  <div className="paymentFormStep">
                    <Row>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.bank_account_name
                              ? 'fieldInvalid'
                              : ''
                          }`}
                        >
                          <label>Account name</label>
                          <FormControl
                            placeholder=""
                            name="bank_account_name"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            data-required="true"
                            value={
                              formValue.bank_account_name
                                ? formValue.bank_account_name
                                : ''
                            }
                          />
                          {formValidations.bank_account_name ? (
                            <div className="fieldError">
                              {formValidations.bank_account_name}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.bank_sort_code ? 'fieldInvalid' : ''
                          }`}
                        >
                          <label>Sort code</label>
                          <FormControl
                            placeholder=""
                            name="bank_sort_code"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            data-required="true"
                            value={
                              formValue.bank_sort_code
                                ? formValue.bank_sort_code
                                : ''
                            }
                          />
                          {formValidations.bank_sort_code ? (
                            <div className="fieldError">
                              {formValidations.bank_sort_code}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.bank_account_number
                              ? 'fieldInvalid'
                              : ''
                          }`}
                        >
                          <label>Account number</label>
                          <FormControl
                            placeholder=""
                            name="bank_account_number"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            data-required="true"
                            value={
                              formValue.bank_account_number
                                ? formValue.bank_account_number
                                : ''
                            }
                          />
                          {formValidations.bank_account_number ? (
                            <div className="fieldError">
                              {formValidations.bank_account_number}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.bank_name ? 'fieldInvalid' : ''
                          }`}
                        >
                          <label>Bank name</label>
                          <FormControl
                            placeholder=""
                            name="bank_name"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            data-required="true"
                            value={
                              formValue.bank_name ? formValue.bank_name : ''
                            }
                          />
                          {formValidations.bank_name ? (
                            <div className="fieldError">
                              {formValidations.bank_name}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.bank_address ? 'fieldInvalid' : ''
                          }`}
                        >
                          <label>Bank address</label>
                          <FormControl
                            placeholder=""
                            name="bank_address"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            data-required="true"
                            value={
                              formValue.bank_address
                                ? formValue.bank_address
                                : ''
                            }
                          />
                          {formValidations.bank_address ? (
                            <div className="fieldError">
                              {formValidations.bank_address}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.bank_iban ? 'fieldInvalid' : ''
                          }`}
                        >
                          <label>IBAN</label>
                          <FormControl
                            placeholder=""
                            name="bank_iban"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            data-required="true"
                            value={
                              formValue.bank_iban ? formValue.bank_iban : ''
                            }
                          />
                          {formValidations.bank_iban ? (
                            <div className="fieldError">
                              {formValidations.bank_iban}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div className={`mb-3 d-block fonmGroupControl`}>
                          <label>
                            Routing code <small>(if applicable)</small>
                          </label>
                          <FormControl
                            placeholder=""
                            name="bank_routing_code"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={
                              formValue.bank_routing_code
                                ? formValue.bank_routing_code
                                : ''
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.bank_account_holding_branch
                              ? 'fieldInvalid'
                              : ''
                          }`}
                        >
                          <label>
                            Account holding branch{' '}
                            <small>(if applicable)</small>
                          </label>
                          <FormControl
                            placeholder=""
                            name="bank_account_holding_branch"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={
                              formValue.bank_account_holding_branch
                                ? formValue.bank_account_holding_branch
                                : ''
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="formFieldCol">
                        <div
                          className={`mb-3 d-block fonmGroupControl ${
                            formValidations.bank_bic_swift_code
                              ? 'fieldInvalid'
                              : ''
                          }`}
                        >
                          <label>
                            BIC/SWIFT code <small>(if applicable)</small>
                          </label>
                          <FormControl
                            placeholder=""
                            name="bank_bic_swift_code"
                            onChange={inputChange}
                            onBlur={blurHandle}
                            value={
                              formValue.bank_bic_swift_code
                                ? formValue.bank_bic_swift_code
                                : ''
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex mt-4">
                      <button
                        className="save-btn px-4 buttonIconSave"
                        onClick={saveDetails}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientPersonalInfo;
