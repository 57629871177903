/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PrimaryDiscographyRecordingsInnerFeatured } from './PrimaryDiscographyRecordingsInnerFeatured';
import {
    PrimaryDiscographyRecordingsInnerFeaturedFromJSON,
    PrimaryDiscographyRecordingsInnerFeaturedFromJSONTyped,
    PrimaryDiscographyRecordingsInnerFeaturedToJSON,
} from './PrimaryDiscographyRecordingsInnerFeatured';
import type { PrimaryDiscographyRecordingsInnerParentRecording } from './PrimaryDiscographyRecordingsInnerParentRecording';
import {
    PrimaryDiscographyRecordingsInnerParentRecordingFromJSON,
    PrimaryDiscographyRecordingsInnerParentRecordingFromJSONTyped,
    PrimaryDiscographyRecordingsInnerParentRecordingToJSON,
} from './PrimaryDiscographyRecordingsInnerParentRecording';
import type { PrimaryDiscographyRecordingsInnerParentReleasesInner } from './PrimaryDiscographyRecordingsInnerParentReleasesInner';
import {
    PrimaryDiscographyRecordingsInnerParentReleasesInnerFromJSON,
    PrimaryDiscographyRecordingsInnerParentReleasesInnerFromJSONTyped,
    PrimaryDiscographyRecordingsInnerParentReleasesInnerToJSON,
} from './PrimaryDiscographyRecordingsInnerParentReleasesInner';
import type { PrimaryDiscographyRecordingsInnerPplContributionsInner } from './PrimaryDiscographyRecordingsInnerPplContributionsInner';
import {
    PrimaryDiscographyRecordingsInnerPplContributionsInnerFromJSON,
    PrimaryDiscographyRecordingsInnerPplContributionsInnerFromJSONTyped,
    PrimaryDiscographyRecordingsInnerPplContributionsInnerToJSON,
} from './PrimaryDiscographyRecordingsInnerPplContributionsInner';
import type { PrimaryDiscographyRecordingsInnerWestburyContributionsInner } from './PrimaryDiscographyRecordingsInnerWestburyContributionsInner';
import {
    PrimaryDiscographyRecordingsInnerWestburyContributionsInnerFromJSON,
    PrimaryDiscographyRecordingsInnerWestburyContributionsInnerFromJSONTyped,
    PrimaryDiscographyRecordingsInnerWestburyContributionsInnerToJSON,
} from './PrimaryDiscographyRecordingsInnerWestburyContributionsInner';

/**
 * 
 * @export
 * @interface PrimaryDiscographyRecordingsInner
 */
export interface PrimaryDiscographyRecordingsInner {
    /**
     * 
     * @type {PrimaryDiscographyRecordingsInnerParentRecording}
     * @memberof PrimaryDiscographyRecordingsInner
     */
    parentRecording: PrimaryDiscographyRecordingsInnerParentRecording;
    /**
     * 
     * @type {Array<PrimaryDiscographyRecordingsInnerParentReleasesInner>}
     * @memberof PrimaryDiscographyRecordingsInner
     */
    parentReleases: Array<PrimaryDiscographyRecordingsInnerParentReleasesInner>;
    /**
     * 
     * @type {Array<PrimaryDiscographyRecordingsInnerWestburyContributionsInner>}
     * @memberof PrimaryDiscographyRecordingsInner
     */
    westburyContributions: Array<PrimaryDiscographyRecordingsInnerWestburyContributionsInner>;
    /**
     * 
     * @type {Array<PrimaryDiscographyRecordingsInnerPplContributionsInner>}
     * @memberof PrimaryDiscographyRecordingsInner
     */
    pplContributions: Array<PrimaryDiscographyRecordingsInnerPplContributionsInner>;
    /**
     * 
     * @type {PrimaryDiscographyRecordingsInnerFeatured}
     * @memberof PrimaryDiscographyRecordingsInner
     */
    featured: PrimaryDiscographyRecordingsInnerFeatured;
}

/**
 * Check if a given object implements the PrimaryDiscographyRecordingsInner interface.
 */
export function instanceOfPrimaryDiscographyRecordingsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "parentRecording" in value;
    isInstance = isInstance && "parentReleases" in value;
    isInstance = isInstance && "westburyContributions" in value;
    isInstance = isInstance && "pplContributions" in value;
    isInstance = isInstance && "featured" in value;

    return isInstance;
}

export function PrimaryDiscographyRecordingsInnerFromJSON(json: any): PrimaryDiscographyRecordingsInner {
    return PrimaryDiscographyRecordingsInnerFromJSONTyped(json, false);
}

export function PrimaryDiscographyRecordingsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrimaryDiscographyRecordingsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parentRecording': PrimaryDiscographyRecordingsInnerParentRecordingFromJSON(json['parentRecording']),
        'parentReleases': ((json['parentReleases'] as Array<any>).map(PrimaryDiscographyRecordingsInnerParentReleasesInnerFromJSON)),
        'westburyContributions': ((json['westburyContributions'] as Array<any>).map(PrimaryDiscographyRecordingsInnerWestburyContributionsInnerFromJSON)),
        'pplContributions': ((json['pplContributions'] as Array<any>).map(PrimaryDiscographyRecordingsInnerPplContributionsInnerFromJSON)),
        'featured': PrimaryDiscographyRecordingsInnerFeaturedFromJSON(json['featured']),
    };
}

export function PrimaryDiscographyRecordingsInnerToJSON(value?: PrimaryDiscographyRecordingsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parentRecording': PrimaryDiscographyRecordingsInnerParentRecordingToJSON(value.parentRecording),
        'parentReleases': ((value.parentReleases as Array<any>).map(PrimaryDiscographyRecordingsInnerParentReleasesInnerToJSON)),
        'westburyContributions': ((value.westburyContributions as Array<any>).map(PrimaryDiscographyRecordingsInnerWestburyContributionsInnerToJSON)),
        'pplContributions': ((value.pplContributions as Array<any>).map(PrimaryDiscographyRecordingsInnerPplContributionsInnerToJSON)),
        'featured': PrimaryDiscographyRecordingsInnerFeaturedToJSON(value.featured),
    };
}

