{
  "12 string guitar": "guitar",
  "12-string bass": "bass-guitar",
  "12-string guitar": "guitar",
  "17-string bass koto": "koto",
  "5-string banjo": "banjo",
  "6-string banjo": "banjo",
  "6-string bass": "bass-guitar",
  "8-string bass guitar": "bass-guitar",
  "accompanist": "general",
  "accordina": "accordion",
  "accordion": "accordion",
  "acoustic bass": "acoustic-bass",
  "acoustic piano": "keyboards",
  "achere": "",
  "acoustic bass guitar": "acoustic-bass",
  "acoustic fretless guitar": "acoustic-guitar",
  "acoustic guitar": "acoustic-guitar",
  "acoustic piccolo bass": "acoustic-bass",
  "actor": "general",
  "adufe": "",
  "aeolian harp": "harp",
  "afoxe": "",
  "african congo drums": "percussion",
  "afuche": "",
  "agogo": "",
  "ajaeng": "strings",
  "akete": "",
  "aketse": "",
  "akkordolia": "zither",
  "alfaia": "",
  "algaita": "",
  "alghoza": "",
  "alphorn": "alphorn",
  "alto vocals": "vocals",
  "alto clarinet": "clarinet",
  "alto crumhorn": "woodwind",
  "alto flute": "flute",
  "alto horn": "horn",
  "alto recorder": "recorder",
  "alto saxophone": "saxophone",
  "alto trombone": "trombone",
  "alto valve trombone": "trombone",
  "alto viol": "strings",
  "alto violin": "violin",
  "amadinda": "",
  "aman khuur": "",
  "ambtara": "",
  "analog synthesizer": "synthesizer",
  "anandalahari": "",
  "anatara": "",
  "andean flute": "flute",
  "andean harp": "harp",
  "angklung": "",
  "anglo concertina": "",
  "vocals": "vocals",
  "ankle rattlers": "percussion",
  "announcer": "general",
  "antara": "",
  "anvil": "percussion",
  "apentemma": "",
  "apito": "",
  "apongalahy": "",
  "appalachian dulcimer": "general",
  "archlute": "strings",
  "archtop guitar": "guitar",
  "arco bass": "double-bass",
  "arghul": "",
  "arpa": "",
  "arpeggione": "",
  "arrabel": "",
  "artist": "contributor",
  "artistic narrative narrator": "general",
  "artistic producer": "producer",
  "ashiko": "",
  "asiko": "",
  "associate producer": "producer",
  "atabal": "",
  "atabaque": "",
  "atarigane": "",
  "atchere": "",
  "audio generator": "synthesizer",
  "autoharp": "autoharp",
  "baandu": "",
  "babasa": "",
  "baby grand piano": "grand-piano",
  "background studio singer": "vocals",
  "backing band": "general",
  "backing vocals": "vocals",
  "baglama": "",
  "bagpipes": "bagpipes",
  "bajo": "",
  "bajo quinto": "",
  "bajo sexto": "strings",
  "balafon": "",
  "balalaika": "strings",
  "baltic psalteries": "",
  "bambaro": "",
  "bamboo flute": "flute",
  "band": "general",
  "band/ensemble member": "general",
  "bandola": "strings",
  "bandolin": "strings",
  "bandoneon": "strings",
  "bandora": "strings",
  "bandura": "strings",
  "bandurria": "strings",
  "bangu": "",
  "banhu": "strings",
  "banjitar": "strings",
  "banjo": "banjo",
  "banjo-dulcimer": "banjo",
  "banjo-mandolin": "mandolin",
  "banjo-ukulele": "banjo",
  "banjolin": "banjo",
  "banjulele": "banjo",
  "bankia": "",
  "bansuri": "",
  "bapang": "",
  "bara": "",
  "barbat": "",
  "baritone saxhorn": "brass",
  "baritone vocals": "vocals",
  "baritone clarinet": "clarinet",
  "baritone guitar": "guitar",
  "baritone horn": "horn",
  "baritone saxophone": "saxophone",
  "baroque guitar": "guitar",
  "baroque trumpet": "trumpet",
  "barrel drum": "percussion",
  "barrel organ": "keyboards",
  "barrel piano": "keyboards",
  "baryton": "",
  "bass": "bass-guitar",
  "bass vocals": "vocals",
  "bass accordion": "accordion",
  "bass clarinet": "bass-clarinet",
  "bass concertina": "",
  "bass crumhorn": "woodwind",
  "bass drum": "bsss-drum",
  "bass dulcian": "",
  "bass fado viola": "strings",
  "bass flute": "flute",
  "bass guitar": "bass-guitar",
  "bass harmonica": "mouth-organ",
  "bass lute": "strings",
  "bass marimba": "",
  "bass oboe": "oboe",
  "bass pedals": "",
  "bass racket": "",
  "bass recorder": "recorder",
  "bass saxophone": "saxophone",
  "bass synthesizer": "synthesizer",
  "bass tambura": "",
  "bass trombone": "trombone",
  "bass trumpet": "trumpet",
  "bass tuba": "tuba",
  "bass viol": "strings",
  "bass violin": "strings",
  "bass-baritone vocals": "vocals",
  "basset clarinet": "woodwind",
  "basset horn": "",
  "basso continuo": "",
  "bassoon": "bassoon",
  "basy": "",
  "bata drum": "percussion",
  "bawu": "",
  "bayan": "",
  "bazooka": "",
  "beatbox": "general",
  "programming": "programming",
  "bedug": "",
  "bell": "bell",
  "bell plate": "bells",
  "bell tree": "bells",
  "bell-piano": "bells",
  "bellow-blown bagpipes": "bagpipes",
  "bellowed reed": "",
  "bellowphone": "",
  "bells": "bells",
  "bembe": "",
  "bendir": "",
  "berda": "",
  "berimbau": "",
  "bhapang": "",
  "bicycle bell": "bells",
  "big drum": "percussion",
  "bin": "",
  "bin-sasara": "",
  "bin-sitar": "",
  "biniou": "",
  "birbyne": "",
  "birch lur": "",
  "bisernica": "",
  "biwa": "",
  "blaster beam": "",
  "blues harp": "",
  "bo (bell)": "bells",
  "bo (cymbals)": "percussion",
  "flute": "flute",
  "boatswain's pipe": "",
  "bodhran": "percussion",
  "body percussion": "percussion",
  "bolivian flute": "flute",
  "bolon": "",
  "bombarde": "",
  "bombardon": "",
  "bombo": "bombo",
  "bombo (bass drum)": "bombo",
  "bombo (frame drum)": "bombo",
  "bombo (mouth bom)": "",
  "bombo leguero": "",
  "bonang": "",
  "bonang barung": "",
  "bonang panembung": "",
  "bonang panerus": "",
  "bones": "",
  "bongo": "bongos",
  "bongos": "bongos",
  "boobam": "",
  "boobams": "",
  "boomwhacker": "",
  "border pipes": "bagpipes",
  "bordonua": "",
  "bottle": "",
  "bouzar": "",
  "bouzar / gouzouki": "",
  "bouzouki": "strings",
  "bowed lute": "strings",
  "bowed lyre": "strings",
  "bowed piano": "",
  "bowed psaltery": "",
  "bowed string instruments": "strings",
  "box zither": "zither",
  "brac": "",
  "braguinha": "",
  "brass": "brass",
  "brass band": "brass",
  "brass bass": "brass",
  "bronze lur": "",
  "broom": "",
  "brushes": "",
  "bucium": "",
  "bugarija": "",
  "bugle": "brass",
  "buhay": "",
  "buisine": "",
  "buk": "",
  "bukkehorn": "",
  "bulbul tarang": "",
  "bull fiddle": "strings",
  "bullroarer": "",
  "button accordion": "accordion",
  "buzuq": "",
  "byzaanchi": "",
  "c melody saxophone": "saxophone",
  "c-bass recorder": "recorder",
  "c-melody saxophone": "saxophone",
  "cabasa": "cabasa",
  "cadenza": "",
  "caixa": "",
  "caja": "",
  "caja vallenata": "",
  "cajas rumberas": "",
  "cajon": "",
  "cajon peruano": "",
  "calabash": "",
  "caller": "",
  "calliope": "",
  "calung": "",
  "campana": "",
  "cantor": "",
  "caramusa": "",
  "carillon": "",
  "carnyx": "",
  "castanets": "castanets",
  "cavaco": "",
  "cavaquinho": "",
  "caxixi": "",
  "celesta": "",
  "cello": "cello",
  "cello banjo": "banjo",
  "cembalet": "",
  "ceng ceng": "",
  "cetara": "",
  "cevgen": "",
  "chacaras": "",
  "chacha": "",
  "chak'chas": "",
  "chakhe": "",
  "chalumeau": "",
  "chamber organ": "keyboards",
  "chamber pipes": "",
  "chamberlin": "",
  "chande": "",
  "changgo": "",
  "changi": "",
  "chansonnier": "",
  "chanter": "",
  "chanzy": "",
  "chap": "",
  "chap lek": "",
  "chapman stick": "",
  "charamel": "",
  "charango": "",
  "chasquidos": "",
  "chau gong": "",
  "chekere": "",
  "cheng": "",
  "chikuzen biwa": "",
  "chime bar": "",
  "chimes": "chimes",
  "chimes/carillion": "chimes",
  "chinchin": "",
  "chinese drum": "percussion",
  "chinese flute": "flute",
  "chinese harp": "harp",
  "ching": "",
  "ching (cymbals)": "percussion",
  "chirimia": "",
  "chitarra battente": "",
  "chitarrone": "",
  "chitra veena": "",
  "chiwata": "",
  "chocalho": "",
  "choir": "vocals",
  "choir member": "vocals",
  "choir singer": "vocals",
  "choir singing": "vocals",
  "choir stand-in": "vocals",
  "chonguri": "",
  "conductor": "conductor",
  "chromatic button accordion": "accordion",
  "chromatic harmonica": "mouth-organ",
  "chuniri": "",
  "church organ": "organ",
  "chuurqin": "",
  "cifteli": "",
  "cigar box guitar": "guitar",
  "cimbalom": "",
  "cimbasso": "",
  "cimpoi": "",
  "cinema organ": "organ",
  "cither": "",
  "citole": "",
  "cittern": "",
  "cizhonghu": "",
  "handclaps": "hand-claps",
  "clap sticks": "percussion",
  "clapper": "percussion",
  "clapstick": "percussion",
  "clarin cajamarquino": "",
  "clarinet": "clarinet",
  "clarinet (c)": "clarinet",
  "clarinet (e flat)": "clarinet",
  "clarion": "",
  "clarsach": "harp",
  "classical guitar": "guitar",
  "classical kemence": "",
  "claves": "claves",
  "clavichord": "",
  "clavier": "",
  "clavinet": "",
  "claviola": "",
  "clavioline": "",
  "claviorgan": "",
  "claviorganum": "",
  "clog": "",
  "clog dancer": "",
  "co ke": "",
  "producer": "producer",
  "cobza": "",
  "colascione": "",
  "comb": "",
  "commentator": "general",
  "computer": "general",
  "concert band": "general",
  "concert flute": "flute",
  "concert grand piano": "grand-piano",
  "concert harp": "harp",
  "concertina": "",
  "violin": "violin",
  "conch": "",
  "concussion idiophone": "",
  "conga": "congas",
  "continuum": "",
  "contra-alto clarinet": "clarinet",
  "contrabass": "",
  "contrabass clarinet": "bass-clarinet",
  "contrabass flute": "flute",
  "contrabass recorder": "recorder",
  "contrabass saxophone": "saxophone",
  "contrabass trombone": "trombone",
  "contrabassoon": "contrabassoon",
  "contralto vocals": "vocals",
  "contributor": "contributor",
  "cor anglais": "english-horn",
  "cornamuse": "",
  "cornet": "cornet",
  "cornett": "",
  "corno da caccia": "",
  "coro": "",
  "countertenor vocals": "",
  "cowbell": "cow-bell",
  "crash cymbals": "cymbal",
  "craviola": "",
  "cretan lyra": "",
  "cristal baschet": "",
  "cromorne": "",
  "crotales": "",
  "crumhorn": "woodwind",
  "crwth": "",
  "cuatro": "",
  "cuica": "",
  "cultrun": "",
  "cumbus": "",
  "cura": "",
  "curtal": "",
  "cyla-diare": "",
  "cylindrical drum": "percussion",
  "cymbal": "cymbal",
  "daegeum": "",
  "daf": "",
  "daire": "",
  "daluo": "",
  "damaru": "",
  "dan bau": "",
  "dan nguyet": "",
  "dan nhi": "",
  "dan tam": "",
  "dan tam thap luc": "",
  "dan tranh": "",
  "dan tu": "",
  "dan tu day": "",
  "dan ty ba": "",
  "danburo": "",
  "danso": "",
  "darabuka": "",
  "daruan": "",
  "davul": "",
  "daxaphone": "",
  "deaejeng": "",
  "denis d'or": "",
  "dhol": "",
  "dholak": "",
  "di (slit drum)": "",
  "di (flute)": "",
  "diaff": "",
  "diatonic button accordion": "accordion",
  "diddley bow": "",
  "didgeridoo": "didgeridoo",
  "dikanza": "",
  "dili tuiduk": "",
  "dilruba": "",
  "ding buot": "",
  "ding nam": "",
  "ding tac ta": "",
  "diple": "",
  "disk drive": "",
  "diyingehu": "",
  "dizi": "",
  "scratches": "",
  "djembe": "djembe",
  "djoza": "",
  "djura": "",
  "dobro": "",
  "dobro-bass": "",
  "dohol": "",
  "doira": "",
  "dojo": "",
  "dolceola": "",
  "doli": "",
  "dombra": "",
  "doneli": "",
  "donno": "",
  "donso ngoni": "",
  "doorbell": "",
  "doshpuluur": "",
  "dotara": "",
  "double bass": "double-bass",
  "double bassoon": "bassoon",
  "double clarinet": "clarinet",
  "double flute": "flute",
  "double reed": "wodwind",
  "doumbaki": "",
  "doumbi": "",
  "doyra": "",
  "dramyin": "",
  "dril-bu": "",
  "droma": "",
  "drone": "",
  "drum": "percussion",
  "drums": "drumkit",
  "drum machine": "drum-machine",
  "drum programming": "programming",
  "stylophone": "stylophone",
  "duck call": "",
  "duduk": "",
  "duff": "",
  "duggi": "",
  "dulce melos": "",
  "dulceola": "",
  "dulcian": "",
  "dulcimer": "",
  "dulcitone": "",
  "dulzaina": "",
  "dumbak": "",
  "dumbelek": "",
  "dumbuks": "",
  "dundun": "",
  "dung-chen": "",
  "dunun": "",
  "dutar": "",
  "duxianqin": "",
  "dvojacka": "",
  "dvojnice": "",
  "e-bow": "",
  "e-flat clarinet": "clarinet",
  "guitar": "guitar",
  "e-organ": "",
  "ebow": "",
  "effects": "general",
  "ektara": "",
  "electric accordion": "accordion",
  "electric appalachian dulcimer": "",
  "electric baritone guitar": "electric-guitar",
  "electric cello": "cello",
  "electronic drums": "",
  "electric fiddle": "violin",
  "electric fretless guitar": "electric-guitar",
  "electric grand piano": "grand-piano",
  "electric harmonium": "harmonium",
  "electric harp": "harp",
  "electric harpsichord": "harpsichord",
  "keyboards": "keyboards",
  "electric lap steel guitar": "steel-guitar",
  "electric organ": "organ",
  "electric piano": "keyboards",
  "electric sitar": "sitar",
  "electric upright bass": "double-bass",
  "electric viola": "viola",
  "electric violin": "violin",
  "electronic cello": "cello",
  "synthesizer": "synthesizer",
  "electronic organ": "organ",
  "electronic percussion": "",
  "electronic valve instrument": "",
  "electronic vibraphone": "",
  "electronic wind instrument": "",
  "electronics": "",
  "electronium": "",
  "elektronium": "",
  "eligible studio producer": "producer",
  "end-blown flute": "flute",
  "english bagpipes": "bagpipes",
  "english concertina": "",
  "english flageolet": "",
  "english guitar": "guitar",
  "english horn": "english-horn",
  "ensemble": "",
  "entertainer": "general",
  "epinette des vosges": "",
  "erhu": "erhu",
  "esraj": "",
  "euphonium": "brass",
  "ewi": "",
  "ezzo-soprano": "",
  "fado viola": "",
  "fairground organ": "organ",
  "falsetto vocals": "vocals",
  "fanfare trumpet": "trumpet",
  "fanfaretrumpet": "trumpet",
  "farfisa": "",
  "featuring": "",
  "sound effects": "sound-effects",
  "fiddel": "",
  "fiddle": "violin",
  "fife": "woodwind",
  "finger clicks": "finger-snaps",
  "finger cymbals": "",
  "fipple flute": "woodwind",
  "five-string banjo": "banjo",
  "flabiol": "",
  "flageolet": "",
  "flamenco guitar": "guitar",
  "flexatone": "",
  "floppy disk drive": "",
  "floyera": "",
  "flugabone": "",
  "flugelhorn": "brass",
  "fluier": "",
  "flumpet": "",
  "flute d'amour": "flute",
  "flute organ": "flute",
  "flutina": "",
  "folk guitar": "guitar",
  "folk harp": "harp",
  "folklorist": "",
  "foot stamping": "foot-stomps",
  "foot stomps": "foot-stomps",
  "foot tapping": "",
  "footbass": "",
  "fortepiano": "keyboards",
  "fotdella": "",
  "four-string banjo": "banjo",
  "frame drum": "percussion",
  "free reed": "",
  "free-reed flute": "flute",
  "french bagpipes": "bagpipes",
  "french horn": "french-horn",
  "fretless bass guitar": "bass-guitar",
  "fretless guitar": "guitar",
  "friction drum": "percussion",
  "friction idiophone": "",
  "friscaletto": "",
  "frottoir": "",
  "frigideira": "",
  "fujara": "",
  "fundeh": "",
  "fyell": "",
  "gadulka": "",
  "gaita": "",
  "gajde": "",
  "galician gaita": "",
  "gallichon": "",
  "galo": "",
  "galoubet": "",
  "gambang": "",
  "gamelan": "",
  "ganga": "",
  "gangsa": "",
  "gankogui": "",
  "ganza": "",
  "gaohu": "",
  "gardon": "",
  "garifuna drum": "",
  "garklein recorder": "",
  "garmon": "",
  "gayageum": "",
  "gbedu": "",
  "gehu": "",
  "gemshorn": "",
  "gender": "",
  "gender barung": "",
  "gender panerus": "",
  "gender wayang": "",
  "genggong": "",
  "geomungo": "",
  "german concertina": "",
  "german harp": "harp",
  "ghara": "",
  "ghatam": "",
  "ghati": "",
  "ghayta": "",
  "ghijak": "",
  "ghungroo": "",
  "giga": "",
  "gimbri": "",
  "gini": "",
  "gittern": "",
  "glass": "",
  "glass harmonica": "mouth-organ",
  "glass harp": "",
  "glockenspiel": "glockenspiel",
  "goblet drum": "",
  "goge": "",
  "gong": "gong",
  "gong bass drum": "",
  "gong chime": "",
  "gorodao": "",
  "gottuvadyam": "",
  "gourd": "",
  "gourd rattle": "",
  "gouzouki": "",
  "gralla": "",
  "gramorimba": "",
  "grand piano": "grand-piano",
  "grantang": "",
  "gravikord": "",
  "great bass recorder": "recorder",
  "great highland bagpipe": "bagpipes",
  "griot": "",
  "grneta": "",
  "groovebox": "",
  "gu": "",
  "gua'chara": "",
  "guacharaca": "",
  "guan": "",
  "guayo": "",
  "guban": "",
  "gubgubi": "",
  "gudastviri": "",
  "gudok": "",
  "gudugudu": "",
  "guimbri": "",
  "guira": "",
  "guiro": "guiro",
  "guitalele": "",
  "guitar banjo": "bango",
  "guitar synthesizer": "",
  "guitaret": "",
  "guitarron": "",
  "guitarron chileno": "",
  "guitarron mexicano": "",
  "guitarviol": "",
  "guitorgan": "",
  "guitsteel": "",
  "gumbri": "",
  "gung": "",
  "gunje": "",
  "guntang": "",
  "guqin": "",
  "gusli": "",
  "guzheng": "",
  "haegeum": "",
  "hair": "",
  "halldorophone": "",
  "hammer": "",
  "hammered dulcimer": "",
  "hammond organ": "",
  "handbell": "hand-bell",
  "handpan": "",
  "hang drum": "percussion",
  "hard disk drive": "",
  "hardanger fiddle": "",
  "hardingfele": "",
  "harjedalspipa": "",
  "harmet": "",
  "harmonica": "mouth-organ",
  "harmonium": "harmonium",
  "harmony vocals": "vocals",
  "harp": "harp",
  "harp guitar": "guitar",
  "harpejji": "",
  "harpsichord": "harpsichord",
  "harpsipiano": "keyboards",
  "haute-contre vocals": "",
  "hawaiian guitar": "guitar",
  "heckelphone": "",
  "heike biwa": "",
  "helicon": "",
  "hi-hat": "",
  "hichiriki": "",
  "high violin": "violin",
  "highland bagpipes": "bagpipes",
  "hmong flute": "flute",
  "hoddu": "",
  "homus": "",
  "horagai": "",
  "horn": "horn",
  "horns": "",
  "hosho": "",
  "hoshu": "",
  "hot fountain pen": "",
  "hotchiku": "",
  "hourglass drum": "",
  "huapanguera": "",
  "hudukka": "",
  "hue puruhau": "",
  "hue puruwai": "",
  "hulusi": "",
  "hummel": "",
  "humming": "",
  "hunting horn": "",
  "hunting horn (valved)": "",
  "huqin": "",
  "hurdy-gurdy": "",
  "hyoshigi": "",
  "ichigenkin": "",
  "idiophone": "",
  "igil": "",
  "imitation bass": "",
  "indian harp": "harp",
  "indonesian rebab": "",
  "instruments": "",
  "interviewee": "",
  "interviewer": "",
  "irish bouzouki": "strings",
  "irish flute": "flute",
  "irish harp": "harp",
  "irish whistle": "whistle",
  "jagd horn": "",
  "jaggo": "",
  "jal tarang": "",
  "janggu": "",
  "jantar": "",
  "japanese wooden flute": "flute",
  "jarana": "",
  "jawan": "",
  "jazz horn": "",
  "jegogan": "",
  "jejo": "",
  "jejo voatavo": "",
  "jeli ngoni": "",
  "jew's harp": "",
  "jing": "",
  "jing'erhu": "",
  "jinghu": "",
  "jingle bells": "bells",
  "joik": "",
  "jouhikko": "",
  "joze": "",
  "jublag": "",
  "jug": "jug",
  "jugkalimba": "",
  "jumbush": "",
  "junjung": "",
  "jushichigen-kin (bass koto)": "koto",
  "k'kwaengwari": "",
  "ka (drum)": "",
  "kaba gaida": "",
  "kabosa": "",
  "kabosy": "",
  "kacapi": "",
  "kacapi indung": "",
  "kacapi rincik": "",
  "kacapi siter": "",
  "kachva sitar": "",
  "kagura suzu": "",
  "kagurabue": "",
  "kaiamba rambo": "",
  "kakegoe": "",
  "kalimba": "kalimba",
  "kamac": "",
  "kamaica": "",
  "kamale ngoni": "",
  "kamancheh": "",
  "kanjira": "",
  "kankles": "",
  "kannel": "",
  "kantele": "",
  "kantilan": "",
  "kanun": "",
  "karamouza": "",
  "karigo": "",
  "karkabas": "",
  "karna": "",
  "kartal": "",
  "kaval": "",
  "kayagum": "",
  "kazoo": "kazoo",
  "kecer": "",
  "kelenang": "",
  "kemanak": "",
  "kemenche": "",
  "kemence of the black sea": "",
  "kemene": "",
  "kempli": "",
  "kempul": "",
  "kempur": "",
  "kempyang": "",
  "ken bau": "",
  "ken la": "",
  "kena": "",
  "kendang": "",
  "kendang lanang": "",
  "kendang wadon": "",
  "kendhang batangan": "",
  "kendhang gendhing": "",
  "kendhang indung": "",
  "kendhang ketipung": "",
  "kendhang kulanter": "",
  "kendhang wayangan": "",
  "kenong": "",
  "kenyir": "",
  "kepyak": "",
  "kethuk": "",
  "kettledrum": "kettle-drum",
  "ketuk": "",
  "keyboard bass": "",
  "keyed box zither": "zither",
  "keyed brass instruments": "brass",
  "keytar": "keytar",
  "khaen": "",
  "khalam": "",
  "khartal": "",
  "khen meo": "",
  "khene": "",
  "khim": "",
  "khlui": "",
  "khol": "",
  "khong wong": "",
  "khong wong lek": "",
  "khong wong yai": "",
  "khulsan khuur": "",
  "khurdak": "",
  "khuur": "",
  "ki pah": "",
  "kinnor": "",
  "kirar": "",
  "kithara": "",
  "kkwaenggwari": "",
  "klong": "",
  "klong khaek": "",
  "klong song na": "",
  "klong that": "",
  "klong yao": "",
  "koauau": "",
  "koauau ponga ihu": "",
  "kobyz": "",
  "kokle": "",
  "kokyu": "",
  "komun'go": "",
  "komuz": "",
  "kong wong yai": "",
  "kora": "",
  "korintsana": "",
  "kortholt": "",
  "kos": "",
  "koto": "koto",
  "kotsuzumi": "",
  "krakebs": "",
  "krap": "",
  "krap khu": "",
  "krap phuang": "",
  "krap sepha": "",
  "krar": "",
  "kudum": "",
  "kusle": "",
  "kynggari": "",
  "lagerphone": "",
  "lakota flute": "flute",
  "lakoto flute": "flute",
  "lamba": "",
  "lamellaphone": "",
  "lamellophone": "",
  "langeleik": "",
  "laouto": "",
  "lap harp": "harp",
  "lap steel guitar": "steel-guitar",
  "laser harp": "harp",
  "laud": "",
  "launeddas": "",
  "lautenwerck": "",
  "lavta": "",
  "lead guitar": "guitar",
  "lead vocal": "vocals",
  "likembe": "",
  "limbe": "",
  "lion's roar": "",
  "lira": "",
  "lira da braccio": "",
  "lira organizzatai": "",
  "lirica": "",
  "lirone": "",
  "liru": "",
  "lithophone": "",
  "liuqin": "",
  "llaute": "",
  "log drum": "percussion",
  "lokanga bara": "",
  "lokole": "",
  "low whistle": "whistle",
  "lowland pipes": "bagpipes",
  "lukeme": "",
  "lur": "",
  "lute": "",
  "lute guitar": "guitar",
  "lute harpsichord": "",
  "lutheal": "",
  "lyra": "",
  "lyra viol": "",
  "lyre": "",
  "lyricon": "",
  "lysarden": "",
  "madal": "",
  "maddalam": "",
  "maddale": "",
  "magruna": "",
  "mallets": "",
  "mandobass": "",
  "mandocello": "",
  "mandoguitar": "",
  "mandola": "",
  "mandolin": "mandolin",
  "mandolin banjo": "banjo",
  "mandolincello": "",
  "mandolute": "",
  "mandora": "",
  "mandore": "",
  "manjira": "",
  "manjur": "",
  "mankeri": "",
  "manzello": "",
  "maracas": "maracas",
  "marimba": "",
  "marimba lumina": "",
  "marimbula": "",
  "mark tree": "",
  "marovany": "",
  "marxophone": "",
  "masenqo": "",
  "masinko": "",
  "mc": "",
  "mbira": "",
  "mejoranera": "",
  "mellophone": "",
  "mellophonium": "",
  "mellotron": "",
  "melodeon": "",
  "melodica": "melodica",
  "melophone": "",
  "membranophone": "",
  "mendoza": "",
  "metallophone": "",
  "mexican vihuela": "",
  "mey": "",
  "mezzo-contralto vocals": "vocals",
  "mezzo-soprano vocals": "vocals",
  "midi controller": "",
  "midi instrument": "",
  "midistick": "",
  "mijwiz": "",
  "minimoog": "",
  "minipiano": "keyboards",
  "minister": "",
  "mirliton": "",
  "mirwas": "",
  "mizmar": "",
  "mizwad": "",
  "moceno": "",
  "mong": "",
  "monkey stick": "",
  "monochord": "monochord",
  "moog": "",
  "morchang": "",
  "morin khuur": "",
  "morrisette": "",
  "morsing": "",
  "mountain dulcimer": "",
  "mouth bow": "",
  "mouth harp": "",
  "mouth organ": "mouth-organ",
  "mrdanga": "",
  "mridangam": "",
  "mukkuri": "",
  "munnharpe": "",
  "murali": "",
  "murcang": "",
  "murli": "",
  "muselar": "",
  "musette": "",
  "musette de cour": "",
  "music box": "",
  "music sequencer": "",
  "music video clip director": "",
  "musical bow": "",
  "musical box": "",
  "musical saw": "",
  "n'vike": "",
  "nabal": "",
  "nadaswaram": "",
  "nagadou-daiko": "",
  "nagak": "",
  "nagasvaram": "",
  "nai": "",
  "nakers": "",
  "nal": "",
  "nanga": "",
  "naobo": "",
  "naqareh": "",
  "naqqara": "",
  "narrator": "",
  "nattuva talam": "",
  "natural horn": "",
  "natural trumpet": "",
  "ney": "",
  "ngoma": "",
  "ngomi": "",
  "ngoni": "",
  "nguru": "",
  "nigenkin": "",
  "njarka": "",
  "nohkan": "",
  "noises": "",
  "northumbrian pipes": "bagpipes",
  "northumbrian small pipes": "bagpipes",
  "nose flute": "",
  "nose whistle": "whistle",
  "novachord": "",
  "nyatiti": "",
  "nyckelharpa": "",
  "oboe": "oboe",
  "oboe d'amore": "oboe",
  "oboe da caccia": "oboe",
  "oboe damore": "oboe",
  "ocarina": "",
  "ocean drum": "",
  "octapad": "",
  "octave mandolin": "mandolin",
  "octavilla": "",
  "octavina": "",
  "octoban": "",
  "octobass": "",
  "okonkole": "",
  "oktawka": "",
  "olifant": "",
  "omnichord": "",
  "ondes martenot": "",
  "ondioline": "",
  "onoben": "",
  "open-back banjo": "",
  "ophicleide": "",
  "optigan": "",
  "organ": "",
  "organistrum": "",
  "orpharion": "",
  "orphica": "",
  "otamatone": "",
  "other": "",
  "other brass instruments": "brass",
  "other instruments": "general",
  "other keyboard instruments": "keyboards",
  "other music video clip creator": "general",
  "other percussion instruments": "percussion",
  "other plucking instruments": "",
  "other string instruments": "strings",
  "other woodwind instruments": "woodwind",
  "otsuzumi": "",
  "oud": "",
  "outi": "",
  "oval spinet": "",
  "overtone flute": "",
  "overtone voice": "",
  "p'iri": "",
  "pahu": "",
  "paiban": "",
  "paila": "",
  "pakhavaj": "",
  "pakhawaj": "",
  "palmas": "",
  "pan flute": "panpipes",
  "panpipes": "panpipes",
  "pandeiro": "",
  "pandereta": "",
  "panduri": "",
  "pang gu ly hu hmong": "",
  "pano": "",
  "paraguayan harp": "",
  "pardessus de viole": "",
  "parlour grand piano": "grand-piano",
  "pate": "",
  "pedabro": "",
  "pedal accordion": "",
  "pedal piano": "keyboards",
  "pedal steel guitar": "steel-guitar",
  "pedalboard": "",
  "pemade": "",
  "percussion": "percussion",
  "performer": "contributor",
  "phach": "",
  "pi": "",
  "pi chawa": "",
  "pi nai": "",
  "pi thiu": "",
  "pianet": "",
  "piano": "keyboards",
  "piano accordion": "accordion",
  "piano duo": "keyboards",
  "piano four hands": "keyboards",
  "piano quartet": "keyboards",
  "piano spinet": "keyboards",
  "piano trio": "keyboards",
  "pianoforte": "keyboards",
  "piccolo": "piccolo",
  "piccolo banjo": "banjo",
  "piccolo bass": "bass",
  "piccolo bass guitar": "bass-guitar",
  "piccolo flute": "piccolo",
  "piccolo oboe": "oboe",
  "piccolo trumpet": "trumpet",
  "pierrot ensemble": "",
  "pifana": "",
  "piffaro": "",
  "pipa": "",
  "pipe": "pipe",
  "pipe and tabor": "pipe",
  "pipe major": "bagpipes",
  "pipe organ": "organ",
  "pipes": "bagpipes",
  "piri": "",
  "pito": "",
  "pito herreno": "",
  "pixiephone": "",
  "pkhachich": "",
  "platillos": "",
  "player piano": "keyboards",
  "plectrum banjo": "banjo",
  "plucked idiophone": "",
  "plucked string instruments": "",
  "pluriarc": "",
  "pocket trumpet": "trumpet",
  "poetry reader": "general",
  "poi awhiowhio": "",
  "polyphon": "",
  "porotiti": "",
  "portative": "",
  "portuguese guitar": "guitar",
  "porutu": "",
  "positive organ": "",
  "post horn": "",
  "practice chanter": "",
  "preacher": "",
  "prempensua": "",
  "prepared piano": "keyboards",
  "presenter": "general",
  "primero": "",
  "psalmodicon": "",
  "psaltery": "",
  "pujador": "",
  "pullankulal": "",
  "pumotomoto": "",
  "pump organ": "organ",
  "pupakapaka": "",
  "purerehua": "",
  "putatara": "",
  "putorino": "",
  "qanun": "",
  "qasaba": "",
  "qilaut": "",
  "qirba": "",
  "quadruple reed": "",
  "quena": "",
  "quenacho": "",
  "quijada": "",
  "quills": "",
  "quintfagott": "",
  "quinto": "",
  "quinton": "",
  "quitls": "",
  "quray": "",
  "rabab": "",
  "rabeca": "",
  "rabel": "",
  "rackett": "",
  "raconteur": "",
  "rag dung": "",
  "rain stick": "",
  "rainstick": "rainstick",
  "rammana": "",
  "ranat ek": "",
  "ranat kaeo": "",
  "ranat thum": "",
  "rap": "",
  "rapper": "vocals",
  "ratchet": "",
  "rattle": "",
  "rauschpfeife": "",
  "ravanahatha": "",
  "reactable": "",
  "read by": "",
  "rebab": "",
  "rebec": "",
  "reco-reco": "",
  "recorder": "recorder",
  "reed": "",
  "reed organ": "",
  "reeds": "",
  "regal": "",
  "reikin": "",
  "remix": "",
  "remixer": "",
  "repeater": "",
  "repicador": "",
  "repinique": "",
  "reporter": "",
  "requinto": "",
  "requinto guitar": "guitar",
  "resonator guitar": "guitar",
  "resonator banjo": "banjo",
  "reyong": "",
  "rgya-gling": "",
  "rhaita": "",
  "rhodes piano": "keyboards",
  "rhythm guitar": "guitar",
  "rhythm sticks": "",
  "rhythms box": "",
  "riqq": "",
  "rkang-gling": "",
  "rnga": "",
  "rol-mo": "",
  "rondador": "",
  "ronroco": "",
  "roria": "",
  "rototom": "",
  "rozhok": "",
  "ruan": "",
  "rubab": "",
  "rubboard": "",
  "rudra veena": "",
  "rudravina": "",
  "ryuteki": "",
  "sabar": "",
  "sabaro": "",
  "sackbut": "",
  "sackpipa": "",
  "saduk": "",
  "saenghwang": "",
  "sahnai": "",
  "salterio": "",
  "saluang": "",
  "samba whistle": "",
  "samica": "",
  "sampled performer": "",
  "sampler": "sampler",
  "sampona": "",
  "samvadini": "",
  "sanfona": "",
  "sansa": "",
  "sanshin": "",
  "santoor": "",
  "santur": "",
  "sanxian": "",
  "sanza": "",
  "sao meo": "",
  "sao truc": "",
  "sapeh": "",
  "sarangi": "",
  "saraswati veena": "",
  "sargija": "",
  "sarod": "",
  "saron": "",
  "saron barung": "",
  "saron demung": "",
  "saron panerus": "",
  "saron peking": "",
  "saron wayang": "",
  "saroz": "",
  "sarrusophone": "",
  "satara": "",
  "satsuma biwa": "",
  "saw duang": "",
  "saw sam sai": "",
  "saw u": "",
  "saxello": "",
  "saxhorn": "",
  "saxophone": "saxophone",
  "saxor": "",
  "saz": "",
  "scat": "vocals",
  "schwyzerorgeli": "",
  "scottish smallpipes": "bagpipes",
  "scraped idiophone": "",
  "scraper": "",
  "scratcher": "",
  "segunda": "",
  "sekere": "",
  "seljeflyte": "",
  "selmer-maccaferri guitar": "",
  "semi-acoustic guitar": "",
  "senh tien": "",
  "seni rebab": "",
  "seperewa": "",
  "sequencer": "",
  "serbo-croatian tamburica orchestra": "",
  "serpent": "",
  "setar": "",
  "shahi baaja": "",
  "shaker": "shaker",
  "shakubyoshi": "",
  "shakuhachi": "",
  "shamisen": "",
  "shanai": "",
  "shawm": "",
  "shehnai": "",
  "shekere": "",
  "sheng": "",
  "shichepshin": "",
  "shime-daiko": "",
  "shinobue": "",
  "sho": "",
  "shofar": "",
  "shouting": "vocals",
  "shruti box": "",
  "shudraga": "",
  "shuitar": "",
  "shuttle pipes": "",
  "sihu": "",
  "siku": "",
  "sil-snyan": "",
  "sindhi sarangi": "",
  "singing bowl": "",
  "single reed": "",
  "sintir": "",
  "siren": "",
  "sistrum": "",
  "sitar": "sitar",
  "skratjie": "",
  "slagbordun": "",
  "slapstick": "",
  "slenthem": "",
  "slentho": "",
  "slide brass instruments": "brass",
  "slide guitar": "guitar",
  "slide trumpet": "trumpet",
  "slide whistle": "whistle",
  "slit drums": "percussion",
  "small guitar": "guitar",
  "smallpipes": "bagpipes",
  "snare drum": "snare-drum",
  "sodina": "",
  "sodinar": "",
  "soloist": "",
  "song loan": "",
  "sopilka": "",
  "sopranino": "",
  "sopranino clarinet": "clarinet",
  "sopranino recorder": "recorder",
  "sopranino saxophone": "saxophone",
  "soprano vocals": "vocals",
  "soprano clarinet": "clarinet",
  "soprano cornet": "cornet",
  "soprano crumhorn": "woodwind",
  "soprano flute": "flute",
  "soprano recorder": "recorder",
  "soprano saxophone": "saxophone",
  "soprano trombone": "trombone",
  "soprano violin": "violin",
  "sordone": "",
  "sorna": "",
  "souna": "",
  "sound director": "general",
  "sounds": "",
  "sousaphone": "",
  "souvliari": "",
  "spanish guitar": "acoustic-guitar",
  "speech": "vocals",
  "spike-fiddle": "",
  "spilapipa": "",
  "spinet": "",
  "spinettone": "",
  "spoken word": "vocals",
  "spoons": "spoons",
  "square pianoforte": "keyboards",
  "steel drums": "steel-drum",
  "steel guitar": "steel-guitar",
  "steelpan": "",
  "steel-string acoustic guitar": "acoustic-guitar",
  "steirische harmonika": "mouth-organ",
  "stick zither": "zither",
  "stomp box": "",
  "story teller": "",
  "street organ": "organ",
  "string drum": "percussion",
  "string instrument": "strings",
  "string synthesizer": "",
  "strings": "strings",
  "stritch": "",
  "stroh violin": "",
  "struck idiophone": "",
  "struck string instruments": "",
  "strumstick": "",
  "subcontrabass recorder": "recorder",
  "subcontrabass saxophone": "saxophone",
  "suka": "",
  "suling": "",
  "suona": "",
  "surbahar": "",
  "surdo": "",
  "surdu": "",
  "surigane": "",
  "surmandal": "",
  "surnai (kashmir)": "",
  "sursingar": "",
  "svara mandala": "",
  "svirala": "",
  "swarmandal": "",
  "symphony": "",
  "sympitar": "",
  "synclavier": "",
  "synthaxe": "",
  "t'rung": "",
  "taal": "",
  "tabal": "",
  "tabla": "",
  "table steel guitar": "steel-guitar",
  "tabor": "",
  "tack piano": "keyboards",
  "taegum": "",
  "taepyeongso": "",
  "taiko": "",
  "taishogoto": "",
  "talharpa": "",
  "talkbox": "",
  "talking drum": "talking-drum",
  "tam-tam": "",
  "tama": "",
  "tambin": "",
  "tambora": "",
  "tamboril": "",
  "tamborim": "",
  "tambourine": "tambourine",
  "tambur (drum)": "percussion",
  "tambur (gong)": "gong",
  "tambur (lute)": "",
  "tanpura": "",
  "tamburitza": "",
  "tan-tan": "",
  "tanbou ka": "",
  "tanbur": "",
  "tanbura": "",
  "tangent piano": "",
  "tannerin": "",
  "taonga puoro": "",
  "tapan": "",
  "tapboard": "",
  "taphon": "",
  "tar": "",
  "tar (drum)": "percussion",
  "tar (flute)": "flute",
  "tar (frame drum)": "percussion",
  "tar (lute)": "",
  "tar shenai": "",
  "taragot": "",
  "tarawangsa": "",
  "tarka": "",
  "tarogato": "",
  "tarol": "",
  "tarota": "",
  "tavil": "",
  "te ku": "",
  "tea chest bass": "",
  "tef": "",
  "telharmonium": "",
  "temple blocks": "",
  "temur khuur": "",
  "tenor vocals": "vocals",
  "tenor banjo": "banjo",
  "tenor flute": "flute",
  "tenor guitar": "guitar",
  "tenor horn": "horn",
  "tenor recorder": "recorder",
  "tenor sarrusophone": "",
  "tenor saxophone": "saxophone",
  "tenor sordun": "",
  "tenor trombone": "trombone",
  "tenor viol": "strings",
  "tenor violin": "violin",
  "tenora": "",
  "tepsija": "",
  "thavil": "",
  "the great stalacpipe organ": "organ",
  "theatre organ": "organ",
  "theorbo": "",
  "theremin": "theremin",
  "thon": "",
  "three-hole pipe": "pipe",
  "throat singing": "",
  "thumb piano": "",
  "ti bwa": "",
  "ti-tse": "",
  "tibetan singing bowl": "",
  "tibetan water drum": "",
  "tible": "",
  "tieu": "",
  "tifa": "",
  "totobuang": "",
  "timbales": "percussion",
  "timbrel": "",
  "timpani": "percussion",
  "timple": "",
  "tin whistle": "whistle",
  "tinya": "",
  "tiompan": "",
  "tiple": "",
  "tipple": "",
  "toasting": "",
  "tokere": "",
  "tololoche": "",
  "tom-tom": "",
  "tombak": "",
  "tonette": "",
  "tongtong": "",
  "tonkori": "",
  "topshuur": "",
  "torader": "",
  "toumbas": "",
  "tovshuur": "",
  "toy (instruments)": "general",
  "toy piano": "keyboards",
  "tram ple": "",
  "trang jau": "",
  "trang lu": "",
  "transverse flute": "flute",
  "traps": "",
  "trautonium": "",
  "treble vocals": "vocals",
  "treble flute": "flute",
  "treble recorder": "recorder",
  "treble shawm": "",
  "treble tambura": "",
  "treble viol": "strings",
  "treble violin": "violin",
  "tres": "",
  "tres cubano": "",
  "triangle": "triangle",
  "trikiti": "",
  "trikitixa": "",
  "triple harp": "",
  "tritantri veena": "",
  "tromba marina": "",
  "trombone": "trombone",
  "trumpet": "trumpet",
  "trompetica china": "",
  "trong bong": "",
  "trumpet marine": "trumpet",
  "trutruka": "",
  "tsuur": "",
  "tuba": "tuba",
  "tubaphone": "",
  "tubax": "",
  "tube zither": "zither",
  "tubon": "",
  "tubular bells": "tubular-bells",
  "tubulum": "",
  "tulum": "",
  "tumba": "",
  "tumbadora": "",
  "tumbi": "",
  "tumutumu": "",
  "tun": "",
  "tuner": "",
  "tungur": "",
  "tusseflute": "",
  "txalaparta": "",
  "txirula": "",
  "txistu": "",
  "typewriter": "",
  "tzouras": "",
  "udu": "",
  "ugal": "",
  "uilleann pipes": "bagpipes",
  "ukelele": "ukelele",
  "ukulele banjo": "banjo",
  "unidentified instruments": "general",
  "upright piano": "keyboards",
  "utogardon": "",
  "valiha": "",
  "valve trombone": "trombone",
  "valve trumpet": "trumpet",
  "valved brass instruments": "brass",
  "valvetrombone": "trombone",
  "variophon": "",
  "veena": "",
  "vena": "",
  "venu": "",
  "vessel flute": "",
  "vibes": "",
  "vibra-slap": "vibraslap",
  "vibrandoneon": "",
  "vibraphone": "vibraphone",
  "vibraslap": "vibraslap",
  "vichitra veena": "",
  "vielle": "",
  "vietnamese guitar": "",
  "vihuela": "",
  "vina": "",
  "viol": "",
  "viola": "viola",
  "viola caipira": "viola",
  "viola bastarda": "viola",
  "viola beiroa": "viola",
  "viola braguesa": "viola",
  "viola campanica": "viola",
  "viola d'amore": "viola",
  "viola da gamba": "viola",
  "viola de cocho": "viola",
  "viola kontra": "viola",
  "viola nordestina": "viola",
  "viola organista": "viola",
  "viola pomposa": "viola",
  "violarina": "strings",
  "violino piccolo": "strings",
  "viololyra": "strings",
  "violoncello piccolo": "strings",
  "violone": "strings",
  "violotta": "strings",
  "virginals": "",
  "vocal": "vocals",
  "vocal effects": "vocals",
  "vocalese": "vocals",
  "vocalizer": "",
  "vocoder": "vocoder",
  "voice actor": "general",
  "voice synthesizer": "",
  "vuvuzela": "vuvuzela",
  "wagner tuba": "brass",
  "walaycho": "",
  "waldhorn": "",
  "warr guitar": "guitar",
  "washboard": "washboard",
  "washint": "",
  "washtub bass": "",
  "waterphone": "",
  "wavedrum": "",
  "weisenborn": "",
  "whip": "",
  "whistle": "whistle",
  "whistling": "",
  "whistling water jar": "",
  "wiener horn": "horn",
  "willow flute": "flute",
  "wind": "woodwind",
  "wind chimes": "chimes",
  "wind instrument": "woodwind",
  "wind machine": "",
  "wind piano": "",
  "wind synthesizer": "",
  "wire-strung harp": "harp",
  "wobble board": "",
  "wood block": "wood-block",
  "wood chimes": "chimes",
  "woodblock": "wood-block",
  "wooden fish": "",
  "wooden flute": "flute",
  "woodwind": "woodwind",
  "word director": "general",
  "worship leader": "general",
  "wot": "",
  "wurlitzer electric piano": "keyboards",
  "wurlitzer organ": "organ",
  "xalam": "",
  "xaphoon": "",
  "xiao": "",
  "xiaoluo": "",
  "xilophono": "",
  "xun": "",
  "xylophone": "xylophone",
  "strohfidel": "",
  "xylorimba": "",
  "yaktaro": "",
  "yamaha wx7": "",
  "yang t'chin": "",
  "yanggeum": "",
  "yangqin": "",
  "yatga": "",
  "yayli tanbur": "",
  "yehu": "",
  "yenjing": "",
  "yodel": "",
  "yodelling": "",
  "yonggo": "",
  "yoochin": "",
  "yorgaphone": "",
  "yueqin": "",
  "yunluo": "",
  "zabumba": "",
  "zafzafa": "",
  "zampogna": "",
  "zampona": "",
  "zapateado": "",
  "zaqq": "",
  "zarb": "",
  "zeze": "",
  "zhaleika": "",
  "zhonghu": "",
  "zhongruan": "",
  "zhuihu": "",
  "zill": "",
  "zirbaghali": "",
  "zither": "",
  "zongora": "",
  "zukra": "",
  "zumare": "",
  "zummara": "",
  "zurla": "",
  "zurna": "",
  "beresta": "",
  "pakuru": "",
  "pirula": "",
  "pukaea": "",
  "rehu": "",
  "salamuri": "",
  "zamr": ""
}
